/* CustomFilterComponent.css */

/* Border color of the radio button when unchecked */
.ant-radio-inner {
  border-color: #000000 !important;
}

/* Color of the radio button when checked */
.ant-radio-checked .ant-radio-inner {
  background-color: #ffc000 !important;
  border-radius: 100%;
  border-color: #000000 !important;
}

/* Color of the checkmark inside the radio button when checked */
.ant-radio-checked .ant-radio-inner::after {
  background-color: #ffc000 !important;
}

.custom-filter-popover .ant-popover-inner {
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.filter-popover-content {
  width: 280px;
}

/* .sort-buttons {
  margin-bottom: 16px;
}

.sort-button {
  display: block;
  width: 100%;
  text-align: left;
  margin-bottom: 4px;
  color: #4bafa6;
  font-weight: 500;
}

.filter-label {
  font-weight: 500;
  margin-bottom: 12px;
}

.filter-row {
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
}

.condition-select,
.value-select {
  width: 50%;
}

.radio-group {
  margin-bottom: 12px;
  display: block;
}

.button-row {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.submit-button {
  background-color: #ffc000;
  border-color: #000000;
  color: #000000;
  border-radius: 20px;
}

.submit-button:hover {
  background-color: #e6ac00;
  border-color: #000000;
  color: #000000;
} */

/* Customize Select components */
.ant-select-selector {
  border-radius: 4px !important;
  border-color: #d9d9d9 !important;
}

/* Optional: Add hover effects */
.sort-button:hover {
  background-color: rgba(75, 175, 166, 0.1);
}

/* Customize the Select arrow color */
.custom-select-arrow .ant-select-arrow {
  color: #4bafa6 !important;
}

/* Optional: Change arrow color on hover */
.custom-select-arrow:hover .ant-select-arrow {
  color: #3a8c85 !important;
}

.custom-filter-menu {
  width: 265px;
  background: var(--white) !important;
  padding: 0;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
              0 3px 6px -4px rgba(0, 0, 0, 0.12),
              0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.06);
}

/* Add this new style for the placeholder color */
.custom-select-placeholder .ant-select-selection-placeholder {
  color: var(--filter-placeholder-color) !important;
}

/* Style for the search input inside Select */
