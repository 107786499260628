.data-display {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #838383;
  border-radius: 5px;
  padding: 10px;
  background-color: var(--chatWindow_Color) !important;
}

.SpaceDown {
  padding-bottom: 10px;
}
.data-item {
  text-align: center;
}

.data-value {
  display: flex;
  gap: 0.5rem;
  font-weight: bold;
}

.data-label {
  color: var(--bannerTextColor) !important;
}

.percentage {
  display: flex;
  align-items: center;
}

.positive-percentage {
  color: #169414;
  display: flex;
  align-items: center;
  gap: 4px;
}

.negative-percentage {
  color: #ae2e2e;
  display: flex;
  align-items: center;
  gap: 4px;
}

.positive-arrow {
  color: green;
  margin-left: 5px;
}

.negative-arrow {
  color: red;
  margin-left: 5px;
}

/* .align-perf-dropdowns {
      display: flex;
      align-items: center;
      justify-content: space-between;
          gap: 5rem;
      width: 100%;
      position: relative;
      top: 2rem;
      padding-bottom: 3rem;
  }
  
  */
.datepicker {
  position: absolute;
  z-index: 1000;
  /* Ensure it stays above other elements */
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.date-range-picker {
  position: relative;
  top: -3rem;
}

.align-perf-side-dropdowns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  gap: 1rem;
  bottom: 13px;
  position: relative;
}

.align-per-tabs {
  width: 20vw;
}

.align-strats-dropdowns {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  position: relative;
  top: 1rem;
  padding-bottom: 2rem;
  /* background-color: aqua; */
}

.align-strat-side-dropdowns {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  position: relative;
  /* top: 1rem; */
  /* padding-bottom: 2rem; */
  /* background-color: aqua; */
}
.side-three-starts {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.side-prim-strat {
  position: relative;
  left: 12rem;
}
.side-sec-strat {
  position: relative;
  left: 6rem;
}

.align-strat-tabs {
  width: 16vw;
}

.align-strat-tabs-primary {
  width: 26vw;
}

.align-all-dropdowns {
  width: 19vw !important;
  position: relative;
  top: 0.4rem;
  height: 3rem;
}

.date-viewDetailsFunding {
  position: relative;
  /* top: -0.6rem; */
  left: 3rem;
  bottom: 2.59342rem;

  /* background-color: #007bff; */
}

.date-viewDetailsFunding .MuiFilledInput-root {
  width: 12rem !important;
}

.rmsc * {
  /* background-color: var(--dropdown-bg) !important; */
  /* background-color: var(--dropdown-bg) !important; */
  /* color: var(--text_Color) !important; */
}
.rmsc .select-item.selected {
  background-color: var(--background-DD_Content) !important;
  color: var(--text_Color) !important;
}
.rmsc .select-item {
  background-color: var(--background-DD_Content) !important;
  color: var(--text_Color) !important;
  padding: 2px 2px 2px 5px !important;
}
.rmsc .item-renderer input {
  position: relative;
  top: 5px;
}
.rmsc .options li {
  margin: -3px -3px -3px 0 !important;
}
.rmsc .options {
  background-color: var(--background-DD_Seperator) !important;
}
.rmsc .options::-webkit-scrollbar {
  height: 5px; /* Adjust scrollbar height */
  width: 5px; /* Adjust scrollbar width */
}

.rmsc .options::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 10px;
}

.rmsc .options::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color on hover */
}

.rmsc .options::-webkit-scrollbar-track {
  background: var(
    --background-DD_Seperator
  ) !important; /* Background of scrollbar track */
}
.custom-multiselect {
  border: 1px solid var(--tableBorder) !important;
  /* width: 200px; */
  border-radius: 8px;
  position: relative;
  padding: 0;
  color: var(--text_Color) !important;
  /* This is important for positioning the dropdown */
}
.multiselect-width {
  width: 200px !important;
}

.multiselect-width .dropdown-content {
  min-width: 200px !important;
  width: auto !important;
  white-space: nowrap !important;
  z-index: 9999 !important;
}

.rmsc .dropdown-heading {
  border-radius: 8px !important;
  background-color: var(--dropdown-bg) !important;
}

.rmsc.custom-multiselect:hover {
  border: 1px solid var(--dropdown-hover-bg) !important;
}
.custom-multiselect .dropdown-container {
  border: none !important;
  position: relative !important;
  border-radius: 8px !important;
}

/* Apply custom styles for checkboxes within the multi-select component */
.custom-multiselect .dropdown-content .option > input[type="checkbox"] {
  width: 20px;
  /* Width of the checkbox */
  height: 20px;
  /* Height of the checkbox */
  accent-color: black !important;
  /* Sets the checkbox color when checked */
  background-color: black !important;
  /* Background color when unchecked */
  border: 1px solid black;
  /* Border color of the checkbox */
  /* Space between the checkbox and text */
  cursor: pointer;
  /* Change cursor to pointer */
  z-index: 999;
}

/* Set the tick mark color */
.custom-multiselect .dropdown-content .option > input[type="checkbox"]:checked {
  accent-color: white !important;
  /* White tick mark color */
}
.custom-multiselect .dropdown-content {
  z-index: 999999;
  border: none;
  position: absolute;
  /* Position the dropdown absolutely */
  top: 100%;
  /* Position it below the input */
  left: 0;
  width: 100%;
  /* Make it the same width as the input */
  background-color: var(--dropdown-bg) !important;

  color: black !important;
  /* Ensure the background is white */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  border: 1px solid black;
  border-radius: 4px;
}

.custom-select .css-1nmdiq5-menu {
  z-index: 999;
  border: none;
  position: absolute;
  /* Position the dropdown absolutely */
  top: 100%;
  /* Position it below the input */
  left: 0;
  width: 100%;
  /* Make it the same width as the input */
  background-color: var(--dropdown-bg) !important;
  color: black !important;

  /* Ensure the background is white */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  border: 1px solid black;
  border-radius: 4px;
}

/* Targeting the dropdown content specifically for react-multi-select */
.custom-multiselect .dropdown-container .dropdown-content {
  z-index: 999999;
  border: 1px solid black;
  position: absolute;
  top: 100%; /* Position it below the input */
  left: 0;
  width: 100%;
  /* background-color: black !important; Force the background color to black */
  color: white !important; /* Change text color to white for contrast */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

/* Specific targeting for items/options inside the dropdown */
.custom-multiselect .dropdown-container .dropdown-content .item {
  color: var(--text_Color) !important; /* Set color for dropdown items */
  /* background-color: var(--lightGray) !important; Set background for items */
}

/* Modify the search box */
.custom-multiselect .dropdown-container .search input {
  background-color: var(--lightGray) !important; /* Set search box background */
  color: var(--text_Color) !important; /* Set text color */
}

.custom-multiselect .dropdown-container .dropdown-content {
  /* background-color: black !important; */
  color: black !important;
}

.performance-table table .table-body-wrapper > tr:nth-child(odd) > td {
  background-color: var(--tdOdd); /* Applies to odd rows */
  color: var(--headerText) !important;
  border-right: none !important;
  border-bottom: none !important;
}

.performance-table table .table-body-wrapper > tr:nth-child(even) > td {
  /* If you want a different style for even rows, add here */
  background-color: var(--tdEven); /* Example: Light grey for even rows */
  color: var(--headerText) !important;
  border-right: none !important;
  border-bottom: none !important;
}

.timePeriod {
  font-weight: bold;
}
.normal_table_headers th {
  background-color: var(--table-header-bg) !important;
  color: var(--headerText) !important;
  border-bottom: none !important;
}

@media only screen and (min-width: 1160px) and (max-width: 1307px) {
  .align-per-tabs {
    width: 16vw;
  }
}

@media only screen and (min-width: 1250px) and (max-width: 1600px) {
  .multiselect-width .dropdown-content {
    width: 200px !important;
    white-space: wrap !important;
    z-index: 9999 !important;
  }
}
