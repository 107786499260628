.modalStyle .ant-modal-content {
  font-family: "Mulish", sans-serif !important;
  height: 90vh !important;
  background-color: var(--chatWindow_Color);
}

/* .ant-modal {
    width: 100%;
    transform-origin: 85px 43px;
    overflow: hidden;
    height: -webkit-fill-available;
} */

.suggested-questions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5rem;
  padding: 1rem;
  /* background-color: blueviolet; */
}

.suggested-question-needed {
  border: 1px solid var(--tableBorder);
  border-radius: 20px;
  background-color: var(--tdOdd); /* Example: Light grey for even rows */
  cursor: "pointer";
  padding: 1.5rem;
  text-align: center;
  color: var(--headerText) !important;
}

.suggested-question-needed:hover {
  background-color: var(--tdEven); /* Example: Light grey for even rows */
  cursor: pointer;
}

.specific-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: "Mulish", sans-serif;
  min-width: 60vw;
  box-shadow: 0 0 10px rgba(22, 17, 17, 0.15);
  border: 1px solid #000;
  border-radius: 10px;
  color: var(--text_Color);
}

.specific-table th {
  text-align: center;
  border: 1px solid #dddddd;
  padding: 8px;
  color: var(--chatWindow_TableHeader);
}
.specific-table td {
  background: var(--chatWindow_Color) !important;
  text-align: center;
  border: 1px solid #dddddd;
  padding: 8px;
}
.specific-table thead tr {
  background-color: #f2f2f2;
  font-weight: bold;
}

.specific-table td {
  background-color: #fafafa;
}

.specific-table tr:nth-child(even) td {
  background-color: #ffffff;
}

.specific-table th {
  background-color: #e5f3f2;
}

.specific-table th,
.specific-table td {
  border: 1px solid #ddd;
  padding: 8px;
}
.scrollable-content-chat {
  /* flex: 1; */
  /* max-height: 100%; */
  /* Constrain content height */
  overflow-y: auto;
  /* overflow-x: hidden; */
  height: 100%;
  /* Enable vertical scrolling */
  padding-bottom: 120px;
  /* Optional padding for spacing */
}

.scrollable-content-chat .scroll-content-downloads {
  background-color: blueviolet;
}
/* Custom scrollbar styles for WebKit browsers */
.scrollable-content-chat::-webkit-scrollbar {
  width: 8px;
  /* Adjust the width of the scrollbar */
}

.scrollable-content-chat::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Color of the scrollbar thumb */
  border-radius: 10px;
  /* Rounded corners for a smoother look */
}

.scrollable-content-chat::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Darker on hover */
}

.scrollable-content-chat::-webkit-scrollbar-track {
  background-color: transparent;
  /* Transparent background for the scrollbar track */
}

/* Remove scrollbar arrows */
.scrollable-content-chat::-webkit-scrollbar-button {
  display: none;
  /* Hide the up/down arrow buttons */
}

.chat-msgs {
  height: 76vh;
  overflow-y: auto;
  color: var(--tableText);
}

.chat-msgs::-webkit-scrollbar-track {
  background-color: transparent;
  /* max-height: 85vh; */
  /* Transparent background for the scrollbar track */
}

/* Remove scrollbar arrows */
.chat-msgs::-webkit-scrollbar-button {
  display: none;
  /* Hide the up/down arrow buttons */
}

.chat-msgs .recent-entry {
  /* padding: 10px;
    border-radius: 50px;
    cursor: pointer; */
  cursor: pointer;
  font-family: "Mulish", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20.25px;
  text-align: left;

  color: var(--tableText);
}

.chat-msgs .recent-entry:hover {
  /* background-color: #e2e6eb; */
}

/* For standard browsers */
.chatInput-text::placeholder {
  color: var(--tableText);
  background-color: var(--chatWindow_Color);
}

.active-suggestion {
  background-color: #f0f0f0;
}

/* For WebKit browsers (e.g., Chrome, Safari) */
.chatInput-text::-webkit-input-placeholder {
  color: var(--tableText);
  background-color: var(--chatWindow_Color);
}

.horizontal_seperator {
  margin: 15px 0px 15px 0px;
  width: 214px;
  /* height: 1px; */
  top: 261px;
  left: 134px;
  background-color: gray !important;
}

.history {
  font-family: Mulish;
  font-size: 17px;
  font-weight: 700;
  line-height: 22.95px;
  text-align: left;
  color: var(--headerText);
}

.top_left_Icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-spacing {
  margin-right: 16px;
  cursor: pointer;
  /* Adjust this value for desired spacing */
}
.result {
  padding: 0px 5%;
}

.result-title {
  margin: 0px 0px 10px 0px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.result-data {
  display: flex;
  align-items: flex-start;
}

.loader-chat {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.loader-chat hr {
  border-radius: 4px;
  border: none;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #e1efee, #62b1a4, #e1efee);
  background-size: 800px 50px;
  height: 20px;
  animation: loading 3s linear infinite;
  /* Add animation */
}

@keyframes loading {
  0% {
    background-position: -800px 0px;
    /* Start position */
  }

  100% {
    background-position: 800px 0;
    /* End position */
  }
}

/* Override Ant Design switch styles with maximum specificity */
.custom-switch .ant-switch.ant-switch-checked,
.custom-switch .ant-switch-checked,
.ant-switch.ant-switch-checked,
.ant-switch-checked {
  background-color: #018E82 !important;
}

.custom-switch .ant-switch.ant-switch-checked:hover,
.custom-switch .ant-switch-checked:hover,
.ant-switch.ant-switch-checked:hover,
.ant-switch-checked:hover {
  background-color: #018E82 !important;
}

.custom-switch .ant-switch.ant-switch-checked:focus,
.custom-switch .ant-switch-checked:focus,
.ant-switch.ant-switch-checked:focus,
.ant-switch-checked:focus {
  background-color: #018E82 !important;
}

/* Also target the inner node of the switch */
.custom-switch .ant-switch.ant-switch-checked .ant-switch-inner,
.ant-switch.ant-switch-checked .ant-switch-inner {
  background-color: #018E82 !important;
}

.custom-switch {
  transition: all 0.3s ease;
}

.selected-prompt {
  color: #018E82 !important;
}