.FixTails {
  position: relative;
  bottom: 2rem;
}

.scrollSummary {
  position: relative;
  /* bottom: 2rem; */
}

.SummaryStds {
  position: relative;
  /* background-color: aqua; */
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  left: -5px;
  width: 100%;
  padding-bottom: 10px;
}

.dropdown-row {
  display: flex;
  align-items: center;
  gap: 20px;
  /* Adjust the gap between dropdowns as needed */
  padding: 23px 0 15px 0;
}

.dropdown-container {
  display: flex;
  align-items: center;
}

.dropdown-label {
  margin-right: 10px;
  font-weight: bold;
  position: relative;
  top: 2px;
}

.dropdown-content-topBar {
  width: 200px;
  /* Adjust width as per requirement */
}

.custom-multiselect .dropdown-content-topBar {
  width: 200px;
  /* Adjust width for multi-selects */
}

/* .general-InvContainer,
.align-all-dropdowns {
    flex: 1;
} */
.total-row {
  font-weight: bold;
  color: #000;
}

.custom-vintage-button {
  background-color: #f2f9f9; /* Light greenish background */
  color: #009688; /* Teal text color */
  border: 1px solid #009688; /* Teal border color */
  border-radius: 30px; /* Rounded edges */
  font-size: 16px; /* Adjust font size */
  padding: 6px 20px; /* Adjust padding */
  height: auto; /* Ensure height is flexible */
  line-height: 1; /* Line height adjustment */
  transition: all 0.3s ease;
  position: relative;
  top: 3rem;
}

/* On hover, override AntD styles */
.custom-vintage-button:hover,
.custom-vintage-button:focus {
  background-color: #e0f7fa; /* Slightly darker green background */
  color: #00796b !important; /* Darker teal text color */
  border-color: #00796b !important; /* Darker teal border */
  outline: none; /* Remove default focus outline */
  box-shadow: none; /* Remove default AntD shadow */
}

/* On active/click state */
.custom-vintage-button:active {
  background-color: #b2dfdb !important; /* Even darker green background */
  color: #004d40 !important; /* Dark teal text color */
  border-color: #004d40 !important; /* Dark teal border */
  outline: none; /* Remove outline */
  box-shadow: none; /* Remove AntD box shadow */
}

.vintage-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.vintage-dropdown {
  position: relative;
  top: 2.2rem;
  left: 2rem;
}

.workbench-table-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.table-row {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  overflow: hidden; /* Prevent overflow issues */
}

.table-column {
  flex: 1;
  min-width: 0; /* Prevent the flex item from overflowing */
  max-width: 50%; /* Ensure both columns share the space equally */
}

.performance-table {
  width: 100% !important; /* Ensure the table takes the full width of its container */
  max-width: 100% !important; /* Prevent the table from exceeding its container */
  overflow: auto !important; /* Add scrolling if the table's content exceeds container width */
}

.selected-loan-table {
  width: 150px !important;
}

.table-row {
  display: flex;
  gap: 16px; /* Add space between columns */
}

.table-column {
  flex: 1; /* Make all columns equal width */
  display: flex;
  flex-direction: column;
}

.table-column .ant-table-wrapper {
  flex: 1; /* Make the Ant Design tables stretch to fill height */
  display: flex;
  flex-direction: column;
}

.table-column .ant-table-body {
  flex: 1; /* Stretch the table body to fill available space */
  overflow-y: auto !important; /* Allow scrolling for large data */
  max-height: 370px !important;
}
.table-section .ant-table-body {
  overflow-y: auto !important; /* Allow scrolling for large data */
}
.alignment-for-Charts {
  height: 465px !important;
}

.alignment-for-Charts .ant-table-wrapper {
  overflow-y: hidden !important;
}
/* .align-border-plot {
  padding: 0 !important;
  border-radius: 4px !important;
} */

.custom-inv-ddDownload {
  background-color: var(--white) !important;
  color: #018e82 !important;
}

.custom-inv-ddDownload .ant-dropdown-menu-item {
  background-color: var(--white) !important;
  color: #018e82 !important;
}

.custom-inv-ddHr {
  background-color: #e0e0e0 !important;
  border-top: 2px solid #e0e0e0 !important;
}
