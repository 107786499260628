.mapping-switch.ant-switch {
    background-color: #d9d9d9;
  }
  
  .mapping-switch.ant-switch:hover {
    background-color: #d9d9d9;
  }
  
  .mapping-switch.ant-switch.ant-switch-checked {
    background-color: rgb(25, 118, 210) !important;
  }
  
  .mapping-switch.ant-switch.ant-switch-checked:hover {
    background-color: rgb(25, 118, 210) !important;
  }
  
  .mapping-switch.ant-switch.ant-switch-checked .ant-switch-inner {
    background-color: rgb(25, 118, 210) !important;
  }
  
  .mapping-switch.ant-switch-checked .ant-switch-inner {
    background-color: rgb(25, 118, 210) !important;
  }