.tableHeaders {
  font-family: "Catamaran", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 32.8px;
  text-align: left;
  color: var(--headerText);
}
.tableHeaders-collateral {
  font-family: "Catamaran", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 32.8px;
  text-align: left;
  position: relative;
  top: 2rem;
}
.PortfolioStds {
  position: relative;
  /* background-color: aqua; */
  left: -5px;
  width: 100%;
  color: var(--button) !important;
}

.dropdown-row {
  display: flex;
  align-items: center;
  gap: 20px;
  /* Adjust the gap between dropdowns as needed */
  padding: 23px 0 15px 0;
}

.dropdown-container {
  display: flex;
  align-items: center;
}

.dropdown-label {
  margin-right: 10px;
  position: relative;
  font-family: "Mulish", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 18.83px;
  text-align: right;
  top: 2px;
  color: var(--headerText);
}

.dropdown-content-topBar {
  width: 200px;
  /* Adjust width as per requirement */
}

.custom-multiselect .dropdown-content-topBar {
  width: 200px;
  /* Adjust width for multi-selects */
}

/* .general-InvContainer,
.align-all-dropdowns {
    flex: 1;
} */

.rmsc.custom-multiselect:hover {
  border: 1px solid var(--tableBorder) !important;
}
