::-webkit-scrollbar {
    width: 8px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color:#8a8585;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .scrollable {
    scrollbar-width: thin;
    scrollbar-color:  #212121;
  }
  
  .content-admin {
        width: 512px;
        height: 58px;
        overflow-y: auto;
        overflow-x: hidden;
        white-space: normal;
        display: block;
        border: 1px solid var(--tableBorder);
        border-radius: 8px !important;
        color: var(--tableText) !important;
        font-size: 15px;
    }
  
  .paymentdatestdfields {
      font-size: 15px;
        width: 85px;
        /* padding-top: 3.5rem; */
        position: relative;
        display: flex;
        left: 29.5rem;
        position: relative;
        top: 3.3rem;
    }
    .paymentdatelastupdate {
      font-size: 15px;
      width:85px;
  
      width: 15rem;
      position: relative;
        display: flex;
        left: 36.3rem;
        top: 3.3rem;
      }
     
    .input-select-general-stdfields-assetclass {
      background-image: linear-gradient(45deg, transparent 50%, var(--button) 50%), linear-gradient(135deg, #018e82 50%, transparent 50%);
      background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), 100% 0;
      background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
      background-repeat: no-repeat;
      border: 1.5px solid var(--tableBorder);
      border-radius: 8px;
      color: var(--tableText);
      padding: 1px 1rem;
      background-color: var(--white);
      display: flex;
      width: 300px;
      height: 38px;
      /* margin-left: 25.5rem; */
      /* margin-top: -2.2rem; */
      padding-bottom: 0rem;
      /* margin-bottom: 0rem; */
      position: relative;
        left: 35rem;
        top: 1rem;
    }
    
    .input-select-general-stdfields-lastupdate {
      background-repeat: no-repeat;
      border: 1.5px solid var(--tableBorder);
      border-radius: 8px;
      color: gray;
      padding: 1px 1rem;
      background-color: var(--white);
      display: flex;
      width: 300px;
      height: 38px;
      position: relative;
        left: 42rem;
        top: 1rem;
    }
  
    .input-text{
      width: 15rem;
      font-size: 15px;
    }
  
    .custom-header{
      width:6rem;
    }
    .custom-header-new{
      width:10rem;
    }
    
    .uw-search-button-container-stdfields {
      width: 12rem;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      position: relative;
      left: 45rem;
      top: 1rem;
    }
    
    .headerdashboard-mastertape {
      font-family: "Catamaran", sans-serif !important;
      font-size: 24px;
      color: var(--tableText);
      font-weight: 600 !important;
      min-width: 20rem;
      position: absolute;
        display: flex;
        top: 3rem;
        left: 1rem;
    }
    
    .stdfields-antd .ant-table-thead > tr > th {
      background-color: var(--table-header-bg);
      color: var(--headerText) !important;
      /* border-bottom-style: 0px; */
      /* Change text color if needed */
    }
    .stdfields-antd .ant-table-thead .account-column {
      border-right: 0.5px solid #000 !important;
    }
    
    .stdfields-antd .ant-table-thead .parent-column {
      border-right: 0.5px solid #000 !important;
      border-bottom: 0.5px solid #000 !important;
    }
    
    .stdfields-antd .ant-table-thead .child-column {
      border-right: 0.5px solid #000 !important;
    }
    .stdfields-antd {
      /* border-bottom-width: 0px; */
      border: 1px solid var(--tableBorder);
      border-radius: 10px;
      overflow: hidden !important;
      /* New addition */
      flex-grow: 1;
      position: relative;
      bottom: -1rem;
      /* Allows the table to take the remaining height of the container */
    }
    
    .stdfields-antd .ant-table-tbody > tr > td {
      border-top: none;
      border-right: none !important;
      /* border-bottom: none; */
      border-left: none;
    }
    
    .stdfields-antd .ant-table-cell {
      height: 49px;
      font-family: "Mulish", sans-serif;
      font-size: 15px;
      font-weight: 400;
      line-height: 18.83px;
      text-align: left;
      background-color: var(--white);
      color: var(--tableText);
    }
    .stdfields-antd .ant-table-tbody>tr:nth-child(odd) > td {
      background-color: var(--tdOdd) !important;
    }
    
    .stdfields-antd .ant-table-tbody > tr:nth-child(even) > td {
      background-color: var(--tdEven) !important;
    }
    
    .stdfields-antd .ant-table-tbody > tr:nth-child(odd) .account-column {
      background-color: var(--white) !important;
      /* position: relative; */
    }
    
    .stdfields-antd
      .ant-table-tbody
      > tr:nth-child(odd)
      .account-column::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      /* background-color: rgba(229, 229, 229, 0.3) !important; */
    }
    
    .stdfields-antd .ant-table-tbody > tr:nth-child(odd) .account-column::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: white;
      z-index: -99;
    }
    .stdfields-antd
      .ant-table-tbody
      > tr:nth-child(odd)
      .account-column:first-child {
      position: sticky;
      left: 0;
      top: 0;
      z-index: 1;
      /* background-color: rgba(255, 255, 255, 1); */
    }
    
    .stdfields-antd .ant-table-tbody > tr:nth-child(odd) .account-column > * {
      color: var(--text_Color) !important;
    }
    
    /* Define the CSS to remove hover effect */
    .stdfields-antd:hover {
      background: var(--white) !important;
    }
    
    /* Common styles for table columns */
    .stdfields-antd .ant-table-thead > tr > th {
      width: 150px; /* Set a default width for all columns */
      text-align: center; /* Center-align header text */
      white-space: normal; /* Allow text wrapping */
      word-wrap: break-word;
      line-height: 1.5; /* Better spacing for multi-line headers */
      padding: 8px;
    }
    
    .stdfields-antd .ant-table-tbody > tr > td {
      width: 150px; /* Match the width of the headers */
      text-align: center; /* Center-align content text */
      white-space: normal;
      word-wrap: break-word;
      line-height: 1.5;
    }
    
    .stdfields-antd .ant-table-thead > tr > th:not(:last-child)::before,
    .stdfields-antd .ant-table-thead > tr > td:not(:last-child)::before {
      content: none !important; /* Disable the pseudo-element */
      background-color: transparent !important; /* Remove any background color */
      width: 0 !important; /* Remove the visual divider entirely */
    }
    
    .stdfields-antd .ant-table-row {
      transition: none !important;
    }
    .stdfields-antd .ant-table-row:hover {
      background-color: var(
        --hover-on-row
      ) !important; /* Your custom hover background */
      box-shadow: var(--hover-on-row) !important; /* Remove default hover shadow */
    }
    
    .stdfields-antd .ant-table-row:hover td.ant-table-cell {
      background: var(--hover-on-row) !important;
    }