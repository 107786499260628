.sidebar {
  width: 60px;
  height: 100vh;
  /* background-color: #0e1111; */
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: width 0.3s ease;
}

.sidebar:hover {
  width: 220px;
}
.sidebar:hover .issure-icon-active {
  width: 185px !important;
}

.sidebar:hover .sidebar-item-content {
  width: 185px !important;
}

.sidebar-icons {
  padding: 15px 0 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-logo {
  position: relative;
  margin-bottom: 50px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* .sidebar:hover ul:active {
  background-color: aqua;
  width: 200px !important;
} */
.sidebar-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 7px;
  color: white;
  text-decoration: none;
  /* width: 185px; */
  /* transition: background-color 0.3s ease; */
}

.sidebar-item img {
  width: 24px;
  height: 24px;
}

.sidebar-item-label {
  display: none;
  margin-left: 15px;
  color: white;
  font-size: 14px;
}

.sidebar:hover .sidebar-item-label {
  display: inline-block;
}

.sidebar-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}

.sidebar-item-profile {
  background-color: #e2ebea;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50px;
  color: #004d40;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}

/* Add hover effects for smooth transitions */
.sidebar-item:hover {
  background-color: #1a534e;
}

.notificationcolor {
  font-size: 24px;
  color: #ccc;
}

.sidebar-icons ul {
  list-style-type: none !important;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style: none !important;
}

.sidebar-icons li {
  display: flex;
  align-items: center;
  justify-content: center;
  /* Ensures text is aligned with icons */
  /* margin-bottom: 20px; */
  /* Adds space between each icon-item */
  list-style-type: none !important;
}

.sidebar-icons img {
  width: 40px;
  /* height: 30px; */
  /* Adds spacing between the icon and text */
}

.sidebar-icons span {
  font-size: 14px;
  color: #fff;
  /* Adjust this based on the sidebar color scheme */
}

.logo-intain-hidden {
  display: none;
  /* Hide IntainLogo by default */
}

/* Show IntainLogo on sidebar hover */
.sidebar:hover .logo-intain-hidden {
  display: block;
  width: 88px;
  height: 44px;
  /* Show IntainLogo when sidebar is hovered */
}

.sidebar:hover .logo-intan {
  display: none;
  /* Hide Intan_AI when sidebar is hovered */
}

.sidebar-item-content {
  opacity: 0.5;
  display: flex;
  gap: 8px;
}
.sidebar-item .sidebar-item-content {
  filter: var(--sideBarIconFilter);
}

.sidebar-item:hover {
  opacity: 0.5;
  cursor: pointer;
  border-radius: 10px;
  padding: 8px 7px;
  /* width: 185px !important; */
}

/* Show Content on sidebar hover */
.content-show {
  display: none;
  /* Hide IntainLogo by default */
}

.sidebar:hover .content-show {
  display: block;
  filter: var(--sideBarIconFilter);
  /* Show Content when sidebar is hovered */
}

.darkmode-label {
  width: 40px; /* Reduced from 50px */
  height: 20px; /* Reduced from 25px */
  position: relative;
  display: block;
  background-color: #03dac6;
  border-radius: 12px; /* Adjusted for smaller size */
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.4),
    inset 0px -1px 2px rgba(255, 255, 255, 0.4);
  cursor: pointer;
  transition: 0.3s;
  right: 0.4rem;
}

.darkmode-label::after {
  content: "";
  width: 16px; /* Reduced from 21px */
  height: 16px; /* Reduced from 21px */
  position: absolute;
  top: 2px; /* Adjusted for centering */
  left: 2px;
  background: linear-gradient(180deg, #ffffff, #ffffff);
  border-radius: 50%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

#darkmode-toggle {
  width: 0;
  height: 0;
  visibility: hidden;
}

#darkmode-toggle:checked + .darkmode-label {
  background-color: #017268;
}

#darkmode-toggle:checked + .darkmode-label::after {
  left: 100%; /* Adjusted for smaller size */
  transform: translateX(-100%);
  background: linear-gradient(180deg, #777, #3a3a3a);
}

/* Adjusted size for sun and moon icons */
.sun,
.moon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 14px; /* Reduced from 19px */
  height: 14px; /* Reduced from 19px */
  transition: 0.3s;
  z-index: 100;
}

.moon {
  left: 3px; /* Adjusted position */
  opacity: 1; /* Moon is visible when unchecked */
}

.sun {
  right: 3px; /* Adjusted position */
  opacity: 0; /* Sun is hidden when unchecked */
}

#darkmode-toggle:checked + .darkmode-label .moon {
  opacity: 0; /* Moon hides when checked */
}

#darkmode-toggle:checked + .darkmode-label .sun {
  opacity: 1; /* Sun shows when checked */
}

.darkmode-label:active::after {
  width: 10px; /* Reduced from 13px */
  height: 10px; /* Reduced from 12px */
}

/* Add this to sidebar.css */
.blur-background {
  filter: blur(5px);
  pointer-events: none;
}

/* Modal overlay to blur the background */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Updated Modal styles */
.modal-container {
  position: fixed;
  bottom: 80px;
  left: 70px;
  width: 240px;
  background: var(--modalBackground);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 0px;
}

/* Profile section */
.profile-section {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--button);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
  color: var(--background-DD_Seperator);
  position: static;
}

/* Button styles */
.modal-button,
.modal-button-logout {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: left;
  background-color: transparent !important;
  color: var(--placeHolder);
  position: static;
}

.modal-button:hover,
.modal-button-logout:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.modal-button-logout {
  color: var(--placeHolder);
}

/* Theme toggle section */
.theme-toggle-section {
  padding: 10px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.darkmode-label {
  position: relative;
  left: 0;
  top: 0;
  margin: 0 auto;
}

/* Tooltip container styles */
.tooltip-container {
  position: relative;
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.3s ease;
}

.tooltip-container.collapsed .darkmode-text {
  opacity: 0;
  width: 0;
  margin: 0;
  overflow: hidden;
  transition: opacity 0.3s ease, width 0.3s ease;
}

.tooltip-container.expanded .darkmode-text {
  opacity: 1;
  width: auto;
  margin: 0 0.5rem;
  transition: opacity 0.3s ease, width 0.3s ease;
}

/* Adjust sun and moon icon alignment for collapsed state */
.tooltip-container.collapsed .darkmode-label {
  justify-content: center;
}

.tooltip-container.expanded .darkmode-label {
  justify-content: space-between;
}

/* Ant Design Popover customization */
.profile-popover {
  .ant-popover-inner {
    background: var(--modalBackground);
    border-radius: 8px;
    padding: 0;
  }

  .ant-popover-inner-content {
    padding: 0;
  }

  .ant-popover-arrow {
    left: 9px;
  }
  .ant-popover-arrow::after {
    background: var(--modalBackground);
    border: 1px solid var(--popover-border);
    width:  11.970563px !important;
  }
  .ant-popover-content {
    left: 8px;
    border: 1px solid var(--popover-border);
    border-radius: 10px;
  }
}

.modal-button,
.modal-button-logout {
  width: 100%;
  padding: 10px;
  border: none;
  background: transparent;
  text-align: left;
  cursor: pointer;
  color: var(--placeHolder);
  font-size: 14px;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.modal-button-logout {
  color:  var(--placeHolder);
}

.profile-section {
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  gap: 12px;
}

.theme-toggle-section {
  padding: 10px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.06); */
}
