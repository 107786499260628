.chat-chart-container {
  display: flex;
  flex-direction: column;
  /* Stack charts vertically */
}

.padding-graph {
  padding-bottom: 10px;
}

.plot-borders-bar-chat-charts {
  flex: 1;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0;
  position: relative;
  width: 100% !important;
  /* width: 100vw !important; */
  /* Add vertical spacing between charts */
}

.plot-inline-chat-charts {
  display: inline !important;
  position: relative;
  width: auto !important;
  height: 450px;

  color: var(--tableText) !important;
  background-color: var(--chatWindow_Color) !important;
}

.plot-inline-chat-charts .plot-container .svg-container {
  width: auto !important;
}

.plot-inline-chat-charts .plot-container .svg-container .main-svg {
  width: 100% !important;
}
