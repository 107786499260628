.accordion-container {
    display: flex;
    gap: 1rem;
    flex-direction: column;
}

.accordion-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    cursor: pointer;
    gap: 1rem;
}

.acc-line-though {
    height: 1px;
    flex: 1;
    background-color: #BCBCBC;
}

.acc-arrow {
    transition: transform 0.2s;
    transform: rotate(180deg);
}

.acc-arrow.open {
    transform: rotate(0deg);
}