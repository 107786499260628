.loan-table-alignments {
  display: flex;
  width: 100%;
  height: auto;
  /* Align cards to the top */
}

.loans-cards {
  /* border-bottom-width: 0px; */
  border: 1px solid var(--tableBorder);
  border-left: none;
  border-radius: 0 10px 10px 0 !important;
  overflow-y: auto; /* Enables vertical scrolling */
  overflow-x: auto; /* Enables horizontal scrolling */ /* New addition */
  flex-grow: 1;
  padding: 3rem;
  display: grid;
  height: 630px !important;
}

/* .scrollable-content-loans {
  height: 100vh; 
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100% !important;
  box-sizing: border-box;
  overflow-x: hidden;
  position: relative;
} */

.scrollable-content-loans {
  /* max-height: 100vh; */
  /* overflow-y: auto; */
  /* padding-bottom: 25rem; */
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100% !important;
  box-sizing: border-box;
  /* overflow-x: hidden; */
  position: relative;
}

.loan-details-graphs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.loan-details-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.loan-contents-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.loan-contents-container .loan-section,
.borrower-attributes,
.performance-section,
.collateral-section {
  border: 1px solid #8c8c8c;
  border-radius: 10px;
  padding: 10px;
  background-color: var(--lightGray);
  color: var(--headerText);
}

.loan-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.loan-info-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.info-item {
  display: flex;
  flex-direction: column; /* Stacks label and value vertically */
  min-width: 120px; /* Ensures consistent width */
  margin-right: 10px;
}

.progress-bar.yellow {
  background-color: #f7c948; /* Yellow for current balance */
}

.progress-bar.red {
  background-color: #f44336; /* Red for original balance */
}

.loan-header-info {
  font-size: 20px;
  font-weight: 600;
  line-height: 32.8px;
  text-align: left;
}

.info-header {
  font-size: 16px;
  font-weight: 600;
  line-height: 32.8px;
  color: var(--headerText);
}

.loan-financial-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--headerText);
}

.bar-section {
  display: flex;
  border: 1px solid #000;
  width: 200px;
  height: 40px;
}

.current-balance {
  background-color: #87ceeb; /* Light blue */
  text-align: center;
  line-height: 40px;
  color: #000;
  font-weight: bold;
}

.original-balance {
  background-color: #ffdab9; /* Peach */
  text-align: center;
  line-height: 40px;
  color: #000;
  font-weight: bold;
}

.table-container-loans {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
}

.table-loans-wrapper {
  max-height: 500px; /* Adjust as needed */
  overflow-y: auto;
  overflow-x: auto;
  width: 100%;
  background-color: var(--lightGray);
  color: var(--headerText);
  border: none !important;
}

.table-loans-wrapper table {
  width: 100%;
  border-collapse: collapse;
}

.table-loans-wrapper thead th {
  position: sticky;
  top: 0;
  background-color: #e8f5f9; /* Light blue header background */
  color: #333;
  font-weight: bold;
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  z-index: 1;
}

.table-loans-wrapper tbody td {
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  white-space: nowrap; /* Prevents text wrapping */
}

.table-loans-wrapper tbody tr:hover {
  background-color: var(--hover-on-row);
}

.custom-input-loans::placeholder {
  color: #000; /* Change placeholder color to black */
  opacity: 1; /* Ensure opacity is set to 1 for better visibility */
}

.custom-input-loans {
  outline: none;
}

/* For LoanDetails Table */

.table-loans-details-wrapper {
  overflow-y: auto;
  max-height: 500px; /* Adjust height as needed */
  width: 100%;
  border: 1px solid #fff;
}
/* can you make the scroll available after the column headers  */
.table-loans-details-wrapper table {
  width: 100%;
  border-collapse: collapse;
}

.table-loans-details-wrapper thead th {
  position: sticky;
  top: 0;
  background-color: #e8f5f9; /* Light blue header background */
  color: #333;
  font-weight: bold;
  text-align: left;
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  z-index: 1;
}

.table-loans-details-wrapper td {
  padding: 16px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
  white-space: nowrap; /* Prevent text wrapping */
}

.table-loans-details-wrapper tbody tr:hover {
  background-color: #fafafa;
}
