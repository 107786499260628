.data-display {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid #838383;
  border-radius: 5px;
  padding: 10px;
  /* background-color: #fafafa; */
  background-color: var(--chatWindow_Color) !important;
  color: var(--headerText);
}

.as_of_Labels {
  color: var(--headerText);
}

.data-item {
  text-align: center;
}

.data-value {
  display: flex;
  gap: 0.5rem;
  font-weight: bold;
}

.data-label {
  color: var(--bannerTextColor) !important;
}

.percentage {
  display: flex;
  align-items: center;
}

.positive-percentage {
  color: #169414;
  display: flex;
  align-items: center;
  gap: 4px;
}

.negative-percentage {
  color: #ae2e2e;
  display: flex;
  align-items: center;
  gap: 4px;
}

.positive-arrow {
  color: green;
  margin-left: 5px;
}

.negative-arrow {
  color: red;
  margin-left: 5px;
}

.IDA_Virtual {
  display: flex;
  gap: 1rem;
  position: relative;
  right: 4px;
}

.IDA_Icons {
  position: relative;
  display: flex;
  flex-direction: column;
}

.IDA_Icons .beta {
  position: relative;
  bottom: 0.6rem;
}
.align-perf-dropdowns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  top: 2rem;
  padding-bottom: 3rem;
  /* background-color: aqua; */
}

.align-per-tabs {
  width: 20vw;
}

.align-strats-dropdowns {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  position: relative;
  top: 1rem;
  padding-bottom: 2rem;
  /* background-color: aqua; */
}

.align-strat-side-dropdowns {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  position: relative;
  /* top: 1rem; */
  /* padding-bottom: 2rem; */
  /* background-color: aqua; */
}

.align-strat-tabs {
  width: 16vw;
}

.align-strat-tabs-primary {
  width: 26vw;
}

.align-all-dropdowns {
  width: 26vw;
  position: relative;
  top: 0.4rem;
}

.custom-multiselect {
  border: 1px solid black;
  border-radius: 8px;
  position: relative;
  /* This is important for positioning the dropdown */
}

.custom-multiselect .dropdown-container {
  border: none !important;
  position: relative !important;
  border-radius: 8px !important;
}

.custom-multiselect .dropdown-content {
  z-index: 99999;
  border: none;
  position: absolute;
  /* Position the dropdown absolutely */
  top: 100%;
  /* Position it below the input */
  left: 0;
  width: 100%;
  /* Make it the same width as the input */
  background: var(--dropdown-bg) !important;
  /* Ensure the background is white */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  border: 1px solid black;
  border-radius: 4px;
}

.custom-select .css-1nmdiq5-menu {
  z-index: 999;
  border: none;
  position: absolute;
  /* Position the dropdown absolutely */
  top: 100%;
  /* Position it below the input */
  left: 0;
  width: 100%;
  /* Make it the same width as the input */
  background: white;
  /* Ensure the background is white */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  border: 1px solid black;
  border-radius: 4px;
}

@media only screen and (min-width: 1160px) and (max-width: 1307px) {
  .align-per-tabs {
    width: 16vw;
  }
  .IDA_Virtual {
    position: relative;
    left: 4px;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 5600px) {
  .IDA_Virtual {
    position: relative;
    left: 4px;
  }
}
