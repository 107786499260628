@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --invgray: #e6e6e6;
  --gray-dark: #343a40;
  --primary: #048c88;
  --primary_light: #8afff7;
  --primary_lighter: #d5f2f0;
  --primary_dark: #064e4a;
  --secondary: #49ae46;
  --secondary_light: #7fd67c;
  --secondary_dark: #2e9a2b;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: white;
  --dark: #343a40;
  --buttoncolor: #ffc000;
  --sidebarcolor: #144e4a;
  --text_Color: #212121;
  --surface_Color: #00403a;
  --chatWindow_Color: #fafafa;
  --chatWindow_TableHeader: #212121;
  --evenRow: rgb(229, 229, 229, 0.3);
  --tableBorder: rgba(33, 33, 33, 1);
  --button: #018e82;
  --paginationText: #8c8c8c;
  --tableText: #212121;
  --headerText: #212121;
  --lightGray: #fafafa;
  --scrollbarColor: transparent;
  --formBorder: rgb(204, 204, 204);
  --tdEven: #f7f7f7;
  --tdOdd: rgba(255, 255, 255, 1);
  --table-header-bg: rgba(229, 243, 242, 1);
  --dropdown-bg: #ffffff; /* Light mode background */
  --dropdown-hover-bg: #f0f0f0; /* Light mode hover */
  --dropdown-selected-bg: var(--button);
  --hover-on-row: #fafafa !important;
  --border-Chat: #212121;
  --sideBarIconsBg: #1a534e;
  --sideBarIconActiveColor: #07baaa;
  --sideBarIconColor: #fafafa;
  --sideBarIconActiveFilter: #dbe5e3;
  --sideBarIconFilter: #93999d;
  --QuestionsSeperator: #fafafa;
  --background-DD_Content: #e2e6ea;
  --background-DD_Seperator: #fafafa;
  --paginationBgColor: #fff;
  --paginationColor: #212121;
  --dropdownBackgroundColor: #ffffff; /* Light mode background */
  --dropdownTextColor: #000000; /* Light mode text */
  --dropdownHoverBackgroundColor: #8c8c8c;
  --filterSelectColor: #444444;
  --bannerTextColor: #212121;
  --placeHolder: #018e82;
  --inv-hover-on-options: #e6e6e6 !important;
  --filter-select-color: transparent;
  --filter-placeholder-color: #adadad;
  --modalBackground:#fff;
}
[dark-theme="dark"] {
  --filter-placeholder-color: #03dac6de;;
  --white: #191f22;
  --light: #27333a;
  --text_Color: #ffffff;
  --surface_Color: #27333a;
  --invgray: #6c757d;
  --chatWindow_Color: #27333a;
  --filter-select-color: transparent;
  --primary_lighter: #27333a;
  --evenRow: #1f292e;
  --tableBorder: rgba(255, 255, 255, 0.38);
  --button: #03dac6de;
  --paginationText: rgba(255, 255, 255, 0.6);
  --tableText: rgba(255, 255, 255, 0.6);
  --QuestionsSeperator: rgba(255, 255, 255, 0.6);
  --headerText: rgba(255, 255, 255, 0.87);
  --lightGray: #191f22;
  --chatWindow_TableHeader: #212121;
  --scrollbarColor: #03dac6de;
  --formBorder: rgba(255, 255, 255, 0.38);
  --tdEven: #27333a;
  --tdOdd: #1f292e;
  --table-header-bg: #27333a; /* Dark mode background color */
  --dropdown-bg: #191f22; /* Dark mode background */
  --dropdown-hover-bg: #444444; /* Dark mode hover */
  --dropdown-selected-bg: var(--button);
  --inv-hover-on-options: #00403a !important;
  --hover-on-row: #00403a !important;
  --border-Chat: #fafafa;
  --sideBarIconsBg: #1a534e;
  --sideBarIconActiveColor: #07baaa;
  --sideBarIconColor: #158680;
  --sideBarIconActiveFilter: invert(38%) sepia(57%) saturate(568%)
  hue-rotate(141deg) brightness(110%) contrast(80%);
  --sideBarIconFilter: invert(36%) sepia(78%) saturate(636%) hue-rotate(142deg)
  brightness(110%) contrast(89%);
  --background-DD_Content: #191f22;
  --background-DD_Seperator: #007b7b;
  --paginationBgColor: #191f22;
  --paginationColor: #fafafa;
  --dropdownBackgroundColor: #27333a; /* Dark mode background */
  --dropdownTextColor: cyan; /* Dark mode text */
  --dropdownHoverBackgroundColor: #007b7b;
  --filterSelectColor: #007b7b;
  --bannerTextColor: #03dac6de;
  --placeHolder: #03dac6de;
  --sidebarProfileColor:#007b7b;
  --modalBackground: #27333a;
}
/* @import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@400;600&display=swap"); */

* {
  margin: 0px;
  padding: 0px;
  font-family: "Mulish";

  /* font-weight: 400; */
}

select option {
  background: #fff;
  background: var(--white);
}

.issure-icon-active img {
  -webkit-filter: #dbe5e3 !important;
          filter: #dbe5e3 !important;
  -webkit-filter: var(--sideBarIconActiveFilter) !important;
          filter: var(--sideBarIconActiveFilter) !important;
}

.issure-icon img {
  -webkit-filter: #93999d !important;
          filter: #93999d !important;
  -webkit-filter: var(--sideBarIconFilter) !important;
          filter: var(--sideBarIconFilter) !important;
}


/* //--------------For Dev ENV -----------\\ */
/* START OF DEV ENV */

:where(.css-dev-only-do-not-override-11lehqq).ant-switch.ant-switch-small.ant-switch-checked .ant-switch-inner{
  background-color:transparent !important;
}

:where(.css-dev-only-do-not-override-11lehqq).ant-select-single.ant-select-open
  .ant-select-selection-item {
  color: #444444 !important;
  color: var(--filterSelectColor) !important;
}

:where(.css-dev-only-do-not-override-11lehqq).ant-select-outlined:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  background-color: #ffffff !important;
  background-color: var(--dropdownBackgroundColor) !important;
  color: #000000 !important;
  color: var(--dropdownTextColor) !important;
}
:where(.css-dev-only-do-not-override-11lehqq)[class^="ant-select"]
  [class^="ant-select"],
:where(.css-dev-only-do-not-override-11lehqq)[class*=" ant-select"]
  [class^="ant-select"],
:where(.css-dev-only-do-not-override-11lehqq)[class^="ant-select"]
  [class*=" ant-select"],
:where(.css-dev-only-do-not-override-11lehqq)[class*=" ant-select"]
  [class*=" ant-select"] {
  color: #000000 !important;
  color: var(--dropdownTextColor) !important;
}

:where(.css-dev-only-do-not-override-11lehqq).ant-select-dropdown
  .ant-select-item-option-content {
  color: #000000 !important;
  color: var(--dropdownTextColor) !important;
}
:where(.css-dev-only-do-not-override-11lehqq).ant-select-dropdown {
  background-color: #ffffff !important;
  background-color: var(--dropdownBackgroundColor) !important;
}

:where(.css-dev-only-do-not-override-11lehqq).ant-pagination {
  background-color: #fff !important;
  background-color: var(--paginationBgColor) !important;
  color: #212121 !important;
  color: var(--paginationColor) !important;
}
:where(.css-dev-only-do-not-override-11lehqq).ant-pagination
  .ant-pagination-item {
  background-color: #fff !important;
  background-color: var(--paginationBgColor) !important;
  border: none !important;
}
:where(.css-dev-only-do-not-override-11lehqq).ant-pagination li {
  display: flex !important;
  align-items: center !important;

  justify-content: center !important;
}
:where(.css-dev-only-do-not-override-11lehqq).ant-pagination
  .ant-pagination-next
  .ant-pagination-item-link {
  color: #000000 !important ;
  color: var(--dropdownTextColor) !important ;
}
:where(.css-dev-only-do-not-override-11lehqq).ant-pagination
  .ant-pagination-prev
  .ant-pagination-item-link {
  color: #000000 !important ;
  color: var(--dropdownTextColor) !important ;
}
:where(.css-dev-only-do-not-override-11lehqq).ant-pagination
  .ant-pagination-disabled
  .ant-pagination-item-link {
  color: #8c8c8c !important;
  color: var(--dropdownHoverBackgroundColor) !important;
}
:where(.css-dev-only-do-not-override-11lehqq).ant-table-wrapper
  .ant-table-row-expand-icon-cell
  .ant-table-row-expand-icon {
  background-color: #fafafa !important;
  background-color: var(--chatWindow_Color) !important;
}
:where(.css-dev-only-do-not-override-11lehqq).ant-table-wrapper
  .ant-table-row-indent
  + .ant-table-row-expand-icon {
  background-color: #fafafa !important;
  background-color: var(--chatWindow_Color) !important;
}
:where(.css-dev-only-do-not-override-11lehqq).ant-pagination
  .ant-pagination-item-active {
  background-color: #fff !important;
  background-color: var(--paginationBgColor) !important;
}

:where(.css-dev-only-do-not-override-11lehqq).ant-tooltip .ant-tooltip-inner {
  background-color: #fafafa !important;
  background-color: var(--lightGray) !important;
}

:where(.css-dev-only-do-not-override-11lehqq).ant-typography {
  color: #212121 !important;
  color: var(--tableText) !important;
}

:where(.css-dev-only-do-not-override-11lehqq).ant-input-outlined {
  background: #fff;
  background: var(--white);
  /* border-style: double; */
}

:where(.css-dev-only-do-not-override-11lehqq).ant-input-group .ant-input {
  width: 100%;
  margin-bottom: none !important;
  text-align: inherit;
  background: #fff !important;
  background: var(--white) !important;
  color: #212121 !important;
  color: var(--tableText) !important;
}

:where(.css-dev-only-do-not-override-11lehqq).ant-input-outlined {
  border-color: #018e82 !important;
  border-color: var(--placeHolder) !important;
}

:where(.css-dev-only-do-not-override-11lehqq).ant-select-single
  .ant-select-selector
  .ant-select-selection-search-input {
  background: transparent !important;
  background: var(--filter-select-color) !important;
}
:where(.css-dev-only-do-not-override-11lehqq).ant-empty-normal
  .ant-empty-description {
  color: #212121 !important;
  color: var(--tableText) !important;
}

:where(.css-dev-only-do-not-override-11lehqq).ant-dropdown .ant-dropdown-menu,
:where(.css-dev-only-do-not-override-11lehqq).ant-dropdown-menu-submenu
  .ant-dropdown-menu {
  background-color: #fff !important;
  background-color: var(--white) !important;
}
/* End of DEV ENV */
/* ---------- CSS FOR PRODUCTION ENV ------------ */
/* START OF PRODUCTION ENV */

:where(.css-11lehqq).ant-switch.ant-switch-small.ant-switch-checked .ant-switch-inner{
  background-color:transparent !important;
}

:where(.css-11lehqq).ant-dropdown .ant-dropdown-menu,
:where(.css-11lehqq).ant-dropdown-menu-submenu .ant-dropdown-menu {
  background-color: #fff !important;
  background-color: var(--white) !important;
}
:where(.css-11lehqq).ant-empty-normal .ant-empty-description {
  color: #212121 !important;
  color: var(--tableText) !important;
}
:where(.css-11lehqq).ant-select-single
  .ant-select-selector
  .ant-select-selection-search-input {
  background: transparent !important;
  background: var(--filter-select-color) !important;
}

:where(.css-11lehqq).ant-input-outlined {
  border-color: #018e82 !important;
  border-color: var(--placeHolder) !important;
}

:where(.css-11lehqq).ant-input-outlined {
  background: #fff !important;
  background: var(--white) !important;
  /* border-style: double !important; */
  color: #212121 !important;
  color: var(--tableText) !important;
}

:where(.css-11lehqq).ant-input-group .ant-input {
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
  background: #fff !important;
  background: var(--white) !important;
  color: #212121 !important;
  color: var(--tableText) !important;
}

:where(.css-11lehqq).ant-tooltip .ant-tooltip-inner {
  background-color: #fafafa !important;
  background-color: var(--lightGray) !important;
}

:where(.css-11lehqq).ant-typography {
  color: #212121 !important;
  color: var(--tableText) !important;
}

:where(.css-11lehqq).ant-select-single.ant-select-open
  .ant-select-selection-item {
  color: #444444 !important;
  color: var(--filterSelectColor) !important;
}

:where(.css-11lehqq).ant-select-outlined:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #ffffff !important;
  background-color: var(--dropdownBackgroundColor) !important;
  color: #000000 !important;
  color: var(--dropdownTextColor) !important;
}
:where(.css-11lehqq)[class^="ant-select"] [class^="ant-select"],
:where(.css-11lehqq)[class*=" ant-select"] [class^="ant-select"],
:where(.css-11lehqq)[class^="ant-select"] [class*=" ant-select"],
:where(.css-11lehqq)[class*=" ant-select"] [class*=" ant-select"] {
  color: #000000 !important;
  color: var(--dropdownTextColor) !important;
}

:where(.css-11lehqq).ant-select-dropdown .ant-select-item-option-content {
  color: #000000 !important;
  color: var(--dropdownTextColor) !important;
}
:where(.css-11lehqq).ant-select-dropdown {
  background-color: #ffffff !important;
  background-color: var(--dropdownBackgroundColor) !important;
}

:where(.css-11lehqq).ant-pagination {
  background-color: #fff !important;
  background-color: var(--paginationBgColor) !important;
  color: #212121 !important;
  color: var(--paginationColor) !important;
}
:where(.css-11lehqq).ant-pagination li {
  display: flex !important;
  align-items: center !important;

  justify-content: center !important;
}
:where(.css-11lehqq).ant-table-wrapper
  .ant-table-row-expand-icon-cell
  .ant-table-row-expand-icon {
  background-color: #fafafa !important;
  background-color: var(--chatWindow_Color) !important;
}
:where(.css-11lehqq).ant-table-wrapper
  .ant-table-row-indent
  + .ant-table-row-expand-icon {
  background-color: #fafafa !important;
  background-color: var(--chatWindow_Color) !important;
}
:where(.css-11lehqq).ant-pagination .ant-pagination-item-active {
  background-color: #fff !important;
  background-color: var(--paginationBgColor) !important;
}
:where(.css-11lehqq).ant-pagination .ant-pagination-item {
  background-color: #fff !important;
  background-color: var(--paginationBgColor) !important;
  border: none !important;
}
:where(.css-11lehqq).ant-pagination
  .ant-pagination-next
  .ant-pagination-item-link {
  color: #000000 !important ;
  color: var(--dropdownTextColor) !important ;
}
:where(.css-11lehqq).ant-pagination
  .ant-pagination-prev
  .ant-pagination-item-link {
  color: #000000 !important ;
  color: var(--dropdownTextColor) !important ;
}
:where(.css-11lehqq).ant-pagination
  .ant-pagination-disabled
  .ant-pagination-item-link {
  color: #8c8c8c !important;
  color: var(--dropdownHoverBackgroundColor) !important;
}

/* END OF PRODUCTION ENV */

.App {
  overflow: auto;
}

body {
  background: #fff !important;
  background: var(--white) !important;
  font-family: "Open Sans", sans-serif;
  line-height: 24px;
  color: #212121;
  color: var(--text_Color);
}

html {
  min-height: 100vh;
}

body {
  min-height: 100vh;
}

button {
  color: #212121;
  color: var(--text_Color);
}

/* .page-content {
  margin-bottom: 10px !important;
} */

/* .sidebar{ width: 80px;box-shadow: 0px 5px 5px #ccc; float: left; background:var(--primary_dark); position: fixed; z-index: 1000; min-height: 100vh;}
.sidebar ul{ margin: 0px; padding: 0px; color: var(--white)}
.sidebar ul li{ display: block; position: relative;}
.sidebar ul li a{ display: block; text-transform: none; border-left: 3px solid #144e4a; padding: 0px}
.sidebar ul li a:hover{border-left: 3px solid #85f9f9}
.sidebar ul li a .MuiButton-label{ text-transform: none}
.sidebar ul li a .MuiButton-label .icon{ display: block; text-align: center}
.sidebar ul li a .MuiButton-label .text{ display: none; color: var(--white)}
.sidebar ul li a button{ display: block; text-decoration: none!important; width: 100%; text-align: left; padding: 12px 10px; border-radius: 0px!important;}
.sidebar ul li.logoPlaceholder a button{ padding: 10px 0px; margin-left: 0px; background: #fff;}
.sidebar ul li.logoPlaceholder a{ border: none!important;}
.sidebar ul li.logoPlaceholder a img{ max-width: 60px;}
.sidebar ul li a:hover{background: var(--primary)}
.sidebar ul li:hover > a{background:  var(--primary)}
.sidebar ul li a.active{background: #185955}
.sidebar ul li a img{ display: inline-block; max-width: 35px; vertical-align: middle}
.sidebar ul li ul{ display: none; position: absolute; z-index: 1000; left: 80px; top: 0px; width: 300px; background:  var(--primary);}
.sidebar ul li:hover ul{ display: block;}
.sidebar ul ul a{ border-bottom: 1px solid #1e6b66}
.sidebar ul ul a .MuiButton-label .icon{ display: inline-block; text-align: left; margin-right: 15px;}
.sidebar ul ul a .MuiButton-label .text{ display: inline-block;}
.sidebar ul ul a .MuiButton-label .text{ display: block;}
.bottomMenu{ position: absolute; width: 100%; bottom: 0px;}
.sidebar ul li.userInfo a .MuiButton-label .text{ display: block; margin: auto; text-align: center; background: #4aae46; font-weight: bold; height: 35px; width: 35px; text-align: center; line-height: 35px; border-radius: 50%}
.sidebar ul li.showMenu{ border-top: 1px solid #2e6a68}
.sidebar ul li.showMenu a img{ display: inline-block; max-width: 15px; vertical-align: middle}
.sidebar ul li.showMenu a button{ padding: 5px} */

.sidebar {
  width: 70px;
  float: left;
  background: #00403a;
  background: var(--surface_Color);
  position: fixed;
  z-index: 1000;
  min-height: 100vh;
}

.sidebar ul {
  margin: 0px;
  padding: 0px;
  color: #fff;
}

/* .sidebar ul li{ display: block; position: relative;} */
/* .sidebar ul li a{ display: block; text-transform: none; border-left: 3px solid #144e4a; padding: 0px} */
/* .sidebar ul li a:hover{border-left: 3px solid #85f9f9} */
.sidebar ul li a .MuiButton-label {
  text-transform: none;
}

.sidebar ul li a .MuiButton-label .icon {
  display: block;
  text-align: center;
}

a:hover {
  color: none !important;
  text-decoration: none !important;
}
.sidebar ul li a .MuiButton-label .text {
  display: none;
  color: #fff;
}

.sidebar ul li a button {
  display: block;
  text-decoration: none !important;
  width: 100%;
  text-align: left;
  padding: 15px 15px 15px 12px;
  border-radius: 0px !important;
}

.sidebar ul li.logoPlaceholder {
}

#sub_heading {
  margin: 0px -15px;
  padding: 15px 20px;
}

#sub_heading .pageHeading {
  line-height: 25px;
  font-size: 18px;
  font-weight: bold;
}

#sub_heading .pageHeading span.pageTitle {
  font-size: 18px;
  line-height: normal;
}
/* 
.sidebar ul li.logoPlaceholder a button {
  padding: 10px 0px;
  margin-left: 0px;
  background: #fff;
}

.sidebar ul li.logoPlaceholder a {
  border: none !important;
}

.sidebar ul li.logoPlaceholder a img {
  max-width: 60px;
}

*/
.sidebar ul li a:hover {
  /* background: #185955; */
  width: 185px;
  position: relative;
  justify-content: flex-start;
}

.sidebar ul li:hover > a {
  /* background: #185955; */
}

/* .sidebar ul li a.active{background: #185955} */
.sidebar ul li a img {
  display: inline-block;
  max-width: 26px;
  vertical-align: middle;
}

.sidebar ul li ul {
  display: none;
  position: absolute;
  z-index: 1000;
  left: 80px;
  top: 0px;
  width: 250px;
  list-style-type: none;
}

.sidebar ul .subMenu {
  width: 300px;
  background: #fff;
  box-shadow: 0px 0px 5px #ccc;
  padding: 15px;
  margin-left: 15px;
}

.sidebar ul li:hover ul {
  display: block;
}

.sidebar ul ul a {
  border-left: none !important;
  border-radius: 7px;
}

.sidebar ul ul a:hover {
  border-left: none !important;
  /* background-color: #d5f2f0 !important; */
}

.sidebar ul ul li a button {
  padding: 8px 15px 8px 12px;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 15px solid #fff;
  box-shadow: 0px 0px 5px #ccc;
  position: absolute;
  left: 0px;
}

.arrow_box {
  position: relative;
  background: #f5f5f5;
  /* border: 1px solid #ccc; */
  box-shadow: 0px 0px 5px #ccc;
  max-width: 250px;
  width: 100%;
  padding: 15px;
}

.arrow_box:after,
.arrow_box:before {
  right: 100%;
  top: 25px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  border-color: rgba(245, 245, 245, 0);
  border-right-color: #f5f5f5;
  border-width: 10px;
  margin-top: -10px;
}

.arrow_box:before {
  border-color: rgba(25, 17, 245, 0);
  /* border-right-color: #ccc; */
  border-width: 16px;
  margin-top: -16px;
}

.sidebar ul ul a .MuiButton-label .icon {
  display: inline-block;
  text-align: left;
  margin-right: 15px;
}

.sidebar ul ul a .MuiButton-label .text {
  display: inline-block;
  color: #000 !important;
}

/* .sidebar ul ul a .MuiButton-label .text{ display: block;} */
.bottomMenu {
  position: absolute;
  width: 100%;
  bottom: 0px;
}

.sidebar ul li.userInfo a .MuiButton-label .text {
  display: block;
  margin: auto;
  text-align: center;
  background: #fff;
  color: #000;
  font-weight: bold;
  height: 35px;
  width: 35px;
  text-align: center;
  line-height: 35px;
  border-radius: 50%;
}

.sidebar ul li.showMenu {
  border-top: 1px solid #2e6a68;
}

.sidebar ul li.showMenu a img {
  display: inline-block;
  max-width: 15px;
  vertical-align: middle;
}

.sidebar ul li.showMenu a button {
  padding: 5px;
}

.content {
  padding-left: 70px;
  overflow-x: hidden;
}

.content-bdb {
  padding-left: 70px;
  /* overflow: hidden; */
  overflow-x: hidden;
  height: 100vh;
}

/***Login***********/
.login-sitebar {
  height: 100vh;
}

.site-header-without-login {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  z-index: 10;
  padding: 10px;
  height: 100px;
  text-align: center;
  background: #fff;
  box-shadow: 0px 0px 5px #ccc;
}

.site-header-without-login h2 {
  margin-bottom: 0px;
  font-size: 40px;
}

/* #mainContent{height:calc(100vh - 0px ) ; padding-top: 100px;}
#mainContent > .container-fluid{ min-height: 100%;} */
.container-fluid .custom-height {
  height: 100vh;
}

.login-left-image {
  background: url(/static/media/loginimage2.482cde5a.png) center center no-repeat;
  background-size: cover;
  position: fixed;
  right: 0;
  top: 0;
}

.login-left-image::before {
  background: rgba(0, 113, 110, 0.4) 0% 0% no-repeat;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  content: "";
}

.login-content {
  padding: 50px 110px;
  width: 760px;
  position: relative;
  z-index: 2;
}

.login-content1 {
  padding: 100px 130px;
  width: 100%;
  position: relative;
  z-index: 2;
}

.login-content h1 {
  /* line-height: 0%; */
  margin: 0px;
  color: white;
  font-size: 56px;
  font-weight: 400;
  font-family: "Catamaran", sans-serif;
  width: 90%;
}

.login-content p {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 50px;
  color: white;
  width: 85%;
}

.login-content h3 {
  font-size: 26px;
  padding-bottom: 0px;
}

.login-content h4 {
  font-size: 26px;
  padding-bottom: 0px;
  text-align: center;
  padding-bottom: 50px;
}

/* .custom-height-scrollbar{ overflow-x:auto;} */
.custom-height-scrollbar {
  overflow-y: auto;
}

.left-content {
  color: #fff;
  color: var(--white);
}

.left-content p {
  line-height: 35px;
}

.without-login-wrapper {
  max-width: 1340px;
  width: 100%;
  margin: auto;
  padding: 0px;
  position: relative;
}

.without-login-wrapper h4 {
  font-size: 21px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  text-align: center;
  padding-bottom: 20px;
}

.lineDivider,
.lineDivider span {
  background: #fff;
  background: var(--white);
  position: relative;
  z-index: 3;
  padding: 5px 15px;
}

.lineDivider {
  display: block;
  text-align: center;
  margin: 0 0 20px;
  z-index: 2;
}

.lineDivider:before {
  content: "";
  z-index: 1;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
  font-size: 0;
  background: #ccc;
}

.loginCheckbox {
  margin: 25px 30px 0 20px;
}

.nav-link.loginBtn {
  padding: 0px;
  width: 100%;
  background: #fff;
  background: var(--white);
  text-align: center;
}

.loginBtn button {
  width: 100%;
  padding: 8px 22px;
}

.form-group.field {
  margin-bottom: 0px;
}

label.form-group.field {
  margin-bottom: 5px;
  display: none;
}

#form-btn {
  max-width: 650px;
}

.main-container {
  display: flex;
}

.left-container {
  width: 55%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.right-container {
  width: 45%;
}

.left-container-form {
  width: 65%;
}

.logo-container {
  align-self: flex-start;
  margin-left: 20px;
}

.logo-container img {
  height: 60px;
  width: auto;
  position: relative;
  top: 25px;
}

.custom-class-password {
  padding-bottom: 15px;
}

.custom-class-password label {
  font-size: 15px;
  font-style: normal;
}

.custom-class-password input {
  border: 1px solid #212121;
  border-radius: 8px;
  padding: 20px 10px;
  color: #cac7c7;
}

/* .custom-class-passwords select{border: 1px solid #212121; border-radius: 8px; padding:20px 10px;color: #e27272;} */
.MuiButton-root {
  font-family: "Mulish", sans-serif !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 19px;
  margin-top: 15px !important;
  padding: 5px 30px !important;
  text-transform: none !important;
  border: 1.2px solid rgba(33, 33, 33, 1) !important;
  border: 1.2px solid var(--tableBorder) !important;
  border-radius: 20px !important;
  box-shadow: none !important;
}

/* .MuiButton-root:hover{
  background-color: none !important;
}
.MuiButton-root:focus{
  background-color: none !important;
}
.MuiButton-root:active{
  background-color: none !important; */
/* } */
.topcontent {
  margin-top: 150px !important;
}

.topcontent1 {
  margin-top: 150px !important;
}

.heading1 {
  font-family: "Catamaran", sans-serif;
  letter-spacing: 0px;
  font-weight: 600;
  color: #212121;
  font-size: 30px;
  margin-bottom: 60px;
}

.heading2 {
  color: #212121;
  font-size: 22px;
}

.register1__title-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  color: #212121;
}

.register1__title-container1 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
  color: #212121;
}

.justify-content {
  align-items: end !important;
}

.register1__title-container1s {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.register1__title-container h5 {
  width: 90%;
}

/* .login_sign-in_button{
  width: 110px;
  height: 38px;
  background: #FFC000;
  border: 1.2px solid #212121;
  border-radius: 20px;
} */

/* kyc1 */
.kyc-container {
  height: 100vh;
  overflow: scroll;
}

.kyc-title-container {
  color: #212121;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.kyc-title-container h5 {
  width: 90%;
  margin-left: 5px;
}

.card__container {
  border: 1px solid #212121;
  width: 100%;
  border-radius: 8px;
  padding: 20px;
  text-align: left;
  margin-bottom: 25px;
  background-color: #fafafa;
}

.MuiButton-label {
  font-family: "Mulish", sans-serif;
  font-weight: 600;
  font-size: 15;
}

.card1__title {
  font-family: "Mulish", sans-serif;
  color: #212121;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  /* line-height: 19px; */
  margin-bottom: 10px;
}

.card1__titles1 {
  font-family: "Mulish", sans-serif;
  color: #212121;
  margin-right: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  /* line-height: 19px; */
  margin-bottom: 10px;
}

.alertpopup {
  font-family: "Mulish", sans-serif;
  color: #212121;
  margin-right: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  /* line-height: 19px; */
  margin-bottom: 30px;
}

.card1__title1 {
  color: #212121;
  font-style: normal;
  font-weight: 200;
  font-size: 15.5px;
}

.card1__list {
  color: #212121;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 20px;
  margin-top: 0;
}

.card__button {
  border: 1.2px solid rgba(33, 33, 33, 1) !important;
  border: 1.2px solid var(--tableBorder) !important;
  border-radius: 20px;
  width: 120px;
  height: 42px;
  border: 1.2px solid rgba(33, 33, 33, 1) !important;
  border: 1.2px solid var(--tableBorder) !important;
  /* padding-top: 15px!important; */
}

.card__button-x {
  background-color: #000333 !important;
}

.card__button2 {
  border: 1.2px solid #212121 !important;
  border-radius: 20px !important;
  /* width: 150px; */
  height: 40px;
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.button0 {
  margin-right: 0 !important;
}

.card__button1 {
  margin-bottom: 30px !important;
  border: 1.2px solid #212121 !important;
  border-radius: 20px;
  width: 120px;
  height: 42px;
  margin-top: 2px !important;
}

.form__container {
  color: #212121;
  width: 100%;
  border-radius: 8px;
  text-align: left;
  margin-bottom: 25px;
}

.form__title {
  color: #212121;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  margin-bottom: 23px;
}

.form__button {
  border: 1.2px solid #212121;
  border-radius: 20px;
  width: 110px;
  height: 38px;
  margin-top: 10px;
}

.input-outline input {
  border: 2px solid #212121;
  border-radius: 10px;
}

.form-para {
  color: #212121;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  /* margin-bottom: 10px; */
  margin-top: 15px;
  margin-bottom: 6px;
}

.left-arrow-mui {
  color: #018e82;
  cursor: pointer;
}

.left-arrow-muis {
  color: #018e82;
  cursor: pointer;
  margin-right: 10px;
  font-size: 35px !important;
}

.toolsmuis {
  margin-top: 15px !important;
}

.Reviewdata {
  margin-top: 15px !important;
}

.filter-icon {
  margin-top: 15px !important;
}

.left-arrow-servicer {
  color: #018e82;
  color: var(--button);
}

.left-arrow-muis1 {
  color: #018e82;
  cursor: pointer;
  /* margin-top: 8px; */
  margin-right: 10px;
  font-size: 35px !important;
}

/* popup css */
.card__container1 {
  margin: 0 auto;
  /* Added */
  float: none;
  /* Added */
  margin-bottom: 10px;
  /* Added */
  border: 1px solid #212121;
  width: 28%;
  border-radius: 8px;
  padding: 23px 28px;
}

.card__container2 {
  margin: 0 auto;
  /* Added */
  float: none;
  /* Added */
  margin-bottom: 10px;
  /* Added */
  border: 2px solid #212121;
  width: 25%;
  border-radius: 8px;
  padding: 23px 28px;
}

.popupheading {
  font-family: "Catamaran", sans-serif;
  margin-bottom: 10px;
  color: #212121;
  color: var(--tableText);
  font-size: 20px;
  font-weight: 600;
}

.popupbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px !important;
  margin-bottom: 10px;
}

.shiftingpopup {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-sign-up-para {
  color: #212121;
  text-align: center;
  font-size: 16px !important;
  line-height: 19px;
  margin-top: 7rem;
}

.login-sign_up-link {
  text-decoration: none !important;
  color: #018e82;
}

.login-sign_up-linksview {
  padding-left: 15px !important;
  text-decoration: none !important;
  color: #018e82;
  color: var(--button);
}

.login-sign_up-links {
  text-decoration: none !important;
  color: #018e82;
  color: var(--button);
  width: 10rem;
  font-size: 15px;
  font-weight: 600;
}

.login-sign_up-linksadd {
  border: none;
  background: none !important;
  text-decoration: none !important;
  color: #018e82;
  color: var(--button);
  font-size: 15px;
  font-weight: 600;
}

.login-sign_up-links1 {
  text-decoration: none !important;
  color: #212121;
  font-size: 16px;
  padding: 15px;
}

.login-sign_up-links1:active {
  text-decoration: none !important;
  color: #c42929;
  font-size: 16px;
  padding: 15px;
}

.login-sign_up-links11 {
  text-decoration: none !important;
  color: #212121;
  font-size: 16px;
  padding: 10px;
  margin-right: 450px;
}

.login-sign_up-links111 {
  text-decoration: none !important;
  color: #212121;
  font-size: 16px;
  padding: 10px;
  margin-right: 650px;
}

.login-sign_up-links11:active {
  text-decoration: none !important;
  color: #fa2424;
  font-size: 16px;
  padding: 10px;
  margin-right: 450px;
}

/************Form Loader**************/
.form-loader {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  z-index: 10;
}

.header {
  margin-top: -10px;
  background: rgba(1, 142, 130, 0.1);
  box-shadow: 0px 2px 2px rgba(1, 142, 130, 0.2);
  padding: 0px 18px;
  font-size: 21px;
  height: auto;
}

.header a + a {
  margin-left: 15px;
}

.header-sub {
  background: #e2ebea;
  margin-top: 2px;
  padding: 5px 25px;
  box-shadow: 0px 3px 3px #ccc;
  font-size: 21px;
}

.page-content {
  background: #fff;
  background: var(--white);
  margin-top: 25px;
  margin-left: 35px;
  margin-right: 35px;
  box-shadow: none !important;
}

.page-content-Dashboard {
  background: #fff;
  background: var(--white);
  /* margin-top: 25px; */
  margin-left: 35px;
  margin-right: 35px;
  box-shadow: none !important;
}

.page-contentofpool {
  background: #fff;
  background: var(--white);
  margin-top: 50px;
  margin-left: 35px;
  margin-right: 35px;
  box-shadow: none !important;
}

.page-contentofpool1 {
  background: #fff;
  background: var(--white);
  /* margin-top: 30px; */
  margin-left: 35px;
  margin-right: 35px;
  /* position: relative; */
  box-shadow: none !important;
}

.site-header-without-login + .container-fluid {
  background: #fff;
  background: var(--white);
}

.MuiTableCell-root {
  height: 55px !important;
  padding: 0px 16px !important;
}

.MuiTableCell-root .MuiSvgIcon-root {
  margin-right: 5px;
}

body {
  background: #048c88;
  background: var(--primary);
}

table {
  /* border : 0.2px solid var(--tableBorder) !important; */
}

table thead {
  background-color: #d5f2f0;
  background-color: var(--primary_lighter);
  color: #212121;
  color: var(--headerText);
}
table thead tr th span button span {
  color: #212121;
  color: var(--headerText);
}

table tfoot {
  background-color: #fff;
  background-color: var(--white);
}

table thead tr th.MuiTableCell-root {
  background-color: #d5f2f0 !important;
  background-color: var(--primary_lighter) !important;
  color: #212121;
  color: var(--text_Color);
}

.rjsf {
  padding: 15px;
  margin: 0px -15px;
}

.rjsf .MuiFormControl-root {
  width: 100%;
}

.rjsf .MuiGrid-spacing-xs-2 > .MuiGrid-item {
  padding: 0px 10px;
  margin-bottom: 25px !important;
}

.rjsf .MuiGrid-spacing-xs-2 .MuiFormControl-marginDense {
  margin: 0px;
  padding: 0px;
}

.rjsf .MuiFilledInput-root {
  background: #fff !important;
  border: 1px solid #ccc;
  text-align: left;
}

.MuiButton-containedSizeLarge {
  padding: 10px 10px !important;
  font-size: 18px !important;
}

.MuiToolbar-gutters {
  padding: 0px 0px !important;
}

.file-upload-wrapper {
  padding: 30px;
}

.file-upload-wrapper .filepond--drop-label {
  padding: 30px;
}

.file-upload-wrapper .filepond--drop-label.filepond--drop-label label {
  font-size: 24px;
}

.ReactModal__Overlay {
  z-index: 1001;
}

.closePopup {
  position: absolute !important;
  color: #018e82;
  color: var(--button);
  right: 10px;
  top: 20px;
  background: none;
  border: none;
}

.closePopup:hover {
  color: #018e82;
  color: var(--button);
}

.modalPopup {
  overflow-x: hidden;
  margin: -20px;
  background-color: #fff;
  background-color: var(--white);
  /* border : 1px solid var(--formBorder); */
}

.modalPopup h2 {
  font-family: "Catamaran", sans-serif !important;
  font-weight: 600;
  font-size: 24px;
  padding: 30px;
  color: #212121;
  color: var(--headerText);
}

.modalPopup h4 {
  font-family: "Catamaran", sans-serif !important;
  font-weight: 600;
  font-size: 24px;
  padding: 30px 30px 10px 30px;
}

.modalPopup h3 {
  color: #212121;
  color: var(--tableText);
  font-size: 20px;
  font-weight: 600;
  padding: 30px 30px 10px 30px;
}

.col-form-label {
  text-transform: capitalize;
}

.MuiFormControl-root {
  width: 100%;
  max-width: 650px;
}

.MuiGrid-container .MuiGrid-item {
  width: 33.33%;
  max-width: 33.33%;
  flex-basis: 33.33%;
}

.tableSearch {
  background: #f1f7f7;
  padding: 15px 40px;
  margin: 0px;
}

.tableSearch1 {
  margin-top: 27px;
  margin-bottom: 10px;
}

#searchBox .MuiButtonBase-root {
  margin-right: 15px;
}

#searchBox .MuiFormControl-root {
  width: 100%;
  border-radius: 5px !important;
  max-width: 550px !important;
}

h3.title-page {
  font-size: 21px;
  font-weight: bold;
  border-bottom: 1px rgb(204, 204, 204);
  border-bottom: 1px var(--formBorder);
  padding: 0px 0px 15px 0px;
}

.form_row_single .MuiGrid-container .MuiGrid-item,
.modalPopup .MuiGrid-container .MuiGrid-item {
  width: 100%;
  max-width: 100%;
  flex-basis: 100%;
}

.modalPopup .rjsf {
  margin: 0px;
}

.form_row_two .MuiGrid-container .MuiGrid-item {
  width: 100%;
  max-width: 50%;
  flex-basis: 50%;
}

.form_row_two .MuiFormControl-root {
  width: 100%;
  max-width: 100%;
}

.navbarStepsBtm button {
  margin-left: 16px;
}

.navbarStepsBtm {
  position: fixed;
  z-index: 100;
  bottom: 0px;
  width: calc(100%);
  left: 0px;
  padding: 20px 35px;
  box-shadow: -29px 0px 13px 0 rgba(14, 16, 16, 0.13);
  background-color: #eef6f6;
}

#btn-group.modalPopup {
  padding-bottom: 65px;
}

.portolio-details {
  padding: 10px 10px;
  background: #fff;
}

.portolio-details p {
  padding: 0px !important;
  margin: 0px !important;
  font-weight: bold;
}

.portolio-details p.strong {
  font-weight: normal;
  font-size: 14px;
}

.portolio-details2 {
  padding: 10px 10px;
  background: #fff;
}

.portolio-details2 p {
  padding: 0px !important;
  margin: 0px !important;
  font-weight: normal;
}

.portolio-details2 p label {
  font-weight: normal;
  font-size: 13px;
  display: block;
  margin: 0px;
  padding: 0px;
  color: #7d8887;
}

.portolio-details2-head {
  padding: 20px 0 30px;
  font-size: 22px;
}

.payment_status span {
  color: #048c88;
  font-size: 14px;
  margin-left: 15px;
}

.no-margin {
  margin-bottom: 50px !important;
}

.logout {
  padding: 10px !important;
}

.logout svg {
  width: 45px;
}

.logout.MuiButton-containedPrimary {
  width: 100%;
  display: block;
}

.sidebar ul li a.active {
  background: #048c88;
  background: var(--primary);
}

.sidebar ul li li a.active {
  background: #d5f2f0 !important;
}

/* .backbtn-wrapper { position: relative; top: -20px; background: red;} */
.backbtn-wrapper .float-left p:nth-child(1) {
  font-size: 14px;
  padding: 0px;
  margin: 0px;
  height: 20px;
}

.backbtn-wrapper .float-left p:nth-child(2) {
  font-size: 18px;
  padding: 0px;
  margin: 0px;
  height: 20px;
}

.backbtn {
  padding: 0px;
  max-width: 30px;
  min-width: 20px;
  text-align: center;
  float: left;
}

.nopadding {
  padding: 0px 25px;
}

.backbtn-wrapper a#backbtn {
  padding: 0px;
  max-width: 30px;
  min-width: 30px;
  margin-top: 10px;
}

/* .stepBar{ position: relative; top: -20px;} */
.stepBar ul {
  margin: 0px;
  padding: 0px;
  color: #fff;
}

.stepBar ul li {
  display: inline-block;
  position: relative;
}

.stepBar::before {
  position: absolute;
  left: 0px;
  top: 0px;
  background: none !important;
  content: "";
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.stepBar ul li::before {
  width: 50%;
  margin-top: -12px;
  background: #f1f7f7;
  position: absolute;
  left: 0px;
  top: 50%;
  z-index: 1;
  content: "";
  height: 2px;
}

.stepBar ul li::after {
  width: 50%;
  margin-top: -12px;
  background: #f1f7f7;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  content: "";
  height: 2px;
}

.stepBar ul li:first-child::before {
  left: 50%;
}

.stepBar ul li:last-child::before {
  width: 50%;
}

.stepBar ul li a {
  display: block;
  text-transform: none;
  text-align: center;
  padding: 0px;
}

.stepBar ul li a .MuiButton-label {
  text-transform: none;
  text-align: center;
}

.stepBar ul li a .MuiButton-label .icon {
  display: none;
  text-align: center;
}

.stepBar ul li a .MuiButton-label .text {
  display: block;
  color: #999;
  text-align: center;
}

.stepBar ul li a .MuiButton-label .step {
  z-index: 2;
  position: relative;
  margin: 5px 0px;
  display: block;
  width: 30px;
  height: 30px;
  text-align: center;
  margin: auto;
  background: #999;
  color: #fff;
  border-radius: 100%;
  line-height: 30px;
}

.stepBar ul li a button {
  display: block;
  text-decoration: none !important;
  width: 100%;
  text-align: left;
  padding: 5px 30px 5px 30px;
  border-radius: 0px !important;
}

.stepBar ul li a.active .MuiButton-label .text,
.stepBar ul li:hover a .MuiButton-label .text {
  color: #2c8c84 !important;
  font-weight: bold;
}

.stepBar ul li a.active .MuiButton-label .step,
.stepBar ul li:hover a .MuiButton-label .step {
  background: #2c8c84 !important;
  font-weight: bold;
}

/* Step 2 */
.stepBar ul.stepActive2 li.step1::after,
.stepBar ul.stepActive2 li.step2::before {
  background: #5eb4af;
}

.stepBar ul.stepActive2 li.step1 a .MuiButton-label .text {
  color: #5eb4af !important;
  font-weight: bold;
}

.stepBar ul.stepActive2 li.step1 a .MuiButton-label .step {
  background: #5eb4af !important;
  font-weight: bold;
}

/* Step 3 */
.stepBar ul.stepActive3 li.step1::after,
.stepBar ul.stepActive3 li.step2::before,
.stepBar ul.stepActive3 li.step2::after,
.stepBar ul.stepActive3 li.step3::before {
  background: #5eb4af;
}

.stepBar ul.stepActive3 li.step1::after,
.stepBar ul.stepActive3 li.step2::before {
  background: #5eb4af;
}

.stepBar ul.stepActive3 li.step1 a .MuiButton-label .text,
.stepBar ul.stepActive3 li.step2 a .MuiButton-label .text {
  color: #5eb4af !important;
  font-weight: bold;
}

.stepBar ul.stepActive3 li.step1 a .MuiButton-label .step,
.stepBar ul.stepActive3 li.step2 a .MuiButton-label .step {
  background: #5eb4af !important;
  font-weight: bold;
}

/* Step 4 */
.stepBar ul.stepActive4 li.step1::after,
.stepBar ul.stepActive4 li.step2::before,
.stepBar ul.stepActive4 li.step2::after,
.stepBar ul.stepActive4 li.step3::before,
.stepBar ul.stepActive4 li.step1::after,
.stepBar ul.stepActive4 li.step2::before,
.stepBar ul.stepActive4 li.step3::after,
.stepBar ul.stepActive4 li.step3::before,
.stepBar ul.stepActive4 li.step4::before {
  background: #5eb4af;
}

.stepBar ul.stepActive4 li.step1 a .MuiButton-label .text,
.stepBar ul.stepActive4 li.step2 a .MuiButton-label .text,
.stepBar ul.stepActive4 li.step3 a .MuiButton-label .text {
  color: #5eb4af !important;
  font-weight: bold;
}

.stepBar ul.stepActive4 li.step1 a .MuiButton-label .step,
.stepBar ul.stepActive4 li.step2 a .MuiButton-label .step,
.stepBar ul.stepActive4 li.step1 a .MuiButton-label .step,
.stepBar ul.stepActive4 li.step3 a .MuiButton-label .step {
  background: #5eb4af !important;
  font-weight: bold;
}

span.currency {
  background: #048c88;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: block;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 15px;
  float: left;
}

.innerspace {
  padding-left: 80px;
  position: relative;
  min-height: 80px;
}

.innerspace p + p {
  color: #7d8887;
  font-size: 14px;
}

.paidOn {
  color: #7d8887;
  font-size: 14px;
}

.paymenttype {
  color: #7d8887;
  font-size: 14px;
  margin-left: 10px !important;
}

.MuiPopover-paper {
  background: #fff;
  box-shadow: 0px 5px 5px #000;
  padding: 5px;
}

.MuiPopover-paper ul li {
  padding: 10px 15px;
}

.MuiPopover-paper ul li svg {
  margin-right: 15px;
}

.innerpadding {
  padding: 30px 15px;
}

.innerpadding h3.title-page {
  font-size: 15px;
}

.setting-wrapper {
  padding: 15px;
  background: #f5f5f5;
  margin-top: 20px;
  box-shadow: 0px 0px 5px #ccc;
}

.setting-wrapper h4 {
  font-size: 15px;
  font-weight: bold;
}

.logo-form p {
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}

/* .checkbox-group{ margin-left: -10px;} */
/* .setting-wrapper p{ padding-bottom: 0px; margin-bottom: 0px;} */
.table-wrapper table {
  width: 100%;
  font-size: 14px;
}

.table-wrapper table p {
  margin-bottom: 10px;
}

.table-wrapper {
  width: 100%;
  border: 1px solid #fff;
  padding: 25px;
}

.pdf-address {
  width: 35%;
}

.table-format-content {
  margin: 10px 0px;
}

.table-format-content td {
  padding: 7px;
  border: 1px solid #ccc;
}

.table-format-content th {
  padding: 7px;
  border: 1px solid #ccc;
  background: #f5f5f5;
}

.table-wrapper table td {
  position: relative;
}

.table-wrapper table td .MuiIconButton-root {
  position: absolute;
  left: 0px;
  margin-left: -30px;
}

.table-wrapper table td .MuiIconButton-root.startdate {
  left: auto;
  right: 0px;
  margin-left: auto;
  margin-right: -30px;
}

.steps {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  overflow-x: auto;
}

.steps .step:first-child {
  margin-left: auto;
}

.steps .step:last-child {
  margin-right: auto;
}

.step:first-of-type .step-circle::before {
  display: none;
}

.step:last-of-type .step-content {
  padding-right: 0;
}

.step-content {
  box-sizing: content-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 5rem;
  min-width: 5rem;
  max-width: 5rem;
  padding-top: 0.5rem;
  padding-right: 1rem;
}

.step-circle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  color: #adb5bd;
  border: 2px solid #adb5bd;
  border-radius: 100%;
  background-color: #fff;
}

.step-circle::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: -2px;
  width: calc(5rem + 1rem - 1.5rem);
  height: 2px;
  -webkit-transform: translate(-100%, -50%);
          transform: translate(-100%, -50%);
  color: #adb5bd;
  background-color: currentColor;
}

.step-text {
  color: #adb5bd;
  word-break: break-all;
  margin-top: 0.25em;
}

.step-active .step-circle {
  color: #fff;
  background-color: #064e4a;
  background-color: var(--primary_dark);
  border-color: #064e4a;
  border-color: var(--primary_dark);
}

.step-active .step-circle::before {
  color: #064e4a;
  color: var(--primary_dark);
}

.step-active .step-text {
  color: #064e4a;
  color: var(--primary_dark);
}

.step-error .step-circle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.step-error .step-circle::before {
  color: #dc3545;
}

.step-error .step-text {
  color: #dc3545;
}

.step-success .step-circle {
  color: #28a745;
  background-color: #fff;
  border-color: #28a745;
}

.step-success .step-circle::before {
  color: #28a745;
}

.step-success .step-text {
  color: #28a745;
}

.card-body .rjsf {
  padding: 0px;
}

.card {
  margin-bottom: 20px;
}

.card h5.card-title {
  font-weight: bold;
  font-size: 18px;
}

img {
  max-width: 100%;
}

.image-placeholder {
  position: relative;
  padding: 15px;
  border: 2px solid #ccc;
  padding-bottom: 55px;
  border-radius: 10px;
}

.image-placeholder .icon-placeholder {
  position: absolute;
  bottom: 10px;
  width: 100%;
  left: 0px;
}

.image-placeholder .icon-placeholder button {
  border: 1px solid #048c88 !important;
  margin-left: 10px;
}

button div.loader {
  padding: 0px;
  position: relative;
}

button div.loader .MuiCircularProgress-root {
  width: 15px !important;
  height: 15px !important;
  margin: 2px 15px 0 0;
}

button div.loader .MuiCircularProgress-root svg {
  width: 15px;
  height: 15px;
}

button
  div.loader
  .MuiCircularProgress-root
  svg
  .MuiCircularProgress-circleIndeterminate {
  color: #fff;
}

div.loader {
  padding: 25px;
}

.noDataDisp {
  padding: 50px;
}

table.MuiTable-root .MuiButton-outlinedPrimary {
  max-width: 40px;
  min-width: 40px;
  padding: 0px;
  height: 35px;
}

table.MuiTable-root .MuiButton-outlinedPrimary + .MuiButton-outlinedPrimary {
  margin: 0px 0px 0px 10px;
}

table.MuiTable-root a + .MuiButton-outlinedPrimary {
  margin: 0px 0px 0px 10px;
}

table.MuiTable-root .MuiButton-outlinedPrimary svg {
  margin-right: 0px;
  color: #757575;
}

table.MuiTable-root .MuiButton-outlinedSecondary {
  max-width: 40px;
  min-width: 40px;
  padding: 0px;
  height: 35px;
  border: 1px solid red;
}

table.MuiTable-root a + .MuiButton-outlinedSecondary {
  margin: 0px 0px 0px 10px;
}

table.MuiTable-root .MuiButton-outlinedSecondary svg {
  margin-right: 0px;
  color: red;
}

div.LinearProgress {
  text-align: center;
  font-size: 12px;
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px;
  background-color: white;
  background-color: var(--light);
}

.MuiLinearProgress-colorSecondary {
  background-color: #c9e2e1 !important;
}

.MuiLinearProgress-barColorSecondary {
  background-color: #048c88 !important;
}

.header {
  position: relative;
}

.table,
.table-bordered td {
  color: #000;
}

.table-icon img {
  max-height: 24px;
}

.table-icon {
  margin-left: 10px;
}

.table-icon-full-width {
  width: 120px;
  max-width: 120px;
}

.table-icon-full-width button {
  width: 100%;
  display: block;
  max-width: 120px;
}

.addBankBox {
  padding: 50px;
  text-align: center;
  border-radius: 10px;
}

.addBankBox h1 {
  font-size: 30px;
  padding: 10px 0px;
  margin: 0px;
}

.addBankBox p {
  font-size: 16px;
  padding: 10px 0px;
}

.bankList {
  padding: 50px;
}

.bankList .col-md-6 .innerbox {
  position: relative;
  padding: 20px 20px 10px 20px;
  background: #fff;
  box-shadow: 0px 0px 10px #ccc;
  text-align: center;
  border-radius: 10px;
  text-align: left;
}

.bankList .col-md-6 header {
  padding-left: 240px;
  min-height: 120px;
}

.bankList .col-md-6 .imgPlace {
  position: absolute;
  left: 20px;
  top: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  max-width: 200px;
  width: 100%;
  text-align: center;
}

.bankList .col-md-6 .imgPlace img {
  max-width: 100%;
  max-height: 100px;
}

.bankList h1 {
  font-size: 18px;
  padding: 0px 0px 10px 0px;
  margin: 0px;
  font-weight: bold;
}

.bankList p {
  font-size: 16px;
  padding: 0px 0px 10px 0px;
  margin: 0px;
}

.bankList .col-md-6 footer {
  padding-top: 10px;
  margin-top: 25px;
  border-top: 1px solid #ccc;
  color: #000;
}

.bankList .col-md-6 footer button {
  color: #000;
}

.bankList .col-md-6 footer svg {
  margin-right: 10px;
}

.bankList .col-md-6 .addInnerBox {
  border: 1px dashed #000;
  height: 100%;
  border-radius: 10px;
  text-align: center;
}

.bankList .col-md-6 .addInnerBox button {
  margin: 91px auto;
}

.MuiSelect-filled.MuiSelect-filled {
  text-align: left;
}

/* .tableSearch{ display: none;} */
.MuiTableCell-body .MuiButton-label {
  text-align: left;
  border: #0a4f2b;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  overflow: auto !important;
  background: #00000087 !important;
  opacity: 1 !important;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open::-webkit-scrollbar {
  width: 12px;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: transparent;
  scrollbar-color: var(--scrollbarColor);
  /* For Firefox */
}

.submitbuttonbg button {
  margin-bottom: 30px;
  margin-top: 30px !important;
}

.submitbuttonbg button + button {
  margin-left: 15px;
}

.ReactModal__Overlay .MuiFormControl-root {
  max-width: 100%;
}

.porfolioList {
  margin: 25px 0px;
}

.porfolioList .MuiFilledInput-root {
  background: #fff !important;
  border: 1px solid #ccc !important;
}

.loading_text {
  font-size: 14px !important;
  color: #000 !important;
}

#form-btn {
  margin-right: 0px !important;
}

.MuiFormControl-root.MuiFormControl-marginNormal {
  margin: 0px;
}

.MuiFilledInput-input[placeholder="Search "] {
  background: #fff !important;
  padding: 10px 15px !important;
}

.thanks-message {
  padding: 50px;
  max-width: 600px;
  margin: auto;
  background: #fff;
  box-shadow: 0px 5px 10px #ccc;
  border-radius: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -200px 0px 0px -300px;
}

.thanks-message h2 {
  font-size: 21px;
  margin: 0;
  padding: 0 0 20px;
  color: #bc316f;
}

.thanks-message p {
  padding-bottom: 20px;
}

.backbtn-wrapper .MuiButtonBase-root {
  max-width: 30px;
  min-width: 30px;
}

.portolio-details p .text {
  height: 25px;
  line-height: 25px;
  padding-bottom: 0;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

img.site-logo {
  max-height: 80px;
}

.action_cell {
  width: 100px;
}

.action_cell3 {
  width: 150px;
}

.action_cell4 {
  width: 200px;
}

/* #signinbutton{ margin-left: 20px;} */

/***************WSFS***********/
.trancheInput.col-md-3 {
  flex: 0 0 100%;
  max-width: 100%;
}

.trancheInput .MuiBox-root-2 {
  padding: 0px !important;
}

.trancheInput
  .MuiGrid-root.MuiGrid-container
  .MuiGrid-align-items-xs-center
  .MuiGrid-item.MuiGrid-grid-xs-true {
  max-width: 85%;
}

.trancheInput
  .MuiGrid-root.MuiGrid-container
  .MuiGrid-align-items-xs-center
  .MuiGrid-item.MuiGrid-grid-xs-true
  > .MuiBox-root {
  margin-bottom: 0px;
}

.trancheInput
  .MuiGrid-root.MuiGrid-container
  .MuiGrid-align-items-xs-center
  .MuiGrid-item {
  max-width: 15%;
}

.trancheInput
  .MuiGrid-root.MuiGrid-container
  .MuiGrid-align-items-xs-center
  .MuiGrid-item.MuiGrid-grid-xs-true
  > .MuiBox-root
  > .MuiPaper-rounded
  > .MuiBox-root {
  padding: 0px;
}

.MuiTypography-h5 {
  font-size: 18px !important;
  text-align: left;
  font-weight: bold !important;
  padding: 10px 0px;
}

.MuiTypography-h5 + .MuiDivider-root {
  margin-bottom: 15px;
}

.Adjustments.col-md-3.col-sm-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.additionalDetails.col-md-3.col-sm-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.additionalDetailsTemplate.col-md-3.col-sm-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.Fees.col-md-3.col-sm-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.twocolform .col-md-3 {
  flex: 0 0 50%;
  max-width: 50%;
}

.twocolform .col-md-3 .MuiFormControl-root {
  width: 100%;
  max-width: 100%;
}

.threecolform .col-md-3 {
  flex: 0 0 33.33%;
  max-width: 33.33%;
}

.threecolform .col-md-3 .MuiFormControl-root {
  width: 100%;
  max-width: 100%;
}

.MuiTypography-subtitle2 {
  font-weight: normal !important;
  font-size: 12px !important;
  text-align: left;
}

/* .action_col{ width: 350px!important;} */
.MuiButton-root.backbtn {
  min-width: 30px;
  font-size: 20px;
}

.pageHeading {
  line-height: 35px;
}

.pageHeading1 {
  position: relative;
}

.onecolform .rjsf {
  padding: 35px 35px;
  margin: 0px;
}

.onecolform .col-md-3 {
  flex: 0 0 100%;
  max-width: 100%;
}

.onecolform .col-md-3 .MuiFormControl-root {
  width: 100%;
  max-width: 100%;
}

.width50 {
  max-width: 500px;
}

.table100 {
  width: 100%;
}

.w50 {
  padding-right: 30px;
}

.w50 + .w50 {
  padding-right: 0px;
  width: 50%;
}

#ClassFactors,
#PrePaymentsAndDefaultRates,
#PrePaymentsAndDefaultRates2 {
  overflow: auto;
}

.first_item {
  font-weight: bold;
  background: #048c84;
  color: #fff;
}

#myselectversion option {
  padding: 10px;
}

html {
  scroll-behavior: smooth;
}

.sticky {
  position: fixed;
  top: 0px;
  width: 100%;
  background: red;
}

.nav {
  position: -webkit-sticky;
  top: 75px !important;
  z-index: 1000000 !important;
}

.sticky {
  position: fixed;
  width: 100%;
  left: 0;
  top: 100px;
  z-index: 100;
  border-top: 0;
  top: 100px;
}

/* #tablewrapp  *{border: 1px solid red;} */
.wrapper-pdf-container *,
.wrapper-pdf-container::after,
.wrapper-pdf-container::before {
  box-sizing: border-box;
}

.wrapper-pdf-container label {
  margin: 0px;
}

.wrapper-pdf-container {
  font-family: "Segoe UI";
  font-size: 17px;
  margin: 0px 0px;
}

.wrapper-pdf-container * {
  font-family: "Segoe UI";
}

.wrapper-pdf-container .page-first * {
  color: black;
}

.wrapper-pdf-container .page-first h1.pagetitle {
  color: red;
}

.wrapper-pdf-container h1.pagetitle {
  font-size: 35px;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 50px;
  color: red !important;
}

.wrapper-pdf-container h1.pagetitle::before {
  border-bottom: 2px solid #048c88;
  width: 100px;
  content: "";
  bottom: 0px;
  left: 0px;
  height: 2px;
  position: absolute;
}

.wrapper-pdf-container .page-first label {
  color: #000;
  font-size: 12px;
  display: block;
  padding: 10px 0px;
}

.wrapper-pdf-container .page-first table {
  width: 100%;
  color: #fff;
}

.wrapper-pdf-container .page-first .investor_title {
  background: #048c88;
  color: #fff;
  font-size: 23px;
  padding: 10px 15px;
  display: inline-block;
  margin: 100px 0px;
}

.wrapper-pdf-container .page-first .divider {
  height: 25px;
}

.wrapper-pdf-container .page-first .footertext {
  font-size: 9px;
  color: black;
  font-weight: 100;
  text-align: center;
  line-height: 18px;
}

.footertext {
  position: relative;
  width: 100%;
  padding-bottom: 2rem;
}

.index {
  background: #fff;
  padding-bottom: 50px;
}

.index ul {
  counter-reset: my-awesome-counter;
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.index ul li {
  display: inline-block;
  padding: 30px 15px 0px 15px;
  width: 32%;
  color: #0f0f0f;
  font-size: 22px;
  counter-increment: my-awesome-counter;
  position: relative;
}

.index ul li::before {
  content: counter(my-awesome-counter);
  color: #000;
  font-weight: normal;
  position: absolute;
  left: 30px;
  top: 60px;
}

.index ul li::after {
  border-bottom: 4px solid #048c88;
  width: 75px;
  content: "";
  top: 50px;
  left: 30px;
  height: 2px;
  position: absolute;
}

.index ul li a {
  color: #000;
  text-decoration: none;
}

/* h5.sectiontitle{ color: #048c88; font-weight: bold; position: relative; font-size: 25px; padding-bottom: 10px; margin-bottom: 30px;text-align: left} */
h5.sectiontitle {
  color: #048c88;
  font-weight: bold;
  position: absolute;
  font-size: 12px;
  padding-bottom: 10px;
  margin-bottom: 30px;
  text-align: right;
  right: 89px;
  margin-top: -59px;
}

h5.sectiontitle_collateral {
  color: #048c88;
  font-weight: bold;
  position: absolute;
  font-size: 12px;
  padding-bottom: 10px;
  margin-bottom: 30px;
  text-align: right;
  right: 89px;
  margin-top: -59px;
}

h4.sectiontitle::before {
  border-bottom: 2px solid #048c88;
  width: 100px;
  content: "";
  bottom: 0px;
  left: 0px;
  height: 2px;
  position: absolute;
}

.sectiontitle {
  color: var(--customColor);
}

h4.sectiontitle-active::before {
  border-bottom: 2px solid var(--customColor);
  width: 100px;
  content: "";
  bottom: 0px;
  left: 0px;
  height: 2px;
  position: absolute;
}

/* p.nextpage{ color: #048c88; font-weight: bold; position: relative; font-size: 16px; padding-bottom: 10px; margin-bottom: 30px;} */
/* p.nextpage::before{ border-bottom: 2px solid #048c88; width: 100px; content: ""; bottom: 0px; left: 0px; height: 2px; position: absolute;} */

.summery {
  padding: 25px;
  background: #fff;
  box-shadow: 0px 0px 10px #ccc;
  margin-bottom: 50px;
}

.summery .table {
  border: none !important;
}

.summery .table td {
  border-top: 0px;
  border-right: 1px solid #ccc;
  color: #404040;
}

.summery .table td:last-child {
  border: none;
}

.summery .table td strong {
  font-weight: bold;
  display: block;
  padding-top: 10px;
}

.total-payment-summmery {
  background: #048c88 url(https://in-d.ai/wp-content/uploads/2020/10/pdf-2.png)
    99% 100% no-repeat;
  padding-top: 50px;
}

h4.subsectiontitle {
  color: #fff;
  font-weight: 300;
  position: relative;
  font-size: 28px;
  padding-bottom: 10px;
  margin-bottom: 0px;
}

/* p.nextpage{ color: #fff; font-weight: 300; position: relative; font-size: 28px; padding-bottom: 10px; margin-bottom: 0px;} */

.total-payment-summmery ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.total-payment-summmery ul li {
  display: inline-block;
  padding: 30px 30px 0px 0px;
  width: 33%;
  color: #0f0f0f;
  font-size: 28px;
  position: relative;
}

.total-payment-summmery ul li p {
  font-size: 16px;
  font-weight: normal;
}

.total-payment-summmery ul li .inner {
  background: #fff;
  box-shadow: 0px 0px 15px #048c88;
  padding: 25px 15px 25px 15px;
  font-weight: 500;
}

.wrapper-pdf-container table.table.table-bordered {
  box-shadow: 0px 0px 15px #b2b2b2;
  color: #000;
}

.wrapper-pdf-container table.table.table-bordered thead,
.wrapper-pdf-container table.table.table-bordered .thead-light th {
  background-color: #048c88 !important;
  color: #fff !important;
}

.wrapper-pdf-container table.table.table-bordered strong {
  font-weight: 500;
}

.wrapper-pdf-container .marginTop {
  margin-top: 25px;
}

.wrapper-pdf-container table.table.table-bordered .total_tr td {
  background: #d4fce7;
  font-weight: bold;
}

.wrapper-pdf-container .dealcontactinfo a {
  color: black;
}

.wrapper-pdf-container .table td,
.wrapper-pdf-container .table th {
  padding: 0.75rem;
  vertical-align: top;
}

.wrapper-pdf-container .table-bordered td,
.wrapper-pdf-container .table-bordered th {
  border: 1px solid #dee2e6;
}

.wrapper-pdf-container .table-bordered {
  border: 1px solid #dee2e6;
}

.wrapper-pdf-container .table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.wrapper-pdf-container table {
  border-collapse: collapse;
  text-align: left;
  width: 100%;
  vertical-align: top;
}

.wrapper-pdf-container table td,
.wrapper-pdf-container table th {
  text-align: left;
  vertical-align: top;
}

.wrapper-pdf-container .w50 {
  padding-right: 30px;
  width: 50%;
}

.beforeClass {
  page-break-after: always;
}

#pageHeader,
#pageHeader-first {
  height: 65px;
  background: #fff;
  padding: 15px;
}

#pageHeader-first {
  margin: 0px -15px;
  padding: 25px;
}

#pageHeader {
  width: 100%;
  height: 85px;
}

#pageHeader .page-header-right {
  margin-top: 10px;
}

.page-header-right {
  float: right;
}

.page-header-right .logo {
  float: left;
  padding-left: 25px;
}

.page-header-right .home {
  float: left;
  padding-left: 25px;
}

.page-header-right .menu {
  float: left;
  padding-left: 25px;
  padding-top: 5px;
}

.page-header-right .up {
  float: left;
  padding-left: 25px;
}

.page-header-right .down {
  float: left;
  padding-left: 25px;
}

.page-header-left {
  float: left;
}

.page-header-left .header-distribution-date {
  float: left;
  padding: 0px 25px;
  color: black;
  border-left: 2px solid #048c88;
  border-right: 2px solid #048c88;
  margin-left: 25px;
}

.page-header-left .header-report-type {
  float: left;
  padding: 0px 25px;
  color: black;
}

.page-header-left label {
  color: #000;
  display: block;
  margin: 0px;
  padding: 0px 0px 10px 0px;
  font-size: 14px;
}

#wsfs_logo {
  background-repeat: no-repeat;
  background-size: contain;
  padding-bottom: 0px;
  background-position: right;
  width: 100%;
  height: 23px;
}

#wsfs_logo_first {
  background-repeat: no-repeat;
  background-size: contain;
  padding-bottom: 0px;
  background-position: right;
  width: auto;
  max-height: 37px;
}

#City {
  padding-top: 0px;
  margin-top: -3px;
}

/* new  html template added */
#value0 {
  color: #048c88 !important;
  font-weight: bolder !important;
  display: block;
  width: 900px;
  line-height: 34px;
  font-size: 22px;
}

.wrapper-pdf-container .page-first {
  background-image: url(https://in-d.ai/wp-content/uploads/2020/11/ReportImage.jpg);
  background-repeat: no-repeat;
  /* background-size: contain; */
  /* padding-bottom: 0px; */
  background-position: right;
  background-position-y: 70px !important;
  background-size: 550px 500px !important;
}

.wrapper-pdf-container .page-first .usernote,
.wrapper-pdf-container .page-first .footernote {
  margin: 56px 50px 0px 0px;
  background: #fff;
}

.wrapper-pdf-container .page-first h3 {
  font-weight: 100;
  font-size: 16px;
  color: #0a4f2b;
  margin-top: -5px;
}

.page-header-left .header-title {
  float: left;
  color: #048c88;
  font-size: 18px;
  padding: 15px 0px;
  font-weight: bolder !important;
  margin-left: 20px !important;
}

.index ul li .inner {
  background: #fff;
  box-shadow: 0px 5px 5px #ccc;
  padding: 70px 15px 25px 15px;
  font-weight: 500;
  font-size: 13px !important;
}

h4.sectiontitle {
  color: #048c88;
  font-weight: bold;
  position: relative;
  font-size: 17px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  margin-top: 20px !important;
  text-align: left;
  text-transform: capitalize;
}

.index ul li .inner {
  text-transform: capitalize;
}

.wrapper-pdf {
  margin: auto;
  /* max-width: 1440px; */
  /* padding: 3px 30px 0px 30px; */
  width: 100%;
  font-size: 15px;
  /* background-color: #007bff; */
  scroll-margin-top: 70px;
}

.sectiontitle {
  margin-top: 30px !important;
}

.index {
  background: #fff;
  padding-bottom: 50px;
  margin-left: -9px !important;
}

.wrapper-pdf-container .w50 {
  width: 50%;
}

.wrapper-pdf-container .table td,
.wrapper-pdf-container .table th {
  padding: 0.75rem;
  vertical-align: top;
  font-size: 13px !important;
  color: #212121;
  color: var(--tableText);
}

/* #payment-summary{padding-top: 50px;} */
.btn-primary:disabled {
  color: #fff;
  background-color: #5d5d5d !important;
  border-color: #5d5d5d !important;
}

.btn-primary:disabled:hover {
  color: #fff;
  background-color: #5d5d5d !important;
  border-color: #5d5d5d !important;
}

#loader10 {
  margin-top: 50px;
}

.rjsf .MuiInputBase-root {
  width: 100%;
  text-align: left;
}

.MuiInputBase-inputMarginDense {
  color: #212121 !important;
  color: var(--tableText) !important;
  background: #fff !important;
  background: var(--white) !important;
  width: 250px !important;
  margin-top: 10px;
}

.action_col a {
  display: inline-block;
  margin-right: 15px;
}

.custom-input .MuiInputBase-root {
  width: 100%;
  text-align: left;
}

.MuiInputBase-root.Mui-disabled {
  color: #212121 !important;
}

.MuiOutlinedInput-notchedOutline {
  border: 1px solid #212121 !important;
  border-radius: 8px !important;
}

.logo_img {
  padding: 0px;
  margin-left: 20px;
  margin-right: 20px;
  float: left;
  border: none !important;
}

.logo_img_admin {
  padding: 0px;
  margin-left: -2rem;
  margin-right: 10px;
  float: left;
  border: none !important;
}

.logo_img_admin button {
  padding: 0px;
  margin: 0px;
  border: none !important;
}

.logo_img_admin img {
  max-height: 50px;
}
.logo_img button {
  padding: 0px;
  margin: 0px;
  border: none !important;
}

.logo_img img {
  max-height: 50px;
}

span.pageTitle {
  line-height: 57px;
  position: relative;
  top: 1rem;
}

.page-content-custimization {
  padding: 0px;
  margin: 0px 20px;
  border-radius: 10px;
}

.page-content-custimization-right {
  background: #fff;
  background: var(--white);
  padding: 15px;
  box-shadow: 0px 3px 3px #ccc;
  width: 25%;
  border-radius: 0px 0px 10px 10px;
  float: right;
}

.page-content-custimization-right .col-sm-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.page-content-custimization-left {
  background: #fff;
  background: var(--white);
  padding: 15px;
  box-shadow: 0px 3px 3px #ccc;
  border-radius: 10px;
  float: left;
  width: 70%;
  margin: 30px 2.5%;
}

.customization_table {
  position: relative;
  width: 100%;
  min-height: 600px;
  text-align: left;
}

.customization_table .static_table {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 300px;
  display: block !important;
}

.customization_table_pagination .static_table {
  display: none !important;
}

.customization_table .dynamic_table {
  width: calc(100% - 300px);
  height: 515px;
  overflow: auto;
}

/* .customization_table .dynamic_table.defaulttext {
  padding: 300px 0px 180px 0px;
  background: #fff url(./images/dragdrop_default.png) center 160px no-repeat;
  text-align: center;
} */

.customization_table .dynamic_table p {
  text-align: left;
}

/* .customization_table .dynamic_table{display: none;} */
.customization_table_pagination {
  text-align: left;
  text-align: left;
  margin: -15px -15px 0px -15px;
  background: #f2fefe;
  padding: 15px 15px 7px 15px;
  position: relative;
  z-index: 100;
}

.customization_table_pagination button {
  margin: 10px;
}

.customization_table .dynamic_table {
  box-shadow: 0px 0px 10px #ccc;
  padding: 25px;
  background: #fff;
}

/* .customization_table_pagination button:first-child{ margin-left: 0px;} */
.customization_table .static_table {
  background: #f2fefe;
  padding: 25px;
  margin-right: -30px;
  height: 590px;
  overflow: auto;
}

.customization_table div[role="button"] {
  background: #f2fefe !important;
  color: #000 !important;
  border: 1px solid #000;
  width: 250px;
}

.customization_table {
  background: #e2eaea;
  padding: 30px 20px;
  margin: 0px -14px -14px -14px;
}

.customization_table_pagination .MuiButton-outlinedPrimary {
  color: #000;
}

.customization_table_pagination .MuiButton-outlinedPrimary.active {
  background: #007bff;
  color: #fff;
}

.customize_top {
  padding: 10px 20px;
  border-top: 1px solid #ccc;
  margin: 0px -15px;
  text-align: center;
}

.customize_top h2 {
  font-size: 20px;
  padding: 10px;
  margin: 0px;
}

.text_default {
  -webkit-user-select: none;
          user-select: none;
  padding: 16px;
  margin: 0px 0px 8px;
  min-height: 50px;
  background-color: rgb(69, 108, 134);
  color: white;
}

.customize_top .float-right .MuiFormControlLabel-root {
  margin: 0px;
}

.customize_top .float-right button {
  margin-left: 15px;
}

a button img {
  fill: #007bff;
}

#viewReport.customization_table {
  position: relative;
  width: 100%;
  min-height: 600px;
  background: #fff;
  padding: 0px;
  text-align: left;
  margin: 70px 0px 0px 0px;
}

#viewReport.customization_table .static_table {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 300px;
  display: none !important;
}

#viewReport.customization_table .dynamic_table {
  width: calc(100% - 0px);
  height: 515px;
  overflow: auto;
  box-shadow: none !important;
}

#ViewDragNDrop {
  position: relative;
}

#ViewDragNDrop .customization_table_pagination {
  background: #048c88;
  margin-bottom: -15px;
  border-radius: 0px;
}

#ViewDragNDrop .customization_table_pagination button {
  border: 1px solid #ffffff;
  background-color: rgb(255 255 255);
  color: #054e4a;
}

#ViewDragNDrop .customization_table_pagination button.active {
  background: #144e4a;
  color: #fff;
}

#ViewDragNDrop .view-report-header {
  position: absolute;
  right: 15px;
  top: 150px;
  z-index: 1000000;
}

#validate_monthly_input .MuiFilledInput-input {
  padding: 15px;
  background: #fff;
  border: 1px solid #000;
}

.table-bordered#validate_monthly_input td,
.table-bordered#validate_monthly_input th {
  border: 1px solid #dee2e6;
  vertical-align: middle;
}

.customize_top .alert.alert-danger {
  font-size: 12px;
  padding: 5px 10px;
  margin-top: 5px;
  margin-bottom: 0px;
}

.servicer-heading {
  padding-bottom: 15px;
  margin-bottom: 0px;
  text-align: left;
  font-size: 20px;
  font-weight: bold;
}

.custom-input .MuiTableFooter-root .MuiInputBase-root {
  width: auto !important;
}

#version_loader table tr td.MuiTableCell-root.MuiTableCell-body:last-child {
  width: 250px !important;
}

#version_loader table tr td .MuiCircularProgress-colorPrimary {
  margin-left: 10px !important;
}

/* #version_loader table.MuiTable-root .MuiButton-outlinedPrimary{ max-width: 100%; min-width: auto; padding: 0px 25px; height: 35px;} */
/* MuiCircularProgress-root MuiCircularProgress-colorPrimary MuiCircularProgress-indeterminate */
button.MuiButton-containedPrimary .MuiCircularProgress-colorPrimary {
  color: #fff !important;
  margin-left: 10px;
}

/* #pdfdata{ visibility: hidden; width: 0px; height: 0px;} */
.view-report-header {
  float: right;
}

.view-report-header button {
  margin-left: 20px;
}

.customization_table #PerformanceDetails tr td,
.customization_table #PerformanceDetails1 tr td,
.customization_table #PerformanceDetails2 tr td,
.customization_table #PerformanceDetails3 tr td,
.customization_table #PerformanceDetails4 tr td,
.customization_table #PerformanceDetails5 tr td {
  width: 20%;
}

table.dealcontactinfo {
  max-width: 600px;
}

table.dealinfo {
  width: 400px !important;
}

table.dealinfo td:first-child {
  width: 200px !important;
}

.view_report_heading {
  line-height: 35px;
  margin: 0px;
}

.navigation_top {
  padding: 10px 0px 0px 0px;
  float: left;
  width: 100%;
}

.navigation_top li {
  display: block;
  margin-right: 20px;
}

.addBankBox {
  padding: 50px;
  margin: 70px 50px;
  background: #fff;
  box-shadow: 0px 0px 10px #ccc;
  text-align: center;
  border-radius: 10px;
}

.addBankBox h1 {
  font-size: 30px;
  padding: 10px 0px;
  margin: 0px;
}

.addBankBox p {
  font-size: 16px;
  padding: 10px 0px;
}

.bankList {
  padding: 50px;
}

.bankList .col-md-6 .innerbox {
  position: relative;
  padding: 20px 20px 10px 20px;
  background: #fff;
  box-shadow: 0px 0px 10px #ccc;
  text-align: center;
  border-radius: 10px;
  text-align: left;
}

.bankList .col-md-6 header {
  padding-left: 240px;
  min-height: 120px;
}

.bankList .col-md-6 .imgPlace {
  position: absolute;
  left: 20px;
  top: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  max-width: 200px;
  width: 100%;
  text-align: center;
}

.bankList .col-md-6 .imgPlace img {
  max-width: 100%;
  max-height: 100px;
}

.bankList h1 {
  font-size: 18px;
  padding: 0px 0px 10px 0px;
  margin: 0px;
  font-weight: bold;
}

.bankList p {
  font-size: 16px;
  padding: 0px 0px 10px 0px;
  margin: 0px;
}

.bankList .col-md-6 footer {
  padding-top: 10px;
  margin-top: 25px;
  border-top: 1px solid #ccc;
  color: #000;
}

.bankList .col-md-6 footer button {
  color: #000;
}

.bankList .col-md-6 footer svg {
  margin-right: 10px;
}

.bankList .col-md-6 .addInnerBox {
  border: 1px dashed #000;
  height: 100%;
  border-radius: 10px;
  text-align: center;
}

.bankList .col-md-6 .addInnerBox button {
  margin: 91px auto;
}

.viewmorecolumn {
  /* border: 1px solid #212121;
  border-radius: 8px; */
  float: left;
  height: 600px !important;
}

.viewmorecolumnpdf {
  float: left;
  height: 560px;
  /* overflow-y: auto; */
}

.viewmoreOnecolumn {
  width: 100%;
  float: none;
  height: 450px;
  overflow-y: auto;
}

.viewmoreOnecolumn #viewMore input.form-control {
  width: 350px !important;
}

#imageshow {
  font-size: 20px;
  text-align: center;
  padding: 20px;
  margin-top: 200px;
}

#Matchunmatch iframe {
  width: 100% !important;
  height: 480px !important;
  overflow-y: scroll;
  border: none;
}

#uploadForm {
  padding-top: 100px;
}

#browseexcel {
  margin: 25px 0px;
}

.customForm {
  padding: 40px 10px;
}

.matchdatafield {
  padding: 0px 1px 3px 28px !important;
}

.matchdatafield1 {
  margin-right: 30px;
  padding: 0px 1px 3px 28px !important;
}

.tbodydata {
  margin-bottom: 53px;
}

/* .MuiGridListTile-tile{ overflow: visible;} */
.adminSearch {
  margin: -40px;
  margin-bottom: 40px;
}

.MuiFilledInput-root {
  /* background: #fff !important; */
  /* border: 1px solid #212121 !important; */
  border: 1px solid rgba(33, 33, 33, 1) !important;
  border: 1px solid var(--tableBorder) !important;
  color: #212121 !important;
  color: var(--text_Color) !important;
  background: #fff !important;
  background: var(--white) !important;
  border-radius: 10px !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  width: 450px !important;
}

.MuiFilledInput-inputAdornedEnd {
  padding: 10px 12px 10px !important;
}

/* .MuiFilledInput-underline.Mui-error:after {
  transform: scaleX(1);
  border-bottom-color: #fff !important;
} */
.MuiButtonBase-root.MuiIconButton-root {
  color: #018e82 !important;
  color: var(--button) !important;
}

.MuiButtonBase-root.MuiIconButton-root.MuiPickersDay-day {
  color: #212121 !important;
}

/* .MuiFormHelperText-root.Mui-error {
  color: #f8f9fa !important;
} */
.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary {
  border: none !important;
  color: #018e82 !important;
}

.MuiFilledInput-underline:after {
  border-bottom: 1px rgb(204, 204, 204);
  border-bottom: 1px var(--formBorder);
}

.MuiFilledInput-underline:before {
  border-bottom: 1px rgb(204, 204, 204);
  border-bottom: 1px var(--formBorder);
}

.input-inlineContainer {
  margin-top: 13px;
}

table.MuiTable-root th {
  background: #dbf1f1;
  color: #212121 !important;
  color: var(--tableText) !important;
  font-weight: normal !important;
}

button + .MuiCircularProgress-root {
  margin-left: 20px;
}

.closeiconstyle {
  margin-top: 10px;
  margin-left: 10px;
}

#comparesave {
  text-align: center;
  margin: 15px;
}

.data_table {
  /* border: 1px solid #212121;
  border-radius: 8px; */
}

.data_table thead {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.th_data_table {
  color: #212121;
  font-family: "Catamaran", sans-serif;
  font-weight: 600;
  font-size: 20px;
  width: 310px !important;
}

#viewMore.data_table thead th {
  padding: 23px 0px 15px 28px;
}

.poolTableWrapper .MuiTableCell-root {
  height: auto !important;
  padding: 10px 16px !important;
}

.poolTableWrapper .MuiTableCell-root p {
  margin: 0px;
  padding: 0px;
}

.form-loader {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1000;
  text-align: center;
}

.form-loader .text-center {
  position: absolute;
  top: 50%;
  margin-top: -6px;
}

.relative {
  position: relative;
}

hr {
  margin-top: 0px;
  margin-bottom: 0px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

#poolinfovalue {
  color: #1c1d1d;
  font-size: 16px;
  font-weight: bold;
  font-weight: 600;
  margin-bottom: 8px;
}

#poolinfohead {
  color: #545e5d;
  font-size: 14px;
  margin-bottom: 3px;
}

.labelform {
  height: 590px;
  overflow-y: auto !important;
  /* overflow-x: hidden; */
  background: #f5f5f5;
  margin-left: 38px;

  border: 1px solid #212121;
  /* border-radius: 20px; */
}

.header_right button {
  margin-right: 20px;
}

.stepBar {
  position: relative;
}

.back-to-link {
  margin: 0px 15px 0px 0px !important;
  font-size: 14px;
  color: #000;
  border: 1px solid #ccc;
  padding: 5px 10px;
  text-decoration: none;
}

.tableSearch #assignpoolbutton button {
  height: 50px;
  padding: 20px 30px;
}

.MuiPopover-paper {
  padding: 10px;
  box-shadow: 0px 5px 5px #ccc !important;
}

.MuiPopover-paper ul li {
  color: #000;
}

.MuiPopover-paper ul li a {
  color: #000;
}

.MuiPopover-paper ul li a:hover {
  text-decoration: none;
}

.iframeOuter {
  border: none !important;
}

.bdb_title {
  font-size: 18px;
  margin-bottom: 20px;
  padding: 10px;
  border-bottom: 1px rgb(204, 204, 204);
  border-bottom: 1px var(--formBorder);
}

.create_deal {
  padding: 10px 10px;
}

.popup_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
  top: 0px;
  left: 0px;
  background: #00000050;
}

.MuiDrawer-paper {
  padding: 20px;
  width: 400px;
}

.MuiDrawer-paper h1 {
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 20px;
}

.close_icon {
  position: absolute !important;
  right: 10px;
  top: 7px;
  max-width: 30px;
  min-width: 40px !important;
  width: 30px;
}

.MuiList-root.MuiMenu-list li {
  text-transform: capitalize;
}

.preprocessing_menu li {
  position: relative;
  display: block;
}

.preprocessing_menu ul {
  position: absolute;
  right: 20px;
  top: 35px;
  display: none;
  background: #fff;
  box-shadow: 0px 5px 5px #ccc;
  padding: 0px 0px 0px 0px;
  z-index: 100;
}

.preprocessing_menu li:hover ul {
  display: block;
}

.preprocessing_menu ul li {
  border-bottom: 1px rgb(204, 204, 204);
  border-bottom: 1px var(--formBorder);
}

.preprocessing_menu ul li:last-child {
  border-bottom: 1px rgb(204, 204, 204);
  border-bottom: 1px var(--formBorder);
}

.preprocessing_menu li a {
  padding: 8px;
  display: block;
  text-decoration: none;
  color: #000;
}

.preprocessing_menu li a:hover {
  text-decoration: none;
}

.preprocessing_menu .MuiButton-label {
  text-transform: capitalize !important;
}

.MuiFormLabel-root {
  color: #000333 !important;
}

.poolFormationTableWrapper .MuiTableCell-root {
  height: auto !important;
  padding: 0px !important;
}

.poolFormationTableWrapper .MuiTableCell-root p {
  padding: 5px;
  margin: 0px;
  border-bottom: 1px rgb(204, 204, 204);
  border-bottom: 1px var(--formBorder);
  height: 30px;
  line-height: 24px;
}

.poolFormationTableWrapper .MuiTableCell-root p:last-child {
  border: none;
}

.poolFormationTableWrapper .mismatchbox {
  text-align: center !important;
}

.matchscroll {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: space-between;
}

.matchscroll label {
  color: #212121;
  font-size: 15px;
  font-weight: 400;
  font-family: "Mulish", sans-serif;
  display: block;
  padding: 0px;
  margin-top: 10px;
}

.addBankBox h2 {
  font-size: 21px;
}

.onecolform2 .rjsf {
  padding: 15px 0px;
  margin: 0px;
}

.onecolform2 .col-md-3 {
  flex: 0 0 100%;
  max-width: 100%;
}

.onecolform2 .col-md-3 .MuiFormControl-root {
  width: 100%;
  max-width: 100%;
}

.onecolform2 .field-object.customWrapper {
  float: left;
  width: 300px;
  margin-right: 25px;
}

.onecolform2 .MuiButton-containedPrimary {
  margin-top: 10px;
}

/* .page-content.with_loader{ background: url(./images/mismatch.png) center center no-repeat;} */
.createdByName {
  text-transform: capitalize;
}

.version_btn {
  font-size: 18px;
  cursor: pointer;
  float: right;
}

#browse1 {
  padding: 25px;
}

.MuiButton-outlinedSecondary .button_popup {
  display: none;
  position: absolute;
  left: 0px;
  top: 36px;
  width: 200px;
  padding: 0;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #ccc;
}

.MuiButton-outlinedSecondary:hover .button_popup {
  display: block;
}

.MuiButton-outlinedSecondary:hover .button_popup ul {
  padding: 10px 0px;
}

.MuiButton-outlinedSecondary:hover .button_popup li {
  text-transform: capitalize !important;
  color: #054e4a;
  display: block;
  padding: 10px 15px;
  text-align: left !important;
  border-bottom: 1px rgb(204, 204, 204);
  border-bottom: 1px var(--formBorder);
}

p#headingparah {
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
}

.poolslicingparameter {
  padding: 0px 0px 20px 0px;
  font-size: 14px;
}

.navigate_view {
  text-align: right;
  padding-bottom: 25px;
}

.navigate_view button {
  margin-left: 15px;
}

.navigate_view button svg {
  margin-left: 10px;
}

.preprocessing_menu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.preprocessing_menu > li {
  float: left;
}

.preprocessing_menu li.userInfo a .MuiButton-label .text {
  display: block;
  margin: auto;
  text-align: center;
  background: #048c88;
  color: #fff;
  font-weight: bold;
  height: 35px;
  width: 35px;
  text-align: center;
  line-height: 35px;
  border-radius: 50%;
}

.preprocessing_menu li.userInfo a {
  padding: 0px;
}

.preprocessing_menu li.userInfo a button {
  padding: 0px;
  margin: 0px;
  min-width: 30px;
}

p.orgname span {
  text-transform: capitalize;
  font-weight: bold;
}

p.orgname {
  float: left;
  font-size: 14px;
  line-height: 36px;
  display: block;
  margin-top: 25px;
  /* margin-top: 30px;
  margin-right: 10px; */
}

.notificationcolor {
  color: #018e82;
}

span.total_deals {
  background: #048c88;
  color: #fff;
  font-size: 12px;
  padding: 3px 8px;
  margin-left: 10px;
  display: inline-block;
  border-radius: 5px;
}

.navigation_top li button.MuiButton-contained {
  background: none !important;
  color: rgb(0, 0, 0) !important;
  box-shadow: none !important;
  border: none !important;
  border-bottom: 2px rgb(204, 204, 204);
  border-bottom: 2px var(--formBorder);
}

.navigation_top li button.MuiButton-outlined {
  background: none !important;
  color: rgb(0, 0, 0) !important;
  box-shadow: none !important;
  border: none !important;
  border-bottom: 2px solid #e2ebea !important;
}

.page-content#formElements {
  background: none !important;
  padding: 0px;
  box-shadow: none;
  margin-bottom: 0px !important;
}

.page-content#formElements2 {
  margin-top: 0px;
}

.deal_form .rjsf {
  position: relative;
}

.deal_form .rjsf {
  padding: 0px 15px 0px 15px;
}

.deal_form .rjsf .form-group.field-object.customWrapper {
  float: left;
  width: 66.66%;
  margin-right: 20px;
}

.deal_form .rjsf .form-group.customWrapper .col-md-3 {
  flex: 0 0 50%;
  max-width: 50%;
  margin: 0px !important;
}

.deal_form .rjsf .deal_form_button {
  margin-top: 10px;
}

.deal_form_loan_strat_report .rjsf {
  position: relative;
}

.deal_form_loan_strat_report .rjsf {
  padding: 0px 15px 0px 15px;
}

.deal_form_loan_strat_report .rjsf .form-group.field-object.customWrapper {
  float: left;
  width: calc(100% - 250px);
  margin-right: 20px;
}

.deal_form_loan_strat_report .rjsf .form-group.customWrapper .col-md-3 {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  margin: 0px !important;
}

.deal_form_loan_strat_report .rjsf .deal_form_button {
  margin-top: 10px;
}

.MuiButton-outlinedSecondary .button_popup ul {
  margin: 0px;
  padding: 0px;
}

.MuiButton-outlinedSecondary .button_popup ul li:last-child {
  border: none;
}

#sub_heading .pageHeading span.pageTitle span.dealId {
  display: inline-block;
  padding-left: 10px;
  font-weight: normal;
  font-size: 16px;
  position: relative;
  padding-left: 30px;
}

#sub_heading .pageHeading span.pageTitle span.dealId::before {
  content: ">";
  position: absolute;
  left: 11px;
}

.dealinfo {
  border-bottom: 1px rgb(204, 204, 204);
  border-bottom: 1px var(--formBorder);
  padding-bottom: 10px;
  background-color: #007bff;
  width: 1rem;
  height: 2rem;
  margin-bottom: 15px;
}

.dealinfo label {
  font-size: 13px;
  display: block;
  margin: 0px;
  padding: 0px;
  color: rgb(0, 0, 0) !important;
  font-weight: normal;
}

.dealinfo {
  font-size: 14px;
  padding-bottom: 10px;
  margin-bottom: 15px;
  color: rgb(0, 0, 0) !important;
  font-weight: bold;
}

.MuiToolbar-gutters .MuiFilledInput-input {
  padding: 10px !important;
}

.MuiTableCell-body {
  color: "var(--text_Color)";
  background: #fff;
  background: var(--white);
}

.small_text {
  font-size: 12px;
  display: block;
}

#viewServicerData {
  margin-top: 20px;
}

/* dasboard */
.horizontal-divider {
  margin-top: 25px;
  margin-bottom: 25px;
  background-color: #d3d3d3;
  height: 1px;
  width: 100%;
}

.headingspace {
  font-family: "Catamaran", sans-serif;
  margin-bottom: 15px;
  margin-top: 23px;
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #212121;
  color: var(--headerText);
}

.headingspaces1 {
  font-family: "Catamaran", sans-serif;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
}

.headingspace1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #212121;
  color: var(--tableText);
  margin-bottom: 15px;
  margin-top: -11px !important;
}

.headingspace11 {
  margin-bottom: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #212121;
}

.headingspace111 {
  margin-bottom: 80px;
  margin-top: 12px;
}

.sizeofp {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #212121;
  color: var(--headerText);
}

.sizeofp1 {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}

.page-content1 {
  background: #fff;
  background: var(--white);
  padding-top: 6px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 20px;
  margin-top: 10px;
  margin-bottom: 70px;
  border-radius: 10px;
  border: 1px solid rgba(33, 33, 33, 1);
  border: 1px solid var(--tableBorder);
}

.page-content3 {
  background: #fff;
  background: var(--white);
  padding-top: 6px;
  padding-bottom: 10px;
  padding-left: 28px;
  padding-right: 20px;
  margin-top: 25px;
  margin-bottom: 17px;
  border: 1px solid #212121;
  border-radius: 10px;
}

.page-content4 {
  background: #fff;
  background: var(--white);
  padding-top: 6px;
  padding-bottom: 40px;
  padding-left: 28px;
  padding-right: 20px;
  margin-top: 25px;
  margin-bottom: 17px;
  border: 1px solid #212121;
  border-radius: 10px;
}

/* .documents-section{
  height: 360px;
  overflow-y: auto;
  overflow-x: hidden;
} */
.documents-section {
  height: 330px;
  overflow-y: auto;
  overflow-x: hidden;
}

.page-content2 {
  background: #fff;
  background: var(--white);
  padding: 40px;
  margin-top: 50px;
  margin-bottom: 70px;
  border-radius: 10px;
  border: 1px solid #212121;
}

.page-content11 {
  background: #fff;
  background: var(--white);
  padding-left: 5px;
  padding-top: 3px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 10;
  border-radius: 5px;
  border: 1px solid gray;
}

.page-content12 {
  background: #fff;
  background: var(--white);
  margin-top: 25px;
  margin-bottom: 10px;
  margin-left: 50px;
  border-radius: 5px;
  border: 1px solid gray;
}

h3.title-page1 {
  font-size: 21px;
  font-weight: bold;
  border-bottom: 1px rgb(204, 204, 204);
  border-bottom: 1px var(--formBorder);
  padding: 0px 0px 15px 0px;
}

.hello {
  margin: 10px 10px !important;
}

.shifts1 {
  padding-top: 8px;
}

.shifts12 {
  padding-top: 7px;
}

.shift {
  padding-top: 15px;
}

.shifts {
  padding-top: 15px;
}

.vertical-divider {
  border-left: 0.01em solid #d3d3d3;
  height: 100px;
}

.horizontal-divider {
  margin-top: 15px;
  margin-bottom: 18px;
  border-left: 0.01em solid #d3d3d3;
  width: 100%;
}

.alignment {
  font-size: 20px;
  padding: 20px;
}

.heading {
  margin-top: 15px;
  margin-left: 3px;
  width: 1450px;
}

.row1 {
  margin-bottom: 10px;
}

.arrow-dealcontent {
  position: relative;
  left: 0.2rem;
  /* background-color: #007bff; */
}

.move-dropdowns {
  position: relative;
  bottom: 1rem;
  left: -2rem;
}

.movement-tabs {
  position: relative;
  /* background-color: #007bff; */
  bottom: 2.375rem;
}

.movement-header {
  position: relative;
  /* background-color: #007bff; */
  bottom: 4.75rem;
  left: 0.7rem;
}

.movement-table {
  position: relative;
  /* background-color: #007bff; */
  bottom: 5.2rem;
  left: 1rem;
  /* border: 1.5px solid var(--tableBorder); */
  border-radius: 10px;
}

.movement-index {
  position: relative;
  bottom: 21rem;
  left: 3rem;
}
.movement-tranche-trans {
  position: relative;
  bottom: 5rem;
  left: 1rem;
}

.movement-tabs-creation {
  position: relative;
  /* background-color: #007bff; */
  bottom: 1.5rem;
  left: -0.5rem;
}

.movement-header-creation {
  position: relative;
  /* background-color: #007bff; */
  bottom: 3.5rem;
  left: 0.7rem;
}

.movement-table-creation {
  position: relative;
  /* background-color: #007bff; */
  bottom: 3.5rem;
  left: 1rem;
}

.movement-header-accounts {
  position: relative;
  bottom: 2.7rem;
  left: 0.7rem;
}

.movement-table-accounts {
  position: relative;
  bottom: 2.7rem;
  left: 1rem;
}

.move-dropdowns-reporting {
  position: relative;
  bottom: 1rem;
  left: 0.2rem;
}

.movement-tabs-reporting {
  position: relative;
  /* background-color: #007bff; */
  bottom: 2rem;
  left: -0.3rem;
}

.movement-tabs-reporting-Loan {
  position: relative;
  /* background-color: #007bff; */
  bottom: 3.3rem;
  left: -0.3rem;
}

.movement-header-reporting {
  position: relative;
  /* background-color: #007bff; */
  bottom: 4.28rem;
  left: 0.7rem;
}

.movement-table-reporting {
  position: relative;
  /* background-color: #007bff; */
  bottom: 5.6rem;
  left: 1rem;
  border: 1.5px solid rgba(33, 33, 33, 1);
  border: 1.5px solid var(--tableBorder);
}

.movement-header-annexs {
  position: relative;
  /* background-color: #007bff; */
  /* bottom: 2rem; */
  left: 0.7rem;
  padding-bottom: 6rem;
}

.movement-table-annexs {
  position: relative;
  /* background-color: #007bff; */
  /* bottom: 5.2rem; */
  bottom: 2rem;
  left: 1rem;
}

.move-dropdowns-annexs {
  position: relative;
  bottom: 0.7rem;
  left: -0.5rem;
}

.movement-tabs-annexs {
  position: relative;
  /* background-color: #007bff; */
  left: 1rem;
  top: 0.5rem;
}

.movement-table-reporting-annexs {
  position: relative;
  /* background-color: #007bff; */
  bottom: 2.5rem;
  /* left: 0.75rem; */
}

.movement-header-viewdetails-paymentdate {
  position: relative;
  /* background-color: #007bff; */
  bottom: 2.5rem;
  left: 0.7rem;
}

.movement-table-viewdetails {
  position: relative;
  /* background-color: #007bff; */
  bottom: 4rem;
  left: 1rem;
}

.movement-header-viewdetails-paymentdate {
  position: relative;
  /* background-color: #007bff; */
  bottom: 2.5rem;
  left: 0.7rem;
}
.movement-table-viewdetails {
  position: relative;
  /* background-color: #007bff; */
  bottom: 4rem;
  left: 1rem;
}

.file_reporting {
  position: relative;
  bottom: 5.5rem;
  left: 1rem;
}

.row11 {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* pdashboard */
.solid {
  margin-top: 40px;
  width: 330px;
  height: 230px;
  border-style: solid;
  color: grey;
  overflow-x: hidden;
  overflow-y: auto;
}

.dashed {
  margin-left: 30px;
  margin-top: 40px;
  width: 700px;
  height: 230px;
  border-style: dashed;
  color: grey;
  overflow-x: hidden;
  overflow-y: auto;
}

.dashedd {
  margin-left: 30px;
  margin-top: 40px;
  width: 330px;
  height: 230px;
  border-style: dashed;
  color: grey;
  overflow-x: hidden;
  overflow-y: auto;
}

.shifting {
  width: 750px;
  height: 380px;
}

.upper {
  margin-top: 40px;
  color: grey;
}

.spaces {
  margin-left: 340px;
}

.spacing {
  margin-right: 20px;
}

.topping {
  margin-left: 270px;
  margin-top: -50px;
  margin-bottom: 30px;
}

.toppingg {
  margin-top: -50px;
  margin-bottom: 30px;
}

/*fiels*/
/* .iconshift{
margin-left: 20px;
} */
/* .buttonshifts{
margin-left: 30px;
} */
/* .boxshifts{
margin-left: 75px;
} */
/* .setwidth {
border : 1px solid red;
}

.setwidth tr{
border : 1px solid green;
} */
.setwidth tr td {
  width: 1000px !important;
}

/* .loanshift{
width: 1000px !important;
} */

.rowmapfield {
  display: flex;
  flex-wrap: wrap;
}

.contentfields {
  /* max-width: 800px; */
  margin: auto;
  background: white;
  padding: 10px;
}

.table.fieldstable {
  margin-bottom: 0px !important;
}

#fieldtable1 th {
  background: #d1f4f0;
  color: #000 !important;
  font-weight: normal !important;
}

#fieldtable2 th {
  background: #d1f4f0;
  color: #000 !important;
  font-weight: normal !important;
}

#fieldtable3 th {
  background: #d1f4f0;
  color: #000 !important;
  font-weight: normal !important;
}

/* .fieldstable tbody tr :hover {
background: rgb(172, 233, 215);
} */
.fieldstable td {
  border-left: none;
  border-right: none;
}

/* .fieldstable tbody tr:first-child{ width: 50% } */
input.largerCheckbox {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  /* background-color: green; */
  cursor: pointer;
  /* width: 21px;
height: 18px;
margin-top: 2px */
}

.imgcolor {
  color: #048c88;
  color: var(--primary);
}

.buttonchanges:focus {
  border: none;
  outline: none;
}

.buttonchangess:focus {
  outline: none;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-text.buttonchanges {
  font-size: 18px !important;
  font-weight: bold;
  color: #6c757d !important;
  color: var(--gray) !important;
}

/*---------------STATUS CSS--------------------*/
.page-contentss {
  background: #fff;
  background: var(--white);
  border: 2px solid #a0caca;
  border-radius: 5px;
  box-shadow: 0px 2px 2px #ccc;
  padding-top: 15px;
  margin-top: 20px;
  margin-left: 20px;
}

.page-contentss1 {
  background: #fff;
  background: var(--white);
  border: 2px solid #a0caca;
  border-radius: 5px;
  box-shadow: 0px 2px 2px #ccc;
  margin-top: 20px;
  margin-left: 20px;
}

.page-contents11 {
  background: #fff;
  background: var(--white);
  border: 2px solid #a0caca;
  border-radius: 7px;
  margin-left: 20px;
}

.grid-divider {
  border-left: 2px solid #a0caca;
  margin-top: -50px;
  margin-left: 50px;
  position: absolute;
  height: 80px;
}

.grid-divider1 {
  border-left: 2px solid #a0caca;
  margin-top: -50px;
  margin-left: 50px;
  position: absolute;
  height: 80px;
}

.grid-dividers {
  border-left: 2px solid #a0caca;
  margin-top: -58px;
  margin-left: 50px;
  position: absolute;
  height: 85px;
}

.grid-dividers1 {
  border-left: 2px solid #a0caca;
  margin-top: -58px;
  margin-left: 50px;
  position: absolute;
  height: 85px;
}

.numbershift {
  position: relative;
  margin-left: 15px;
  background-color: #f1f7f7;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
  padding-top: 22px;
  padding-bottom: 20px;
  padding-right: 50px;
  /* margin-left: 5px; */
}

.numbershift1 {
  position: relative;
  margin-left: 15px;
  background-color: #f1f7f7;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
  padding-top: 22px;
  padding-bottom: 20px;
  padding-right: 50px;
  /* margin-left: 5px; */
}

.contentshift {
  margin-top: 10px;
}

.numbershifts {
  text-align: center !important;
  background-color: #f1f7f7;
  /* flex: 0 0 8.333333%; */
  /* max-width: 8.333333%; */
  padding-top: 28px;
  padding-bottom: 14px;
  padding-right: 50px;
  margin-left: 15px;
}

.numbershifts1 {
  text-align: center !important;
  background-color: #f1f7f7;
  /* flex: 0 0 8.333333%; */
  /* max-width: 8.333333%; */
  padding-top: 28px;
  padding-bottom: 14px;
  padding-right: 50px;
  margin-left: 15px;
}

.contentshifts {
  margin-top: 20px;
}

.row12 {
  display: flex;
  flex-wrap: wrap;
  margin-right: -240px;
  margin-left: -5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.rows1 {
  margin-top: -15px;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.rows1s {
  margin-top: -15px;
  margin-bottom: -12px;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

/* @media (-webkit-min-device-pixel-ratio: 0.8){
.numbershifts1{
  text-align: center !important;
  background-color: #f1f7f7;
  flex: 0 0 8.333333%; 
  max-width: 8.333333%;
  padding-top: 10px;
  padding-bottom: 22px;
  padding-right: 50px;
  margin-left: 15px;
}
.numbershifts1 .grid-dividers1{
  border-left: 2px solid #a0caca;
  margin-top: -50px;
  margin-left: 50px;
  position: absolute;
  height: 82px;
}
 /* .contentshifts{
  margin-top: 30px;
  margin-bottom: 13px;
} */
/* } */
@media (-webkit-min-device-pixel-ratio: 0.9) {
  .labelform {
    width: 850px;
    overflow: hidden;
  }

  .viewmorecolumn {
    overflow-y: hidden;
    margin-right: 150px !important;
    width: 850px;
  }

  .areachart-graph .recharts-surface {
    width: 550px !important;
  }

  .pieinnercontainer1-detail {
    margin-left: -20px;
    margin-top: -80px;
    margin-bottom: -30px;
    flex: 1 1;
  }

  .op-main-container {
    border: 1px solid #212121 !important;
    border-radius: 10px;
    min-width: 590px !important;
    max-width: 590px !important;
    height: 324px !important;
    width: 590px !important;
    margin: 20px 0px 0px 0px;
    flex-wrap: wrap;
  }

  .pieoutercontainers .recharts-surface {
    width: 300px !important;
  }

  /* .barchart-chart-container{
  border: 1px solid red;
  width: 300px !important;
  margin-left: 150px !important;
}
.pieoutercontainers .recharts-wrapper{
  margin-left: 80px !important;
  margin-right: 0;
} */
}

@media (-webkit-min-device-pixel-ratio: 0.8) {
  .labelform {
    width: 750px;
    overflow: hidden;
  }

  .viewmorecolumn {
    overflow-y: hidden;
    margin-right: 400px;
    width: 850px !important;
  }

  .areachart-graph .recharts-surface {
    width: 600px;
  }

  .pieinnercontainer1-detail {
    margin-left: -20px;
    margin-top: -80px;
    margin-bottom: -30px;
    flex: 1 1;
  }

  .op-main-container {
    border: 1px solid #212121;
    border-radius: 10px;
    min-width: 670px;
    max-width: 670px;
    height: 326px;
    width: 670px;
    margin: 20px 0px 0px 0px;
    flex-wrap: wrap;
  }

  /* .pieoutercontainers .recharts-wrapper{
  margin-left: 50px;
} */
  .pieoutercontainers .recharts-surface {
    width: 300px !important;
  }
}

@media (-webkit-min-device-pixel-ratio: 1) {
  .labelform {
    width: 780px;
    overflow: hidden;
  }

  .viewmorecolumn {
    overflow-y: hidden;
    margin-right: 10px !important;
    width: 850px !important;
  }

  .op-main-container {
    border: 1px solid #212121;
    border-radius: 10px;
    min-width: 530px !important;
    max-width: 530px !important;
    height: 324px;
    width: 530px !important;
    margin: 20px 0px 0px 0px;
    flex-wrap: wrap;
  }

  .areachart-graph .recharts-surface {
    width: 480px !important;
  }

  .curvechart-graph .recharts-surface {
    width: 1000px !important;
  }

  .pieinnercontainer1-detail {
    /* margin-left: -100px; */
    margin-top: -80px;
    margin-bottom: -30px;
    flex: 1 1;
  }

  .recharts-surface {
    width: 500px !important;
  }

  .pieinnercontainer1-details1 {
    margin-left: -85px !important;
    margin-top: -80px !important;
    flex: 1 1 !important;
  }

  .pieinnercontainer2 {
    flex: 2 1 !important;
    margin-left: 70px !important;
  }

  .pieinnercontainerportfolio {
    flex: 2 1 !important;
    margin-left: -30px !important;
  }
}

@media (-webkit-min-device-pixel-ratio: 1.1) {
  .op-main-container {
    border: 1px solid #212121;
    border-radius: 10px;
    min-width: 470px !important;
    max-width: 470px !important;
    height: 324px;
    width: 470px !important;
    margin: 20px 0px 0px 0px;
    flex-wrap: wrap;
  }

  .areachart-graph .recharts-surface {
    width: 430px !important;
  }

  /* .bar-chart-container{
    max-width: 100%;
    overflow: auto;
  } */
  .labelform {
    /* width: 720px; */
    overflow: hidden;
    /* width: 50% !important; */
    flex: 1 1;
  }

  .viewmorecolumn {
    margin-right: -5px;
    width: 50% !important;
    flex: 1 1;
  }

  .tick-icon1 {
    margin: 35px 40px 0px 20px !important;
  }

  .cross-icon1 {
    margin: 35px 40px 0px 20px !important;
  }

  .curvechart-graph .recharts-surface {
    width: 900px !important;
  }

  .curvechart-graph {
    margin-bottom: 0px;
  }

  .pieinnercontainer1-detail {
    /* margin-left: -80px; */
    margin-top: -80px;
    margin-bottom: -30px;
    flex: 1 1;
  }

  .pieinnercontainer2 {
    flex: 2 1 !important;
    margin-left: 0px !important;
  }

  .pieinnercontainerportfolio {
    flex: 2 1 !important;
    margin-left: -40px !important;
  }

  .pieinnercontainer1-details1 {
    margin-left: -60px !important;
    margin-top: -80px !important;
    flex: 1 1 !important;
  }

  .recharts-surface {
    width: 440px !important;
  }
}

@media (-webkit-min-device-pixel-ratio: 1.25) {
  .op-main-container {
    border: 1px solid #212121;
    border-radius: 10px;
    min-width: 390px !important;
    max-width: 390px !important;
    height: 340px;
    width: 390px !important;
    margin: 20px 0px 0px 0px;
    flex-wrap: wrap;
  }

  .areachart-graph .recharts-surface {
    width: 360px !important;
  }

  .labelform {
    /* width: 720px; */
    overflow: hidden;
    width: 80% !important;
    /* flex: 1; */
  }

  .viewmorecolumn {
    margin-right: -5px;
    width: 50% !important;
    flex: 1 1;
  }

  .MuiInputBase-inputMarginDense {
    color: #212121 !important;
    background: #fff !important;
    width: 120px !important;
    margin-top: 10px;
  }

  /* .matchscroll{
  display: flex;
  flex-wrap: wrap;

  justify-content: space-between;
 } */
  .curvechart-graph .recharts-surface {
    width: 800px !important;
  }

  .curvechart-graph {
    margin-bottom: 0px;
  }

  .pieinnercontainer1-detail {
    /* margin-left: -50px; */
    margin-top: -80px;
    margin-bottom: -30px;
    flex: 1 1;
  }

  .pieinnercontainer1-details1 {
    margin-left: -30px !important;
    margin-top: -80px !important;
    flex: 1 1 !important;
  }

  .recharts-surface {
    width: 370px !important;
  }

  .pieinnercontainer2 {
    flex: 2 1 !important;
    margin-left: -30px !important;
  }

  .pieinnercontainerportfolio {
    flex: 2 1 !important;
    margin-left: -60px !important;
  }

  .pool-box-text-container1 {
    margin-bottom: 75px !important;
    width: 300px;
  }

  .numbershifts {
    text-align: center !important;
    background-color: #f1f7f7;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
    padding-top: 30px;
    padding-bottom: 28px;
    padding-right: 50px;
    margin-left: 15px;
  }

  .numbershifts1 {
    text-align: center !important;
    background-color: #f1f7f7;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
    padding-top: 45px;
    padding-bottom: 37px;
    padding-right: 50px;
    margin-left: 15px;
  }

  .numbershift1 {
    text-align: center !important;
    background-color: #f1f7f7;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
    padding-top: 32px;
    padding-bottom: 28px;
    padding-right: 50px;
    margin-left: 15px;
  }

  .numbershifts .grid-dividers {
    border-left: 2px solid #a0caca;
    margin-top: -60px;
    margin-left: 50px;
    position: absolute;
    height: 100px;
  }

  .numbershift1 .grid-divider1 {
    border-left: 2px solid #a0caca;
    margin-top: -60px;
    margin-left: 50px;
    position: absolute;
    height: 100px;
  }

  .numbershifts1 .grid-dividers1 {
    border-left: 2px solid #a0caca;
    margin-top: -72px;
    margin-left: 50px;
    position: absolute;
    height: 122px;
  }

  .contentshifts {
    margin-top: 12px;
  }
}

@media (-webkit-min-device-pixel-ratio: 1.5) {
  .page-content2 {
    background: #fff;
    background: var(--white);
    padding: 28.5px;
    margin-top: 50px;
    margin-bottom: 70px;
    border-radius: 10px;
    border: 1px solid #212121;
  }

  .op-main-container {
    border: 1px solid #212121;
    border-radius: 10px;
    min-width: 310px !important;
    max-width: 310px !important;
    height: 367px !important;
    width: 310px !important;
    margin: 20px 0px 0px 0px;
    flex-wrap: wrap;
  }

  .areachart-graph .recharts-surface {
    width: 280px !important;
  }

  .curvechart-graph .recharts-surface {
    width: 650px !important;
  }

  .viewmorecolumn {
    width: 850px !important;
    margin-left: 40px;
  }

  /* .matchscroll{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
 } */
  .curvechart-graph {
    margin-bottom: 28px;
  }

  .metrics-flex {
    margin-left: 120px;
    margin-bottom: 45px;
  }

  .pieinnercontainer1-detail {
    margin-left: -35px;
    margin-top: -80px;
    margin-bottom: 0px;
    flex: 1 1;
  }

  .pieinnercontainer2 {
    flex: 2 1 !important;
    margin-left: -105px !important;
  }

  .pieinnercontainerportfolio {
    flex: 2 1 !important;
    margin-left: -105px !important;
  }

  .pieinnercontainer1-details1 {
    margin-left: -18px !important;
    margin-top: -80px !important;
    flex: 1 1 !important;
  }

  .recharts-surface {
    width: 300px !important;
  }

  .numbershifts {
    text-align: center !important;
    background-color: #f1f7f7;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
    padding-top: 58px;
    padding-bottom: 48px;
    padding-right: 50px;
    margin-left: 15px;
  }

  .numbershifts1 {
    text-align: center !important;
    background-color: #f1f7f7;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
    padding-top: 58px;
    padding-bottom: 48px;
    padding-right: 50px;
    margin-left: 15px;
  }

  .numbershifts .grid-dividers {
    border-left: 2px solid #a0caca;
    margin-top: -86px;
    margin-left: 50px;
    position: absolute;
    height: 146px;
  }

  .numbershifts .grid-dividers1 {
    border-left: 2px solid #a0caca;
    margin-top: -86px;
    margin-left: 50px;
    position: absolute;
    height: 146px;
  }

  .numbershifts1 .grid-dividers1 {
    border-left: 2px solid #a0caca;
    margin-top: -86px;
    margin-left: 50px;
    position: absolute;
    height: 146px;
  }

  .numbershift1 {
    text-align: center !important;
    background-color: #f1f7f7;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
    padding-top: 58px;
    padding-bottom: 48px;
    padding-right: 50px;
    margin-left: 15px;
  }

  .numbershift1 .grid-divider1 {
    border-left: 2px solid #a0caca;
    margin-top: -86px;
    margin-left: 50px;
    position: absolute;
    height: 146px;
  }

  .contentshifts {
    margin-top: 12px;
  }

  .headingspaces1 {
    font-family: "Catamaran", sans-serif;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 0px !important;
  }

  .pool-box-text-container1 {
    margin-bottom: 62px !important;
    width: 300px;
  }
}

.fontsizeset {
  padding-top: 12px;
  font-size: 18px;
}

/* .css-26l3qy-menu{
height: 100px;
/* min-height: 100px; */
/* overflow-x: hidden;*/
/* overflow-y: auto; */
/* ] */

.as-shifted {
  margin-left: 50px;
  margin-right: 50px;
  position: relative;
  box-sizing: border-box;
}

.setup {
  padding-left: 8px;
}

.tag-container {
  min-width: 100%;
  max-width: 105%;
  display: flex;
  flex-wrap: wrap;
  min-height: 50px;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 10px;
}

.tag {
  display: flex;
  align-items: center;
  padding: 5px;
  border: 1px solid gray;
  border-radius: 5px;
  height: 25px;
  margin: 2px 5px 2px 0px;
  color: black;
}

.tag-container input {
  border: none;
  flex: 1 1;
  outline: none;
  padding: 5;
  /* background: black; */
  color: black;
}

.tag span {
  margin-left: 5px;
  cursor: pointer;
}

.tag-containers {
  min-height: 50px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  font-family: sans-serif;
  text-align: start;
}

.form-container {
  width: 400px;
}

.form-container-tranches {
  width: 1100px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(26%, 1fr));
  grid-gap: 1rem;
  gap: 1rem;
}

.form-container-tranches > .modalsubmit {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-end: auto;
  justify-self: end;
}

.form-container-setup {
  width: 436px;
}

.form-container1 {
  width: 370px;
}

.input-container {
  margin-bottom: 20px;
  position: relative;
}

.input-container-select-type {
  display: flex;
  margin-bottom: 20px;
}

.input-container-account {
  margin-bottom: 20px;
  margin-right: 20px;
}

.label {
  color: #212121;
  color: var(--headerText);
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 8px !important;
  /* border: 1px solid red
 ; */
}

.label1 {
  color: #212121;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 8px !important;
  /* border: 1px solid red
 ; */
}

.label::after {
  content: "\a";
  white-space: pre;
}

.input {
  border: 1.5px solid rgba(33, 33, 33, 1);
  border: 1.5px solid var(--tableBorder);
  border-radius: 8px;
  padding: 10px 10px;
  color: #212121;
  color: var(--tableText);
  display: block;
  width: 100%;
}

.input1 {
  border: 1.5px solid #212121;
  border-radius: 8px;
  padding: 10px 10px;
  color: #212121;
  display: block;
  width: 370px;
}

.input-none {
  padding: 0px 10px;
  border: none;
  outline: none;
}

.input-none1 {
  padding: 0px 10px;
  outline: none;
  border: none;
  position: relative;
  border-radius: 8px;
  color: #212121;
  color: var(--tableText);
  display: block;
  width: 450px !important;
  height: 30px;
}

.flex1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  width: 450px !important;
  height: 38px;
}

.flex1 p {
  margin-bottom: 0px !important;
  padding: 7px;
  color: #8c8c8c;
}

select {
  /* styling */
  background-color: white;
  border: 2px solid #212121;
  border-radius: 8px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;
  width: 100%;

  /* reset */

  margin: 0;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.input-select {
  background-image: linear-gradient(45deg, transparent 50%, #018e82 50%),
    linear-gradient(135deg, #018e82 50%, transparent 50%);
  background-image: linear-gradient(45deg, transparent 50%, var(--button) 50%),
    linear-gradient(135deg, var(--button) 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  border: 1.5px solid rgba(33, 33, 33, 1);
  border: 1.5px solid var(--tableBorder);
  border-radius: 8px;
  padding: 10px 10px;
  color: #212121;
  color: var(--tableText);
  background-color: #fff;
  background-color: var(--white);
  display: block;
  width: 100%;
}

.input-general-css {
  position: relative;
  border: 1.5px solid #212121;
  border-radius: 8px;
  padding: 10px 10px;
  color: #212121;
  display: block;
  width: 100% !important;
}

.input-select-private-key {
  margin-top: -10px;
  background-image: linear-gradient(45deg, transparent 50%, #018e82 50%),
    linear-gradient(135deg, #018e82 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  border: 1.5px solid #212121;
  border-radius: 8px;
  padding: 10px 10px;
  color: #212121;
  background-color: #fff;
  display: block;
  width: 200px;
}

.MuiAccordionSummary-expandIconWrapper {
  color: #018e82 !important;
}

.eye-btn {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 25px !important;
  padding: 6px !important;
  color: #018e82;
}

.eye-btn1 {
  padding: 10px;
  font-size: 45px !important;
  color: #018e82;
}

.lockicon {
  padding-left: 5px;
  padding-bottom: 5px;
  color: #8c8c8c;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0px;
  width: 100%;
}

.flex p {
  margin-bottom: 0px !important;
  padding: 7px;
  color: #8c8c8c;
}

.simple-container {
  font-size: large;
  margin: 5px 6px;
}

input[type="radio"] {
  width: 20px;
  height: 20px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #ffc000;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 1.5px solid #212121;
}

.error-msg {
  color: red;
  font-size: 13px;
}

.after-radio-input {
  width: 100%;
  padding: 5px;
  border: none !important;
  outline: none !important;
}

.input-radio-div {
  outline: none !important;
  border: 2px solid #212121;
  border-radius: 8px;
  padding: 8px 0;
  margin: 10px 0;
  height: 45px;
  width: 100%;
}

/* for custom radio button */
/* Customize the label (the container) */
.label-radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 17px;
  -webkit-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.label-radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: rgb(250, 250, 250);
  border-radius: 50%;
  border: thin solid black;
}

/* On mouse-over, add a grey background color */
.label-radio-container:hover input ~ .checkmark {
  background-color: rgb(255, 255, 255);
}

/* When the radio button is checked, add a blue background */
.label-radio-container input:checked ~ .checkmark {
  background-color: rgb(255, 255, 255);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.label-radio-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.label-radio-container .checkmark:after {
  top: 3px;
  left: 3px;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: #ffc000;
}

.flex-radio {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;
}

.login-sign_up-link:hover {
  color: #018e82;
}

.login-sign_up-links:hover {
  color: #018e82;
}

.login-sign_up-links1:hover {
  color: #000;
}

.login-sign_up-links11:hover {
  color: #000;
}

::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #adadad;
  opacity: 5;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #adadad;
  opacity: 5;
}

@media (-webkit-min-device-pixel-ratio: 1.1) {
  .login-content1 {
    padding: 150px 260px;
    width: 100%;
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (-webkit-max-device-pixel-ratio: 1) {
  .login-content1 {
    padding: 150px 280px;
    width: 100%;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (-webkit-min-device-pixel-ratio: 1.1) {
  .login-content1 {
    padding: 150px 260px;
    width: 100%;
    position: relative;
    z-index: 2;
  }

  .kyc-content2 {
    max-width: 370px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .kyc-content2-certification {
    max-width: 410px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (-webkit-min-device-pixel-ratio: 1.25) {
  .login-content1 {
    padding: 150px 200px;
    width: 100%;
    position: relative;
    z-index: 2;
  }

  .topcontent {
    margin-top: 100px !important;
  }
}

@media (-webkit-min-device-pixel-ratio: 1.5) {
  .login-content1 {
    padding: 150px 130px;
    width: 100%;
    position: relative;
    z-index: 2;
  }

  .topcontent {
    margin-top: 150px !important;
  }
}

.userInfo {
  display: block;
  margin-top: 18px;
  margin-left: 15px;
  margin-right: 30px;
  text-align: center;
  background: #048c88;
  color: #fff !important;
  font-weight: lighter;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 35px;
  border-radius: 50%;
}

.userInfo p {
  margin-top: 2px;
  color: white !important;
}

.issure-icon-active {
  opacity: 1;
  background-color: #1a534e;
  background-color: var(--sideBarIconsBg);
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  border-radius: 10px;
  /* width: 165px; */
}

/* .issure-icon-active img {
  filter: var(
    --sideBarIconActiveFilter,
    invert(38%) sepia(57%) saturate(568%) hue-rotate(141deg) brightness(93%)
      contrast(88%)
  );
}

.issure-icon img {
  filter: var(
    --sideBarIconFilter,
    invert(27%) sepia(62%) saturate(451%) hue-rotate(147deg) brightness(95%)
      contrast(94%)
  );
} */

.issure-icon-active:hover {
  background-color: #1a534e;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  border-radius: 10px;
  width: 200px;
}

.issure-icon:hover {
  background-color: #1a534e;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 10px;
}

.issure-icon {
  opacity: 0.5;
  display: flex;
  padding: 5px;
  grid-gap: 8px;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.issure-icon-chat {
  opacity: 1;
}

.issure-icon-disable-chat {
  opacity: 0.5;
}
.sidebar ul {
  margin: 0px;
  padding-top: 100px;
  color: #fff;
}

.issure-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 7px 5px;
  background-color: transparent;
  /* color: #ffffff; */
}

.ReactModal__Content--after-open {
  margin-top: 50px;
  /* border: 1px solid #212121 !important; */
  border-radius: 8px !important;
}

.modalshiftcontent {
  margin-right: 30px;
  margin-left: 30px;
}

.modalshiftcontent1 {
  width: 460px !important;
  height: 580px !important;
  overflow-y: auto;
}

.modalshiftcontent2 {
  width: 460px !important;
  height: 140px !important;
  overflow-y: auto;
}

.bankdetails-heading {
  padding: 0px 0px 10px 0px !important;
}

.popupbutton2 {
  color: #8c8c8c;
  background: none;
  border: none;
  margin-top: 30px !important;
  font-size: 18px;
  justify-content: end;
}

.popupbutton2:hover {
  color: #8c8c8c;
}

.popupbutton22 {
  color: #8c8c8c;
  background: none;
  border: none;
  margin-top: 30px !important;
  font-size: 18px;
  justify-content: end;
  /* padding-right: 1rem; */
}

.popupwaterfall {
  color: #8c8c8c;
  background: none;
  border: none;
  font-size: 18px;
  justify-content: end;
  /* padding-right: 1rem; */
}

.popupwaterfalldelete {
  color: #8c8c8c;
  background: none;
  border: none;
  font-size: 18px;
  margin-left: 20px;
  justify-content: end;
  /* padding-right: 1rem; */
}

.popupbutton23 {
  color: #8c8c8c;
  background: none;
  border: none;
  /* margin-top: 30px !important; */
  font-size: 18px;
  justify-content: end;
  padding-right: 1rem;
}

.popupbutton22:hover {
  color: #8c8c8c;
}

.backshift {
  margin-top: 15px !important;
}

.popupbutton1 {
  color: #018e82;
  color: var(--button);
  border: rgba(33, 33, 33, 1);
  border: var(--tableBorder);
  font-size: 15px;
}

.popupbutton1:hover {
  color: #018e82;
  color: var(--button);
}

.popupbutton2 {
  margin-top: 15px;
  color: #018e82;
  color: var(--button);
  background: none;
  border: none;
  font-size: 15px;
}

.popupbutton2:hover {
  color: #018e82;
  color: var(--button);
}

.popupbuttons1 {
  color: #018e82;
  color: var(--button);
  background: none;
  border: none;
  font-size: 15px;
  margin-left: 20px;
}

.popupbuttons1:hover {
  color: #018e82;
  color: var(--button);
}

.justify-content-start {
  justify-content: flex-start !important;
  margin-left: 18px;
  margin-top: -5px;
}

.justify-content-end {
  justify-content: flex-end !important;
  margin-left: 180px;
}

.justify-content-accept {
  justify-content: flex-end !important;
  margin-left: 230px;
}

.justify-content-accept-pa {
  justify-content: flex-end !important;
  margin-left: 205px;
}

.justify-content-reject {
  justify-content: flex-end !important;
  margin-left: 240px;
}

.justify-content-end3 {
  justify-content: flex-end !important;
  margin-left: 205px;
}

.justify-content-addedit {
  justify-content: flex-end !important;
  margin-left: 243px;
}

.justify-content-addtrans {
  justify-content: flex-end !important;
  margin-left: 248px;
}

.justify-content-addacc {
  justify-content: flex-end !important;
  margin-left: 240px;
}

.justify-content-end12 {
  justify-content: flex-end !important;
  margin-left: 300px;
}

.justify-content-start11 {
  justify-content: flex-start !important;
  /* margin-left: 1rem; */
  position: absolute;
  left: 3.2rem;
  bottom: 0.4rem;
}

.justify-content-end11 {
  justify-content: flex-end !important;
  margin-left: 258px;
}

.justify-content-end1 {
  justify-content: flex-end !important;
  margin-left: 240px;
}

.justify-content-end2 {
  justify-content: flex-end !important;
  margin-left: 210px;
}

.justify-content-endforlogin {
  justify-content: flex-end !important;
  margin-left: 234px;
}

.justify-content-loginforgot {
  justify-content: flex-end !important;
  margin-left: 220px;
}

.justify-content-commit {
  justify-content: flex-end !important;
  margin-left: 220px;
}

.justify-content-editcommit {
  justify-content: flex-end !important;
  margin-left: 189px;
}

.justify-content-invest {
  justify-content: flex-end !important;
  margin-left: 270px;
}

.popupheading h4 {
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  color: #212121;
  font-size: 20px;
}

.popupheadingabc {
  font-weight: 600;
  color: #212121;
  font-size: 24px;
}

.filter__popup-outer_container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 80px; */
}

.filter__popup-inner_container {
  border: 1px solid black;
  border-radius: 15px;
  padding: 25px;
  width: 450px;
}

.filter__popup-title {
  font-size: 28px;
}

.margin-top {
  margin-top: 45px;
}

.switch {
  position: relative;
  width: 3rem;
  margin: 0 10px;
  height: 20px;
  display: flex;
  justify-content: space-between;
}

.switch:before {
  content: "  ";
  position: absolute;
  left: 0px;
  z-index: -1;
  width: 100%;
  height: 0.5rem;
  background: rgb(245, 245, 245);
  border-radius: 30px;
  border: 1px solid #212121;
}

.switch__label {
  display: inline-block;
  width: 0.2rem;
  padding: 0.5rem;
  text-align: center;
  cursor: pointer;
  transition: color 200ms ease-out;
}

/* .switch__label:hover {
  color: red;
} */

.switch__indicator {
  width: 1.7rem;
  height: 1.7rem;
  position: absolute;
  top: -0.6rem;
  left: 0;
  background-color: white;
  border: 2px solid #212121;
  border-radius: 50%;
  transition: background 300ms cubic-bezier(0.17, 0.67, 0.14, 1.03),
    -webkit-transform 600ms cubic-bezier(0.02, 0.94, 0.09, 0.97);
  transition: transform 600ms cubic-bezier(0.02, 0.94, 0.09, 0.97),
    background 300ms cubic-bezier(0.17, 0.67, 0.14, 1.03);
  transition: transform 600ms cubic-bezier(0.02, 0.94, 0.09, 0.97),
    background 300ms cubic-bezier(0.17, 0.67, 0.14, 1.03),
    -webkit-transform 600ms cubic-bezier(0.02, 0.94, 0.09, 0.97);
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.switch input#one:checked ~ .switch__indicator {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.switch input#two:checked ~ .switch__indicator {
  -webkit-transform: translate3d(1.5rem, 0, 0);
          transform: translate3d(1.5rem, 0, 0);
}

.switch input[type="radio"]:not(:checked),
.switch input[type="radio"]:checked {
  display: none;
}

.radio-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.switch1 {
  position: relative;
  width: 3rem;
  margin: 0 10px;
  height: 20px;
  display: flex;
  justify-content: space-between;
}

.switch1:before {
  content: "  ";
  position: absolute;
  left: 0px;
  width: 100%;
  height: 0.5rem;
  background: rgb(245, 245, 245);
  border-radius: 30px;
  border: 1px solid #212121;
}

.switch1__label {
  display: inline-block;
  width: 0.2rem;
  padding: 0.5rem;
  text-align: center;
  cursor: pointer;
  transition: color 200ms ease-out;
}

/* .switch1__label:hover {
  color: red;
} */

.switch1__indicator {
  width: 1.7rem;
  height: 1.7rem;
  position: absolute;
  top: -0.6rem;
  left: 0;
  background-color: white;
  border: 2px solid #212121;
  border-radius: 50%;
  transition: background 300ms cubic-bezier(0.17, 0.67, 0.14, 1.03),
    -webkit-transform 600ms cubic-bezier(0.02, 0.94, 0.09, 0.97);
  transition: transform 600ms cubic-bezier(0.02, 0.94, 0.09, 0.97),
    background 300ms cubic-bezier(0.17, 0.67, 0.14, 1.03);
  transition: transform 600ms cubic-bezier(0.02, 0.94, 0.09, 0.97),
    background 300ms cubic-bezier(0.17, 0.67, 0.14, 1.03),
    -webkit-transform 600ms cubic-bezier(0.02, 0.94, 0.09, 0.97);
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.switch1 input#three:checked ~ .switch1__indicator {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.switch1 input#four:checked ~ .switch1__indicator {
  -webkit-transform: translate3d(1.5rem, 0, 0);
          transform: translate3d(1.5rem, 0, 0);
}

.switch1 input[type="radio"]:not(:checked),
.switch1__label input[type="radio"]:checked {
  display: none;
}

#four,
#three {
  display: none;
}

.radio1-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

input[type="date"]::before {
  content: attr(placeholder);
  position: absolute;
  color: #999999;
}

input[type="date"] {
  color: #ffffff;
}

input[type="date"]:focus,
input[type="date"]:valid {
  color: #666666;
}

input[type="date"]:focus::before,
input[type="date"]:valid::before {
  content: "";
}

.PrivateValueLabel-label {
  color: white !important;
}

.PrivateValueLabel-circle {
  background-color: #8c8c8c !important;
}

.modal1 {
  margin-top: 500px !important;
}

.heyya {
  margin-top: 70px !important;
  margin-bottom: -50px;
}

.modalPopupregister {
  margin: -20px;
  padding: 50px 30px;
}

.react-loder-section {
  margin-left: 75px;
  margin-top: 25px;
}

.fields-radion-button-center {
  position: absolute;
  height: 17px;
  width: 17px;
  border-radius: 50%;
  top: 3px;
  left: 3px;
  background: #ffc000;
}

.input-range__label--max {
  color: #fff !important;
}

.input-range__label--min {
  color: #fff !important;
}

.input-range__track--active {
  background: #018e82 !important;
}

.input-range__label--value {
  border-radius: 5px;
  padding: 5px 5px;
  position: relative !important;
  top: -38px !important;
  left: -20px;
  background-color: #8c8c8c;
  color: #fff !important;
}

.input-range__label-container {
  left: 0 !important;
}

.input-range__slider {
  background-color: #018e82 !important;
  border: none !important;
  top: 0 !important;
}

.input-range__track {
  height: 0.2rem !important;
}

.fields-date-input {
  border: 1px solid #212121;
  padding: 5px;
  width: 170px;
  border-radius: 10px;
}

.fields-date-input1 {
  border: 1.5px solid #212121;
  border-radius: 8px;
  padding: 0px 10px;
  color: #212121;
  width: 270px !important;
}

.input-container-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input-container-date1 {
  display: flex;
}

.input-date-text-ele {
  position: absolute;
  left: 220px;
  /* top: 420px; */
}

.submitbuttoncss {
  margin-left: 20px !important;
}

.buttonissue {
  left: 20px;
  top: -10px;
}

.buttonissue1 {
  margin-left: 20px !important;
  font-size: 38px !important;
  margin-top: 5px !important;
  color: #018e82;
  cursor: pointer;
}

.addbutton {
  color: #018e82;
}

.modalPopupregister {
  margin: -20px;
  padding: 50px 30px;
}

.react-loder-section {
  margin-left: 75px;
  margin-top: 25px;
}

/* .filter {
  margin-top: 500px !important;
} */

.modal11 {
  margin-top: 500px !important;
}

.heelo {
  margin-top: 5px;
}

.hellocard {
  padding: 0 !important;
  /* margin-top: -19px !important; */
}

.input-range__slider:hover {
  box-shadow: 0 0 15px #018e82;
}

.input-range__label--value {
  border-radius: 5px;
  padding: 5px 5px;
  position: relative !important;
  top: -43px !important;
  left: -20px;
  background-color: #8c8c8c;
  color: #fff !important;
}

.input-range__label--value:after {
  content: " ";
  width: 0px;
  height: 0px;
  border-top: 5px solid #8c8c8c;
  border-left: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid transparent;
  position: absolute;
  left: 50%;
  top: 100%;
  margin-left: -6px;
}

.dashboardheading {
  font-size: 28px;
  color: #212121;
  font-weight: 600;
  font-family: arial;
  margin-bottom: 20px;
  margin-top: 20px;
}

.top-container101 {
  margin-bottom: 20px;
  display: inline-block;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.issuerDashboard-table-top-button-insights {
  margin-left: 10px !important;
  color: #212121 !important;
  color: var(--text_Color) !important;
  padding: 5px;
}

.issuerDashboard-table-top-button-insights-active {
  color: #018e82 !important;
  color: var(--button) !important;
  margin-left: 10px !important;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 900;
  position: relative; /* Needed for positioning the pseudo-element */
}

.issuerDashboard-table-top-button-insights-active::after {
  content: "";
  position: absolute;
  bottom: -1px; /* Position the line at the bottom of the text */
  left: 6px; /* Adjust the starting position */
  width: calc(100% - 25px); /* Set width to text width minus 20px */
  height: 2px; /* Line height */
  background-color: #018e82 !important; /* Line color */
}

.issuerDashboard-table-top-button-workbench {
  padding: 5px;
  color: #212121 !important;
  color: var(--text_Color) !important;
  margin-left: 10px !important;
}

.issuerDashboard-table-top-button-workbench-active {
  color: #018e82 !important;
  color: var(--button) !important;
  margin-left: 10px !important;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 900;
  position: relative; /* Needed for positioning the pseudo-element */
}

.issuerDashboard-table-top-button-workbench-active::after {
  content: "";
  position: absolute;
  bottom: -1px; /* Position the line at the bottom of the text */
  left: 6px; /* Adjust the starting position */
  width: calc(100% - 25px); /* Set width to text width minus 20px */
  height: 2px; /* Line height */
  background-color: #018e82 !important; /* Line color */
}

.issuerDashboard-table-top-button-rejected {
  padding: 5px;
  color: #212121 !important;
  color: var(--text_Color) !important;
  margin-left: 10px !important;
}

.issuerDashboard-table-top-button-rejected-active {
  color: #018e82 !important;
  color: var(--button) !important;
  margin-left: 10px !important;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 900;
  position: relative; /* Needed for positioning the pseudo-element */
}

.issuerDashboard-table-top-button-rejected-active::after {
  content: "";
  position: absolute;
  bottom: -1px; /* Position the line at the bottom of the text */
  left: 6px; /* Adjust the starting position */
  width: calc(100% - 25px); /* Set width to text width minus 20px */
  height: 2px; /* Line height */
  background-color: #018e82 !important; /* Line color */
}

.dashboard-top-right-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.dashboard-top-right-container1 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1 1;
  position: relative;
}

.search-bar-outer-container {
  width: 30px;
  background: #fff;
  background: var(--white);
  color: #212121;
  color: var(--tableText);
}

/* .search-mui-icon{
  background-color: transparent;
  border: none;
  outline: none;
  color: #018e82;
  margin-top: 50px;
} */

.search-mui-icons {
  background-color: #fff;
  background-color: var(--white);
  border: none;
  outline: none;
  color: #018e82;
  color: var(--button);
  margin-top: 20px;
  margin-right: 20px;
  position: relative;
  /* top: -0.3rem; */
}

.search-mui-icons1 {
  background-color: #fff;
  background-color: var(--white);
  border: none;
  outline: none;
  color: #018e82;
  margin-top: 20px;
}

.search-mui-icons2 {
  background-color: #fff;
  background-color: var(--white);
  border: none;
  outline: none;
  color: #018e82;
}

.search-bar-main-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.close-mui-icon {
  background-color: transparent;
  border: none;
  outline: none;
  color: #018e82;
  margin-left: 12px;
  margin-top: 20px;
}

.headerdashboard-trustee {
  font-family: "Catamaran", sans-serif !important;
  font-size: 24px;
  color: #212121;
  color: var(--headerText);
  font-weight: 700 !important;
  line-height: 39px;
  /* margin-top: 26px; */
  min-width: 30rem;
  text-align: left;
  padding-top: 1rem;
}
.headerdashboard {
  font-family: "Catamaran", sans-serif !important;
  font-size: 24px;
  color: #212121;
  color: var(--headerText);
  font-weight: 700 !important;
  line-height: 39px;
  /* margin-top: 26px; */
  min-width: 30rem;
  text-align: left;
}

.headerdashboard-analytics {
  font-family: "Catamaran", sans-serif !important;
  font-size: 24px;
  color: #212121;
  color: var(--headerText);
  font-weight: 700 !important;
  line-height: 39px;
  margin-top: 26px;
  min-width: 30rem;
  text-align: left;
}

.headerdashboard-certification {
  font-family: "Catamaran", sans-serif !important;
  font-size: 26px;
  color: #212121;
  font-weight: 600 !important;
  line-height: 39px;
  margin-top: 26px;
  margin-bottom: 30px;
}

.headerdashboard1 {
  font-family: "Catamaran", sans-serif !important;
  font-size: 20px !important;
  color: #212121;
  color: var(--headerText);
  font-weight: 600 !important;
  margin-top: 20px;
}

.myinvestment {
  font-family: "Catamaran", sans-serif;
  font-size: 24px;
  color: #212121;
  font-weight: 600;
  /* margin-top: 20px; */
}

.headerdashboard2 {
  font-family: "Catamaran", sans-serif;
  font-size: 26px;
  color: #212121;
  font-weight: 600;
  /* margin-bottom: 20px; */
  margin-top: 3px;
}

.buttonspace {
  margin-right: 10px;
}

.tablechangebutton {
  margin-top: 20px;
  margin-left: -90px;
}

.tablechangedeal {
  margin-top: 20px;
  margin-left: -350px;
}

.tablechangebutton2 {
  margin-left: -230px;
  margin-top: 20px;
}

.tablechangebutton3 {
  margin-left: -200px;
  margin-top: 20px;
}

.tablechangebutton1 {
  margin-top: 20px;
  margin-left: -350px;
}

.row1 {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 5px;
  margin-left: 5px;
}

.rowfile {
  height: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  top: 1rem;
  /* margin-right: 5px;
  margin-left: 5px; */
}

.rowreview {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 5px;
  margin-left: 35px;
  margin-bottom: 10px;
}

.rowreview1 {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: 10px;
}

.buttonsverified {
  display: flex;
  justify-content: flex-end;
  position: relative;
  bottom: 1rem;
}

.buttonsverified-file {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.row123 {
  border: 1px solid blue;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pool-box {
  /* border : 1px solid blue; */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.pool-box-text-container1 {
  margin-bottom: 90px;
  width: 300px;
}

.pool-box-text-container {
  margin-bottom: 20px;
  width: 300px;
}

.pool-box-icons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: -20px;
  padding: 10px;
}

.kyc-card__button-container {
  color: #212121;
  color: var(--tableText);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.kyc-card__button-container1 {
  color: #212121;
  color: var(--tableText);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 25px;
}

.upload-button-label {
  height: 100%;
  width: 100%;
  padding-top: 7px;
}

.upload-button-label1 {
  height: 100%;
  width: 100%;
  font-size: 18px;
  padding-top: 8px;
  background: #fff;
  background: var(--white);
  color: #212121;
  color: var(--tableText);
  border-radius: 9rem;
}

.kyc-card__select_name-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-left: 10px;
  font-size: 13px;
}

.selectclass {
  color: #adadad !important;
}

input[type="password"] {
  width: 100%;
}

input[type="text"] {
  width: 100%;
}

input[type="number"] {
  width: 100%;
}

.doller-input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.arrowtextclass {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.qibheader {
  margin-bottom: 20px;
}

.lockicondivcss {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

b {
  font-weight: 600;
  font-size: 18px;
  color: #212121;
}

.popup-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.popup-progress {
  height: 120px;
  width: 120px;
  margin: 30px 0;
}

.green-tick {
  height: 16px;
  width: 16px;
  margin-right: 10px;
}

.render-popup-3 {
  padding: 3px 8px;
  display: flex;
  flex-direction: column;
}

.private-key-btn {
  margin: 0 !important;
  width: 240px;
}

.popup-next-btn {
  width: 120px;
  align-self: flex-end;
  margin-top: 65px !important;
}

.invester-deal-details-top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.outer111 {
  padding-top: 52px;
}

.table_change-investor-buttons {
  margin-left: -250px;
}

.investor-heading-container {
  width: 400px;
}

.MuiAccordion-root:first-of-type {
  padding-top: 0px;
  padding-bottom: 0px;
  /* padding-left: px;
    padding-right: 20px; */
  border: 1px solid #212121;
  box-shadow: none !important;
  border-radius: 10px !important;
}

.headingpayment {
  font-family: "Catamaran", sans-serif;
  margin-top: 8px;
  margin-left: 28px;
  font-weight: 600 !important;
  font-size: 20px !important;
  color: #212121 !important;
}

.MuiAccordionSummary-root {
  margin-right: 29px !important;
  padding: 0 !important;
}

.MuiAccordionDetails-root {
  padding: 0 !important;
}

.css-ahj2mt-MuiTypography-root {
  font-weight: 600 !important;
  font-size: 20px !important;
  color: #212121 !important;
}

.bulletpoints1 {
  margin-left: 28px;
  margin-right: 20px;
  margin-top: 0px;
  line-height: 30px;
  font-weight: 400;
  font-size: 15px;
  color: #212121;
}

.bulletpoints11 {
  margin-left: 28px;
  margin-right: 20px;
  margin-top: 0px;
  line-height: 30px;
  font-weight: 400;
  font-size: 15px;
  color: #8c8c8c;
}

.bulletpoints11-black {
  margin-left: 28px;
  margin-right: 20px;
  margin-top: 0px;
  line-height: 30px;
  font-weight: 400;
  font-size: 15px;
  color: #212121;
}

.bulletpoints11-black-1 {
  /* margin-left: 28px; */
  margin-right: 20px;
  margin-top: 0px;
  line-height: 30px;
  font-weight: 400;
  font-size: 15px;
  color: #212121;
}

.bulletpoints1_container {
  border-top: thin solid #212121;
  padding-top: 20px;
  height: 210px;
  overflow-y: auto;
}

.bulletpoints1_container1 {
  border-top: thin solid #212121;
  padding-top: 20px;
  height: auto;
  overflow-y: auto;
}

/* .Mui-expanded{
  margin-right: 50px;
} */
.bulletpoints {
  margin-top: 0px;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #212121;
}

.abovepart1 {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  grid-gap: 2.2rem;
  gap: 2.2rem;
}

.part1 {
  flex: 8 1;
}

.part1 ul {
  border-top: thin solid #212121;
  padding-top: 20px;
}

.part1 ul li {
  margin-left: 40px;
  margin-right: 20px;
  padding-bottom: 10px;
}

.part1 ul li::marker {
  color: #8c8c8c;
}

.part2 {
  height: 100%;
  flex: 4 1;
}

.pieinnercontainer2 ul li:first-child::marker {
  color: #ffc000;
}

.pieinnercontainer2 ul li:nth-child(2)::marker {
  color: #169414;
}

.pieinnercontainer2 ul li:nth-child(3)::marker {
  color: #ae2e2e;
}

.pieinnercontainer2 ul li {
  padding-bottom: 10px;
}

.pieinnercontainerportfolio ul li:first-child::marker {
  color: #ffc000;
}

.pieinnercontainerportfolio ul li:nth-child(2)::marker {
  color: #169414;
}

.pieinnercontainerportfolio ul li:nth-child(3)::marker {
  color: #ae2e2e;
}

.pieinnercontainerportfolio ul li {
  padding-bottom: 10px;
}

.pieoutercontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editcontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editcontainer1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.pieoutercontainer1 {
  padding-left: 15px;
  padding-right: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pieoutercontainers {
  padding-left: 15px;
  padding-right: 25px;
}

.pieinnercontainer1 {
  flex: 1 1;
}

.pieinnercontainer1-details1 {
  margin-left: -10px;
  margin-top: -80px;
  flex: 1 1;
}

.pieinnercontainer2 {
  flex: 2 1;
}

.pieinnercontainerportfolio {
  flex: 2 1;
}

.popover-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 18px !important;
  padding-left: 15px !important;
  padding-bottom: 18px !important;
  padding-right: 46px !important;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.popover-container button {
  border: none;
  outline: none;
  background: none;
  width: 100%;
  text-align: start;
}

.popover-hr {
  height: 1px;
  background: #e0e0e0;
  width: 100%;
}

.popover-va {
  border: 1px solid #212121 !important;
}

.moververticon {
  color: #018e82;
}

.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  margin: 0px !important;
  padding: 0px !important;
  box-shadow: none !important;
  /* border: 1px solid #212121 !important; */
  /* border-radius: 10px !important; */
}

.css-17ffvgn-MuiTypography-root {
  padding: 0px !important;
}

.MuiButton-root.Mui-disabled {
  background-color: #ffc000 !important;
  opacity: 0.5 !important;
  color: #000 !important;
}

.MuiButton-root.Mui-disabled.disabled-metamask-button {
  background-color: #dee2e6 !important;
  opacity: 0.5 !important;
  color: #000 !important;
}

.pending-pool-popup-card {
  border: 1px solid #212121;
  border-radius: 10px;
  padding: 18px 15px;
  width: 528px;
  margin-bottom: 28px;
}

.pending-pool-popup-card-top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-card-top-container-first p {
  padding: 0;
  margin: 0;
}

.popup-card-top-container-second {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalPopupPending11 > .ReactModal__Content--after-open {
  width: 584px !important;
}

.pending-pool-popup-card-bottom-container {
  display: flex;
  align-items: center;
}

.popup-card-bottom-container-first {
  flex: 3 1;
}

.popup-card-bottom-container-second {
  flex: 3 1;
}

.popup-card-bottom-container-first p,
.popup-card-bottom-container-second p {
  margin: 0;
  padding: 0;
}

.popup-card-bottom-container-first-invest {
  flex: 3 1;
  margin-top: -30px;
  margin-bottom: 23px;
}

.popup-card-bottom-container-second-invest {
  flex: 3 1;
  margin-top: -30px;
  margin-bottom: 23px;
}

/* .popup-card-bottom-container-first-invest p,
.popup-card-bottom-container-second-invest p {
  margin: 0;
  padding: 0;
} */

.first-inner-container {
  margin-top: 15px;
}

.va-popup-button-close-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 43px;
  margin-bottom: 28px;
}

.va-popup-button-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.va-popup-inner-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.8rem;
  gap: 0.8rem;
}

.va-actice_button-key {
  border: none;
  outline: none;
  background-color: #018e82;
  border-radius: 50%;
  padding: 2px 10px;
  color: #ffffff;
}

.va-unactive-button-key {
  border: none;
  outline: none;
  background-color: transparent;
  color: #8c8c8c;
}

.va-actice_button-key-2 {
  border: none;
  outline: none;
  background-color: #018e82;
  border-radius: 50%;
  padding: 2px 6px;
  color: #ffffff;
}

.va-popup-arrow-button {
  border: none;
  outline: none;
  background-color: transparent;
  color: #018e82;
}

.va-popup-arrow-button-left {
  font-size: 1rem !important;
  border: none;
  outline: none;
  background-color: transparent;
  color: #018e82;
}

.va-popup-arrow-button-right {
  border: none;
  outline: none;
  background-color: transparent;
  color: #018e82;
}

.va-popup-close-btn {
  border: 1.2px solid #212121;
  padding: 6px 30px;
  color: #212121;
  background-color: transparent;
  border-radius: 20px;
  cursor: pointer;
}

.va-popup-btn-accept {
  margin: 0 !important;
  margin-left: 10px !important;
}

.Mui-selected {
  background-color: #018e82 !important;
}

.buttoncss {
  border: 1.2px solid #212121 !important;
  border-radius: 20px !important;
  color: #212121 !important;
  box-shadow: none !important;
  text-transform: none !important;
  background-color: #ffc000 !important;
  margin-left: 10px !important;
  padding: 5px 30px !important;
  opacity: 0.5 !important;
}

.futureof {
  padding-bottom: 30px;
}

.css-19micn4-MuiButtonBase-root-MuiPaginationItem-root {
  height: 25px !important;
  min-width: 25px !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #018e82 !important;
}

.kycformwidth {
  max-width: 355px !important;
}

.kyc-content2 {
  max-width: 370px;
}

.kyc-content2-certification {
  max-width: 410px;
}

.tick-icon {
  color: #19a337;
  margin-right: 3px;
}

.cross-icon {
  color: #c00b0b;
  margin-right: 3px;
}

.tick-icon1 {
  font-size: 2.2rem !important;
  color: #19a337;
  margin: 35px 28px 0px 38px;
}

.cross-icon1 {
  font-size: 2.2rem !important;
  color: #c00b0b;
  margin: 35px 28px 0px 38px;
}

.css-pwcg7p-MuiCollapse-root {
  background-color: #fafafa;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.buttonchartchanges {
  margin-top: 20px;
  border: 1.2px solid #212121;
  border-radius: 2px;
  margin-left: 18px;
}

.reorder-chart {
  color: #8c8c8c;
  background: #fff;
  padding: 4px;
}

.reorder-chart-active {
  color: #212121;
  background: #ffc000;
  padding: 4px;
}

.barchart-chart-active {
  background: #ffc000;
  color: #212121;
  padding: 4px;
}

.barchart-chart {
  color: #8c8c8c;
  background: #fff;
  padding: 4px;
}

.reordersize {
  font-size: 1.7rem !important;
}

.op-main-main-container {
  display: flex;
  /* justify-content: space-between; */
  /* align-items: center;  */
  flex-wrap: wrap;
  grid-gap: 2.5rem;
  gap: 2.5rem;
  max-width: 100vw;
}

.op-card-outer-container {
  padding: 15px 15px 0px 15px;
}

.op-card-first-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.op-card-first-container-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.op-card-first-container-left-logo-container {
  border: 2px solid #d9d9d9;
  padding: 5px;
  border-radius: 50%;
  margin-right: 10px;
}

.op-card-logo {
  border-radius: 50%;
  height: 64px;
}

.op-card-wellslogo {
  border-radius: 50%;
  height: 64px;
  width: 65px;
}

.wellslogo {
  height: 50px;
  width: 60px;
  padding: 12px 3px 0px 7px;
}

.op-card-first-container-left-text-container-first-para {
  margin-bottom: 0rem !important;
  margin-top: 10px;
  font-family: "Mulish";
  font-size: 13px;
  color: #212121;
  font-weight: 400;
}

.op-card-first-container-left-text-container-second-para {
  font-family: "Mulish";
  font-weight: 400;
  font-size: 21px;
  color: #212121;
  margin-top: 10px;
}

.op-card-first-container-right-para {
  margin-top: -25px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: right;
  color: #ae2e2e;
}

.op-second-container-paragraph {
  margin-top: 15px;
}

.op-second-container-paragraph p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #212121;
  text-align: left;
  width: 100%;
}

.op-third-container {
  margin-top: 15px;
}

.op-third-container-first-para {
  margin-bottom: 0.4rem !important;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  color: #212121;
}

.op-third-container-second-para {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #212121;
}

.op-range-container {
  background: #fafafa;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top: 1.2px solid #212121;
  margin-top: 15px;
}

.op-range-container-first-container {
  background-color: #ffc000;
  border-bottom-left-radius: 10px;
}

.op-range-container-first-container p {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 15px;
}

.areachart-graph {
  margin-right: 55px !important;
  margin-top: -20px !important;
  margin-bottom: 40px !important;
  margin-left: 15px !important;
}

.metrics-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 35px;
  gap: 35px;
}

.shiftforverification {
  margin-top: 55px;
}

.uw-search-button-container {
  width: auto;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  border-radius: 60px;
  background-color: #ffc000;
  border: 2px solid #000;
}

.uw-search-button-container-creation {
  width: auto;
}
.uw-deal-details-button-container button {
  margin-left: 15px;
  position: relative;
  top: 0.5rem;
  border: 1.5px solid rgba(33, 33, 33, 1) !important;
  border: 1.5px solid var(--tableBorder) !important;
  color: #212121;
  color: var(--tableText);
}
/* If primary color is set */

/* .metrics-flex li{
  margin-right: 20px;
 } */
/* @media (-webkit-min-device-pixel-ratio: ){
  .op-main-container{
    width: 630px !important;
    min-width: 630px !important;
    max-width: 630px !important;
  }
 }
 @media (-webkit-min-device-pixel-ratio: 0.80){
  .op-main-container{
    width: 550px !important;
    min-width: 550px !important;
    max-width: 550px !important;
  }
 } */
.content_pdfconentss {
  /* border: 1px solid red; */
  width: 500px;
  display: flex;
  justify-content: space-between;
  padding: 15px 30px;
}

button {
  border: none;
  outline: none;
  background-color: transparent;
}

.popover-container {
  border: 1px solid #212121;
  width: 120px;
  align-items: flex-start;
  padding: 0 !important;
}

.bs-popover-bottom > .arrow::before {
  top: -7.5rem;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #212121;
}

.popover-container button {
  text-align: left;
  padding: 5px 15px;
  color: #018e82;
}

.tool-main-container {
  border: 1px solid #212121;
  margin: 0 50px;
  padding: 10px 0;
  border-radius: 15px;
}

.tool-template-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  grid-gap: 2em;
  gap: 2em;
  padding: 20px 40px;
}

.tool-button {
  border: 1.2px solid #212121;
  border-radius: 20px;
  padding: 5px 20px;
}

.servicer_poolDetails-btn {
  color: #212121 !important;
  margin-right: 20px !important;
}

.servicer-modal-acc {
  margin-bottom: 30px;
}

.headingpayment1 {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  padding: 0 28px !important;
}

.servicer-popup-inner-container {
  padding: 23px 28px;
  /* border-top: 1.2px solid #212121; */
}

.card__button-servicer {
  border: 1.2px solid #212121;
  border-radius: 50px;
  padding: 0px 15px;
}

.card__button-servicer-level {
  font-size: 15px;
}

.servicer-popup-bottom-container {
  background-color: transparent;
  border-top: 1px solid #212121;
}

.servicer-popup-upload-btn-contianer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.servicer-set-up-popup-option-container {
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.servicer-set-up-popup-text-container {
  width: 100%;
}

.servicer-set-up-popup-text-container-para {
  font-size: 13px;
  padding-top: 6px !important;
  margin-bottom: 12px !important;
}

.servicer-set-up-popup-text-container-heading {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  margin-bottom: 0 !important;
}

.servicer-set-up-popup-text-container-heading-unchecked {
  opacity: 0.5;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  margin-bottom: 0 !important;
}

.servicer-set-up-popup-text-container-para-unchecked {
  opacity: 0.5;
  font-size: 13px;
  padding-top: 6px !important;
  margin-bottom: 12px !important;
}

.servicer-set-up-popup-radio-tag {
  border: 1px solid rgb(255, 255, 255) !important;
  outline: #212121 !important;
}

.servicer-popup-inner-container-heading {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
}

.servicer_deal_details-top_heading_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.headingpaymentAmount {
  font-family: "Catamaran", sans-serif !important;
  font-size: 18px;
  line-height: 30px;
  font-style: normal;
  font-weight: 600;
  color: #212121;
  margin-top: 5px;
  margin-right: 28px;
}

.payment-rules-li-elements {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: flex-start;
}

.payment-rules-li-elements p {
  margin-bottom: 5px !important;
}

.payment-rules-li-elements-para {
  text-align: start;
  width: 112px;
  margin-right: 80px;
}

.servicer-detail-pool-activity-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 12px;
}

.servicer-detail-pool-activity-first-container {
  flex: 2 1;
  display: flex;
  flex-direction: column;
  grid-gap: 28px;
  gap: 28px;
}

.servicer-detail-pool-activity-first-top-container {
  background: #fff;
  background: var(--white);
  padding-top: 6px;
  padding-bottom: 10px;
  padding-left: 28px;
  /* padding-right: 20px; */
  border: 1px solid #212121;
  border-radius: 10px;
  margin-right: 20px;
  height: 186px;
}

.servicer-detail-pool-activity-first-top-container p {
  margin: 0;
  padding: 0;
}

.servicer-detail-pool-activity-first-bottom-container {
  background: #fff;
  background: var(--white);
  padding-top: 6px;
  padding-bottom: 10px;
  padding-left: 28px;
  border: 1px solid #212121;
  border-radius: 10px;
  margin-right: 20px;
  height: 186px;
}

.servicer-detail-pool-activity-first-bottom-container p {
  margin: 0;
  padding: 0;
}

.outer-servicer-detail-pool-activity-second-container {
  padding-left: 14px;
  flex: 4 1;
  width: 100%;
}

.servicer-card-heading {
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #212121;
  margin-top: 18px;
}

.servicer-detail-pool-activity-second-container {
  border: 1px solid #212121;
  border-radius: 10px;
  height: 400px;
  overflow-y: scroll;
}

.servicer-detail-pool-activity-second-container table {
  width: 100%;
}

.servicer-detail-pool-activity-second-container table tr {
  height: 48px;
}

.servicer-detail-pool-activity-second-container table tr th {
  padding-left: 30px;
  padding-right: 30px;
}

.servicer-detail-pool-activity-second-container table tr td {
  padding-left: 30px;
  padding-right: 30px;
}

.servicer-detail-pool-activity-second-container table tr:nth-child(odd) {
  background: #fafafa;
}

.servicer-pool-activity-table-heading {
  background-color: #018e821a;
  font-family: "Mulish", "sans-serif" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #212121;
  padding-top: 10px;
}

.container-full-width-1 {
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  grid-gap: 170px;
  gap: 170px;
}

.container-full-width-2 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 15px;
  grid-gap: 170px;
  gap: 170px;
}

.servicer-card-inner-heading {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #212121;
}

.servicer-card-inner-red-text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  color: #ae2e2e;
}

.servicer-popover-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 18px !important;
  padding-left: 15px !important;
  padding-bottom: 18px !important;
  padding-right: 46px !important;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  width: 180px;
  border: 1px solid rgba(33, 33, 33, 1);
  border: 1px solid var(--tableBorder);
  background-color: #fff;
  background-color: var(--white);
}

.header-popover-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 18px !important;
  padding-left: 15px !important;
  padding-bottom: 18px !important;
  padding-right: 46px !important;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  width: 200px;
  border: 1px solid #212121;
}

.bs-popover-bottom > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #212121;
}

/* .bs-popover-bottom> .arrow {
  top:0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #212121;
} */

.servicer-popover-container button {
  border: none;
  outline: none;
  background: none;
  width: 100%;
  text-align: start;
  color: #018e82;
}

.header-popover-container button {
  border: none;
  outline: none;
  background: none;
  width: 100%;
  text-align: start;
  color: #018e82;
}

.servicer-popover-hr {
  height: 1px;
  background: #e0e0e0;
  width: 150%;
  margin-left: 30px;
}

.header-popover-hr {
  height: 1px;
  background: #e0e0e0;
  width: 145%;
  margin-left: 32px;
}

.servicer-data-main-container {
  display: flex;
  justify-content: space-between;
  grid-gap: 28px;
  gap: 28px;
}

.servicer-data-left-container {
  flex: 3 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 1;
  color: #212121;
  color: var(--tableText);
}

.servicer-data-right-container {
  flex: 2 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 1;
}

.outer-servicer-data-table-container {
  width: 100%;
  margin-bottom: 28px;
}

.servicer-data-table-container {
  border: 1px solid rgba(33, 33, 33, 1);
  border: 1px solid var(--tableBorder);
  overflow-y: auto;
  border-radius: 10px;
}

.servicer-data-table-container table {
  width: 100%;
}

.servicer-data-table-container table tr {
  height: 48px;
}

.servicer-data-table-container table tr th {
  padding-left: 30px;
  padding-right: 30px;
}

.servicer-data-table-container table tr td {
  padding-left: 30px;
  padding-right: 30px;
  font-family: "Mulish", "sans-serif" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #212121;
  color: var(--tableText);
}

.servicer-data-table-heading {
  background-color: rgba(1, 142, 130, 1);
  font-family: "Mulish", "sans-serif" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #212121;
  padding-top: 9px;
  padding-bottom: 9px;
}

.servicer-data-table-container table tr:nth-child(odd) {
  background: #fafafa;
}

.servicer-data-table-row:nth-child(odd) {
  background-color: rgba(255, 255, 255, 1);
  background-color: var(--tdOdd);
}

.servicer-data-table-row:nth-child(even) {
  background-color: var(--evenrow);
}

.servicer-data-table-row {
  font-family: "Mulish", "sans-serif" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #212121;
  color: var(--text_Color);
  padding: 9px 0;
}

/* Common styling for table rows */
.servicer-data-table-container table tr {
  height: 48px;
}

/* Alternate row styling for each table */

/* First table */
.servicer-first-table table tr:nth-child(odd) {
  background: #fff;
  background: var(--white); /* Light grey */
  color: #212121;
  color: var(--tableText);
}
.servicer-first-table table tr:nth-child(even) {
  background: rgb(229, 229, 229, 0.3);
  background: var(--evenRow); /* Slightly darker grey */
  color: #212121;
  color: var(--tableText);
}

/* Second table */
.servicer-second-table table tr:nth-child(odd) {
  background: #fff;
  background: var(--white); /* Light blue */
  color: #212121;
  color: var(--tableText);
}
.servicer-second-table table tr:nth-child(even) {
  background: rgb(229, 229, 229, 0.3);
  background: var(--evenRow); /* Slightly darker blue */
  color: #212121;
  color: var(--tableText);
}

/* Third table */
.servicer-third-table table tr:nth-child(odd) {
  background: #fff;
  background: var(--white); /* Light orange */
  color: #212121;
  color: var(--tableText);
}
.servicer-third-table table tr:nth-child(even) {
  background: rgb(229, 229, 229, 0.3);
  background: var(--evenRow); /* Slightly darker orange */
  color: #212121;
  color: var(--tableText);
}

/* Fourth table */
.servicer-forth-table table tr:nth-child(odd) {
  background: #fff;
  background: var(--white); /* Light pink */
  color: #212121;
  color: var(--tableText);
}
.servicer-forth-table table tr:nth-child(even) {
  background: rgb(229, 229, 229, 0.3);
  background: var(--evenRow); /* Slightly darker pink */
  color: #212121;
  color: var(--tableText);
}

.table-servicer th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #fff !important;
  background-color: var(--white) !important;
  color: #212121;
  color: var(--text_Color);
}

.table-servicer th::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(229, 243, 242, 1) !important;
  background-color: var(--table-header-bg) !important;
  /* Ensure the background color is applied */
  z-index: -1;
  /* Position the pseudo-element behind the header */
}

.servicer-first-table {
  height: 725px;
}

.servicer-second-table {
  height: 200px;
}

.servicer-third-table {
  height: 485px;
}

.servicer-forth-table {
  height: 630px;
}

#mapCamp .line {
  padding: 8px;
  margin: 10px 20px 10px 0px;
  text-align: left;
  border-bottom: 1px solid #212121;
}

#mapCamp #requested_table {
  float: right !important;
  width: 300px;
  border: 5px solid #ccc;
  height: 350px;
  position: fixed;
  overflow: auto;
  right: 50px;
  background-color: #f2fefe;
  /* bottom:50px; */

  /* position: -webkit-sticky;
  position: sticky;
  top: 0; */
}

#mapCamp #requested_table div[data-rbd-droppable-context-id="1"] {
  height: 100%;
}

#mapCamp .dynamic_table {
  float: left;
  width: 100%;
  /* padding-right: 400px !important; */
  /* background-color: #F2FEFE ; */
  padding: 0px;
  margin: 0px;
  min-height: 60px;
  border-bottom: 1px solid #ccc;
}

#mapCamp .dynamic_table .line {
  border-bottom: none;
}

#complexMap {
  margin-top: 20px;
}

.leftcolumn {
  /* width:32%;
  float: left; */
}

.centrecolumn {
  /* width: 65%; */
  /* width:32%;
  float: right; */
  /* float: right; width: 65%; padding-right: 400px;padding-left:50px; position: relative; */
}

.middiv {
  /* float: left;
width:50%;
min-height:84px ; */
  position: relative;
}

.description_text {
  text-align: left;
  line-height: 21px;
  padding: 15px 0 0 0;
}

ul.suggestions {
  position: absolute;
  left: 0px;
  top: 40px;
  z-index: 100;
  background: #fff;
  box-shadow: 0px 5px 5px #ccc;
  width: 100%;
  max-width: 250px;
}

ul.suggestions li {
  display: block;
  padding: 5px 15px;
  border-bottom: 1px solid #ccc;
  text-align: left;
}

ul.suggestions li:hover {
  background-color: #e2eaea;
}

#mapCamp {
  height: 700px;
  /* overflow-y: scroll;
  overflow-x: hidden; */
}

#mapCamp > .mapcomptable {
  /* padding-top: 10px;
    padding-bottom: 10px;  */
  border-bottom: 1px solid #ccc;
  background: #fff;
}

#mapCamp > .mapcomptable:nth-child(odd) {
  background: #f0f7f7;
}

/* .Exclud{
  height:600px;
  overflow-y: scroll;
} */
/* .firstdiv{ margin-bottom: 15px;} */
select.FForm-control.x:not([size]):not([multiple]) {
  height: calc(2.25rem + 5px);
  margin-top: 20px !important;
}

td .FForm-control {
  width: 100%;
  max-width: 250px;
}

.FForm-control {
  display: block;
  width: 100%;
  max-width: 400px;
  padding: 0.705rem 0.75rem;
  color: #212121;
  outline: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #212121;
  border-radius: 8px !important;
  font-family: "Mulish", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.FForm-control::-webkit-search-cancel-button {
  -webkit-appearance: none;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 10px;
  background: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 43%,
      #000 45%,
      #000 55%,
      rgba(0, 0, 0, 0) 57%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(
      135deg,
      transparent 0%,
      transparent 43%,
      #000 45%,
      #000 55%,
      transparent 57%,
      transparent 100%
    );
}

.FForm-control:focus {
  border-color: none !important;
  /* color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);*/
}

input {
  background-color: #fff;
  background-color: var(--white);
  color: #212121;
  color: var(--tableText);
  border: 1px solid rgba(33, 33, 33, 1);
  border: 1px solid var(--tableBorder);
}

input.FForm-control {
  box-shadow: 0px 1px 1px #f5f5f5;
  border-radius: 10px;
}

.MapHeading {
  background-color: #d1f4f0 !important;
  border-top: 1px solid #ccc;
  padding: 6px 6px;
}

.headingmap {
  text-align: left;
  font-weight: 700;
  /* font-style:italic; */
  margin-left: 15px;
}

.headingmapdescp {
  text-align: left;
  /* font-style:italic; */
  font-weight: 700;
  /* font-weight: bold; */
}

/* #browseexcel2{
  margin: 25px 0px;
  text-align: center;
} */
.boxinp {
  border-radius: 10px;
}

.ExtraHeader {
  background: #d1f4f0;
  padding: 0px 15px 15px 15px;
  margin: 0px;
}

.ExtraHeader form {
  height: 85px;
}

.ExtraHeader .col-md-12 {
  height: 85px;
}

.parahStdFields {
  font-size: 16px;
  padding: 7px 7px;

  border: 1px solid #8bb0ac;
}

.SearchSTD {
  margin-top: 20px;
  margin-bottom: 30px;
}

.page-content-setup {
  margin-bottom: 50px !important;
}

.page-content-setup {
  background: #fff;
  background: var(--white);
  padding: 0px;
  padding-bottom: 15px;
  box-shadow: 0px 3px 3px #ccc;
  margin: 20px;
  border-radius: 10px;
}

.MatchMis {
  vertical-align: middle;
  padding: 14px;
}

#Addstandardform .tabledata.col-md-4 {
  flex: 0 0 100%;
  max-width: 100%;
}

.mapcomptable tbody tr td {
  /* border-top: 1px solid #D2D3D3; */
  font-size: 14px;
  text-align: left;
  padding-left: 23px;
  font-family: "Mulish", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #212121;
}

.mapcomptable th {
  padding: 0.75rem;
  vertical-align: top;
  text-align: left;
  padding-left: 23px;
  padding-top: 9px;
  padding-bottom: 9px;
  /* border: 1px solid #D2D3D3; */
  border-left: none;
  border-right: none;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  color: #212121;
}

.mapcomptable th:nth-child(2) {
  padding-left: 40px;
}

#unmatchedtable tbody tr {
  background-color: #fafafa;
  height: 62px;
}

#unmatchedtable tbody tr:nth-child(odd) {
  background: #fff;
}

#matchedtable tbody tr {
  height: 62px;
  background-color: #fafafa;
}

#matchedtable tbody tr:nth-child(odd) {
  background: #fff;
}

#mapCamp {
  background-color: #fff;
}

#mapCamp .card {
  /* margin-top: 30px; */
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #212121;
  border-radius: 10px;
}

/* .lefttable{
  height: 500px;
  overflow: auto;
} */

.tablesearch {
  position: absolute;
  right: 5px;
}

.splittablehead {
  margin-top: 10px;
  margin-left: 0px;
}

.card p {
  margin-top: 7px;
  margin-left: -1px;
}

.headcount {
  margin-left: 5px;
  margin-top: 20px;
  margin-bottom: 15px;
}

.headcount h3 {
  font-family: "Catamaran", sans-serif !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 33px;
}

.tablehead-data tr {
  background: rgba(1, 142, 130, 0.1);
}

.button-tick-css {
  color: #ffc000;
  margin-left: 5px;
}

.button-tick-size {
  font-size: 2rem !important;
}

.render-popup-2 {
  padding: 0 22px;
}

.render-popup-2-inner-top-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 23px;
}

.render-popup-radio-outer-container {
  border: 1.2px solid #212121;
  border-radius: 20px;
  background-color: #ffc000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 20px;
}

.render-popup-radio-outer-container-2 {
  border: 1.2px solid #212121;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 21px;
  height: 20px;
}

.render-popup-radio-inner-container-2 {
  border-radius: 50%;
  background: #ffc000;
  height: 11px;
  width: 11px;
}

.render-popup-2-inner-bottom-container {
  display: flex;
  justify-content: space-between;
}

.render-popup-2-button-container {
  padding: 0 28px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 23px;
}

.render-popup-2-button-container span {
  font-weight: bold;
}

.render-popup-2-button-container button {
  margin-top: 0;
}

.render-popup-text-container {
  margin-left: 6px;
}

.render-popup-text-container p {
  padding: 0;
  margin: 0;
}

.popup-vertical-line {
  height: 77px;
  width: 1.2px;
  background: #212121;
  margin-top: -77px;
  margin-left: 9px;
}

.done-icon {
  font-size: 1rem !important;
}

.render-popup-small-text {
  font-family: "Mulish", sans-serif !important;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: #212121;
}

.popup-vertical-line-1 {
  height: 100px;
  width: 1.2px;
  background: #212121;
  margin-top: -105px;
  margin-left: 9px;
}

.popup-vertical-line-2 {
  height: 120px;
  width: 1.2px;
  background: #212121;
  margin-top: -100px;
  margin-left: 9px;
}

.render-popup-3-radio-outer-container {
  border: 1.2px solid #212121;
  border-radius: 50%;
  background-color: #ffc000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 27px;
  height: 20px;
}

.render-popup-3-radio-outer-container-2 {
  border: 1.2px solid #212121;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 27px;
  height: 20px;
}

.grey-text {
  opacity: 0.5;
}

.disabled-input {
  border: 1px solid #7d8887;
}

.status-pending {
  margin-top: 30px;
}

.stepText {
  line-height: 27px;
  font-family: "Catamaran", sans-serif !important;
  font-weight: 600 !important;
  font-size: 18px;
  color: #212121;
}

.stepText-data {
  font-family: "Mulish", sans-serif !important;
  font-weight: 600;
  font-size: 15px;
  color: #212121;
  line-height: 19px;
  margin-bottom: 4px !important;
  margin-top: 2px !important;
}

button.userinfo-currentuser {
  color: #048c88;
  margin-top: 10px;
  margin-left: -25px;
}

.payment-page-main-container {
  display: flex;
  justify-content: space-between;
  padding: 28px 50px 28px 40px;
}

.payment-page-main-container input {
  width: 540px;
}

.payment-submit-button {
  align-items: center;
}

.button-disabled {
  opacity: 0.5;
}

.popupdashboard-servicer-button-disabled {
  opacity: 0.5;
  width: 15rem;
  font-size: 16px;
}

.status-pending-text {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  /* margin-left: 170px; */
}

.setup-popup-card {
  border: 1px solid #212121;
  border-radius: 10px;
  padding: 18px 20px 18px 20px;
  width: 435px;
  height: 300px;
  overflow-y: auto;
  margin-bottom: 15px;
}

.setup-popup-card-title {
  font-family: "Catamaran", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #212121;
  margin-bottom: 8px !important;
}

.setup-popup-p {
  margin-top: 0px !important;
  font-family: "Mulish", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #212121;
  margin-bottom: 2px !important;
}

.setup-inner-container {
  margin-top: 15px;
}

.setup-popup-p-icon {
  display: flex;
}

.setup-popup-p-icon p {
  margin-top: 0px !important;
  font-family: "Mulish", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #212121;
  margin-bottom: 0px !important;
}

.contentcopyicon {
  color: #018e82;
  margin-left: 10px;
  font-size: 1.5rem !important;
}

.copiedcss {
  color: #212121;
  align-items: center;
  margin-left: 220px;
}

.loreamipsum {
  margin-bottom: 10px !important;
}

.popup-heading1 {
  margin-top: 20px;
  margin-bottom: 0.4rem !important;
  font-family: "Mulish", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: #212121;
}

.ps_page-main-container {
  padding: 38px !important;
}

.ps-main-heading {
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 39px;
  color: #212121;
}

.ps_page-left-container {
  width: 100%;
  flex: 1 1;
}

.ps_page-in-out-main-container {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  grid-gap: 28px;
  gap: 28px;
  margin: 15px;
}

.ps_outer-container {
  background: #ffffff;
  border: 1px solid #212121;
  border-radius: 10px;
  margin-bottom: 28px;
}

.ps_outer-container p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #212121;
}

.ps_outer-container-account {
  background: #ffffff;
  border: 1px solid #212121;
  border-radius: 10px;
  margin-bottom: 28px;
  margin-right: 20px;
}

.ps_outer-container-account p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #212121;
}

.ps_inner-heading-container {
  padding: 15px 28px;
  border-bottom: 1px solid #212121;
}

.ps_inner-heading-container-account {
  border-bottom: 1px solid #212121;
}

.ps_inner-heading-container h3 {
  font-family: "Catamaran";
  font-weight: 600;
  font-size: 20px;
  color: #212121;
}

.ps_inner-heading-container-account h3 {
  font-family: "Catamaran";
  font-weight: 600;
  font-size: 20px;
  color: #212121;
  padding: 15px 30px 10px 30px !important;
}

.ps-inner-bottom-contianer {
  background: #fafafa;
  padding: 23px 28px;
  border-radius: 0 0 10px 10px;
}

.ps-inner-bottom-contianer-account {
  background: #fafafa;
  padding: 23px 28px;
  border-radius: 0 0 10px 10px;
}

.pay-ins-radio-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 59px;
  gap: 59px;
  width: 25%;
}

.ps-inner-bottom-contianer .input-container input {
  width: 60%;
}

.ps-inner-bottom-contianer-account .input-container input {
  width: 100%;
}

.wallet-btn-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ps_page-right-container {
  flex: 1 1;
  width: 100%;
}

.processing-time-bold {
  font-size: 15px !important;
}

.shift-data-important {
  margin-left: 20px;
}

.shift-data-important-css {
  margin-top: 15px;
  margin-left: 20px;
}

.outer-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.outer-register1__title-container {
  width: 100% !important;
}

.outer-kycformwidth {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* Paying agent popup */

.pa_popup-main-heading {
  font-family: "Catamaran";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #212121;
}

.pa_popup-inner-heading {
  font-family: "Catamaran", sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 30px !important;
  color: #212121;
}

.pa_popup-sngle-row-container {
  display: flex;
  justify-content: space-between;
  grid-gap: 30px;
  gap: 30px;
}

.pa_popup-payment-rule-container {
  flex: 4 1;
}

.pa_popup-amount-container {
  flex: 1.5 1;
}

.pa_pop-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 32px;
}

.accept_pop-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 53px;
  margin-bottom: 32px;
}

.pa_popup-reject-btn {
  margin-top: 15px;
  margin-right: 15px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  text-align: right;
  color: #018e82;
}

.pa_popup-payment-rule-container p,
.pa_popup-amount-container p {
  font-family: "Mulish", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #212121;
}

.graphs-container {
  border: 1px solid #212121;
  border-radius: 10px;
  margin-top: 25px;
  margin-left: 0px;
  margin-right: 5px;
}

.bdb-charts {
  margin: 10px;
  border: none !important;
}

.color-text {
  color: #018e82;
  margin-top: 0;
  padding-top: 0;
}

.flex-container1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}

.flex-container1-account {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
}

.small-card {
  height: 120px;
  margin-top: 15px;
  margin-bottom: 23px;
}

.type-disc {
  list-style-type: none;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.circle {
  height: 11px;
  width: 11px;
  border: 1px solid #212121;
  border-radius: 50%;
  margin-right: 10px;
  z-index: 111111;
  background-color: white;
}

/* .render-popup-text-container{
  border: 1px solid red;
} */

.flex-container-2 {
  margin-left: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.hr-line {
  height: 100px;
  margin-top: 15px;
  border-right: 1px solid #212121;
}

.all-details-container {
  margin-left: -26px !important;
}

.small-container {
  width: 50%;
}

.circle-1 {
  height: 14px;
  width: 14px;
  border: 1px solid #212121;
  border-radius: 50%;
  margin-right: 10px;
  z-index: 111111;
  background-color: white;
}

.circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 3px;
  gap: 3px;
  margin-top: -8px;
}

.flex-container3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inner-circle1 {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin-right: 10px;
  z-index: 111111;
  background-color: #ffc000;
  margin-left: 2px;
  margin-top: 2px;
}

.ver-line {
  border-bottom: 1px solid #212121;
  width: 90px;
}

.mid-line {
  border-right: 1px solid #212121;
  height: 15px;
  opacity: 0.4;
}

.popup-heading1 {
  margin-bottom: 0;
}

.container-1 {
  margin-left: 0;
}

.lidatashift {
  margin-bottom: 10px;
}

.popup-heading1 {
  margin-bottom: 0;
}

.container-1 {
  margin-left: 0;
}

.t-flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: flex-start;
}

.t-flex-container .td-first {
  flex: 1 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.t-flex-container .td-second {
  flex: 1.5 1;
}

.t-text-color-green {
  color: #018e82;
}

.t-text-color-red {
  color: #ae2e2e;
}

.t-name {
  margin-top: -3px;
  margin-left: 5px;
}

.t-select {
  height: 20px;
  width: 20px;
}

.t-select:checked {
  accent-color: #ffc000;
}

.t-select-checbox {
  height: 20px;
  width: 20px;
  margin-left: 15px;
  margin-top: 15px;
  margin-right: 10px;
}

.t-select-checbox:checked {
  accent-color: #ffc000;
}

.success-invest {
  margin-top: 22px;
}

.success-text {
  font-size: 22px !important;
}

.rowdealsummary {
  display: flex;
}

.wellslogodeal {
  height: 67px;
  width: 93px;
  padding: 13px 20px 0px 20px;
}

/* --------------------notification css-------------------- */
.header-notification-container {
  width: 400px !important;
  max-width: 400px !important;
  border: 1px solid #212121;
}

.header-notification {
  height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.horizontal-divider-notification {
  margin-top: 12px;
  margin-bottom: 15px;
  background-color: #d3d3d3;
  height: 1px;
  width: 100%;
}

.pool-tag {
  font-family: "Mulish", sans-serif !important;
  margin: 20px 23px 8px 23px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  color: #212121;
}

.time-tag {
  margin-left: 23px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: flex-start;
  font-family: "Mulish", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #8c8c8c;
}

.button-tag {
  text-align: start;
  width: 135px;
  font-family: "Mulish", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  color: #018e82;
}

.ul-notifications {
  margin-bottom: 0rem !important;
}

.main-notification ul li::marker {
  color: #ae2e2e;
}

.no-notification ul li::marker {
  color: white;
}

.MuiBadge-badge {
  background-color: #ae2e2e !important;
}

.hellocardsave {
  margin-right: 10px;
}

.ps_page-left-container1 {
  width: 50%;
}

.paymentsetting {
  background-color: white;
}

.issueftshift {
  margin-left: 25px;
}

.justify-content-servicersave {
  justify-content: flex-end !important;
  margin-top: -30px;
  margin-left: 450px;
}

.justify-content-payingagentsave {
  justify-content: flex-end !important;
  margin-top: -20px;
  margin-left: 750px;
}

.MuiSwitch-colorSecondary.Mui-checked {
  color: #ffc000 !important;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #ffc000 !important;
}

.MuiSwitch-root {
  margin-top: -10px !important;
}

#viewServicerData2 {
  margin-top: 20px;
}

#viewServicerData2 td {
  width: 15%;
}

#viewServicerData2 td:nth-child(4) {
  width: 23%;
}

#viewServicerData2 td:nth-child(2) {
  width: 8%;
}

/* #viewServicerData2 td input {
  background-color: transparent;
}
#viewServicerData2 td textarea {
  background-color: transparent;
}
#viewServicerData2 td select {
  background-color: transparent;
} */
.tablehead {
  background-color: rgba(1, 142, 130, 0.1) !important;
  font-size: 14px;
  letter-spacing: 0.02rem;
  font-family: normal normal 600 14px/17px Proxima Nova;
}

#viewServicerData2 tr {
  /* padding-top: 10px;
   padding-bottom: 10px;  */
  /* border-bottom: 1px solid #ccc; */
  background: #dee2e63d;
}

#viewServicerData2 {
  margin-top: 20px;
}

#viewServicerData2 td {
  width: 20%;
}

.headerclass {
  width: 25%;
}

.headinggeneral {
  font-family: "Mulish", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  margin-bottom: -2px;
}

.checkboxproperty {
  display: flex;
  margin-left: -10px;
}

.PrivateSwitchBase-checked-2 {
  color: #ffc000 !important;
}

.radio-input-include-pending {
  margin-top: 25px;
  margin-right: 15px;
}

.select__control {
  border-radius: 8px !important;
  min-height: 48px !important;
  border: 1px solid #212121 !important;
}

.select__control:hover {
  border-color: #212121 !important;
}

.dataEmail {
  margin-bottom: 50px;
}

.justify-content-confirmmail {
  margin-left: 85px;
}

.modal-terms {
  font-weight: bold;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.modal-scroll {
  height: 800px;
  overflow: hidden;
  overflow-y: scroll;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.modal-linethrough {
  text-decoration: underline;
  margin: 1.5rem 0;
}

.row-accept {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-radio-select {
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin-left: 30px;
}

#icon-button-file-id2 {
  color: #007bff !important;
}

.label-private-key {
  margin-right: 50px;
  color: #212121;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 8px !important;
  /* border: 1px solid red
 ; */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

#signup-container {
  height: 100%;
  overflow-y: auto;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

#signup-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

#signup-container::-webkit-scrollbar {
  /* width: 20px; */
}

/* Track */
#signup-container::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  /* border-radius: 10px; */
}

/* Handle */
#signup-container::-webkit-scrollbar-thumb {
  background: #aaa69d;
  /* border-radius: 10px; */
}

/* Handle on hover */
#signup-container::-webkit-scrollbar-thumb:hover {
  background: #273c75;
}

.Modal-Data {
  margin-left: -18px;
}

.addswitch {
  margin-left: 169px;
}

.popupbutton-css {
  margin-bottom: -20px;
  color: #018e82;
  background: none;
  border: none;
  font-family: "Mulish", sans-serif !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 15px;
  line-height: 19px;
}

.btn_move_Needed {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  margin: 3rem 0rem;
}

.btn_move {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  margin: 3rem 0rem;
  position: relative;
  bottom: 5rem;
}

.btn_move_accounts {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  margin: 3rem 0rem;
  position: relative;
  bottom: 4rem;
}

.btn_prev {
  width: 110px;
  height: 38px;
  font-weight: 600;
  font-size: 15px;
  border-radius: 20px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border: 1.2px solid rgba(33, 33, 33, 1);
  border: 1.2px solid var(--tableBorder);
}

.btn_next {
  width: 110px;
  height: 38px;
  font-weight: 600;
  font-size: 15px;
  border-radius: 20px;
  border: 1.2px solid rgba(33, 33, 33, 1);
  border: 1.2px solid var(--tableBorder);
}

.editBorder {
  border: 1px #fff;
}

.input-generalContainer {
  padding: 0.45rem;
  width: 32rem;
}

.general-InvContainer {
  padding: 0.45rem;
}

.gap-InvContainer {
  margin-bottom: 3rem;
}
.input-generalContainer-Date {
  padding: 0.45rem;
}

.input-generalContainer:last-child {
  margin-bottom: 3rem;
}

.input-general {
  position: relative;
  border: 1.5px solid rgba(33, 33, 33, 1);
  border: 1.5px solid var(--tableBorder);
  border-radius: 8px;
  padding: 10px 10px;
  color: #212121;
  color: var(--tableText);
  display: block;
  width: 450px !important;
  height: 38px;
}

.input-general-file {
  position: relative;
  border: 1.5px solid rgba(33, 33, 33, 1);
  border: 1.5px solid var(--tableBorder);
  border-radius: 8px;
  padding: 10px 10px;
  color: #212121;
  color: var(--tableText);
  display: block;
  width: 350px !important;
  height: 38px;
}

.input-select-general {
  background-image: linear-gradient(45deg, transparent 50%, #018e82 50%),
    linear-gradient(135deg, #018e82 50%, transparent 50%);
  background-image: linear-gradient(45deg, transparent 50%, var(--button) 50%),
    linear-gradient(135deg, var(--button) 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  border: 1.5px solid rgba(33, 33, 33, 1);
  border: 1.5px solid var(--tableBorder);
  border-radius: 8px;
  color: #212121;
  color: var(--tableText);
  padding: 1px 1rem;
  background-color: #fff;
  background-color: var(--white);
  display: block;
  width: 450px;
  height: 38px;
}

.input-select-general-file {
  background-image: linear-gradient(45deg, transparent 50%, #018e82 50%),
    linear-gradient(135deg, #018e82 50%, transparent 50%);
  background-image: linear-gradient(45deg, transparent 50%, var(--button) 50%),
    linear-gradient(135deg, var(--button) 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  border: 1.5px solid rgba(33, 33, 33, 1);
  border: 1.5px solid var(--tableBorder);
  border-radius: 8px;
  color: #212121;
  color: var(--tableText);
  padding: 1px 1rem;
  background-color: #fff;
  background-color: var(--white);
  display: block;
  width: 350px;
  height: 38px;
}

.input-select-dashboard {
  background-image: linear-gradient(45deg, transparent 50%, #018e82 50%),
    linear-gradient(135deg, #018e82 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  border: 1.5px solid #212121;
  border-radius: 8px;
  color: #212121;
  padding: 1px 0.5rem;
  background-color: #fff;
  display: block;
  width: 140px;
  height: 38px;
}

.input-select-general-new {
  background-image: linear-gradient(45deg, transparent 50%, #018e82 50%),
    linear-gradient(135deg, #018e82 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  border: 1.5px solid #212121;
  border-radius: 8px;
  color: #212121;
  padding: 1px 1rem;
  background-color: #fff;
  display: block;
  width: 200px;
  height: 38px;
}

.align-payment {
  display: flex;
  justify-content: space-between;
  width: 450px;
}

.input-general-date {
  position: relative;
  border: 1.5px solid #212121;
  border-radius: 8px;
  padding: 10px 10px;
  color: #212121;
  display: block;
  width: 357px;
  height: 38px;
}

.Servicerdashboard {
  display: flex;
  align-items: center;
}

.Servicerdashboard h1 {
  font-family: "Catamaran", sans-serif !important;
  font-size: 24px;
  color: #212121;
  font-weight: 600 !important;
  line-height: 39px;
  margin-top: 1%;
}

.servicer_color {
  cursor: pointer;
  color: #4a8b87;
  margin-right: 10px;
}

.servicer_upload {
  font-family: "Catamaran", sans-serif !important;
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
}

.servicer_upload h6 {
  font-family: "Mulish", sans-serif !important;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  margin-top: 15px;
}

.Tranche_page-content {
  background: #fff;
  background: var(--white);
  margin: 38px;
  box-shadow: none !important;
}

.css-general {
  background: #fafafa;
  background: var(--lightGray);
  border: 1px solid rgba(33, 33, 33, 1);
  border: 1px solid var(--tableBorder);
  border-radius: 10px;
  margin-bottom: 28px;
  margin-right: 20px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  /* position: relative; */
  /* overflow-y: scroll; */
}

/* .css-collateral {
  background: #FAFAFA;
  border: 1px solid #212121;
  border-radius: 10px;
  margin-bottom: 28px;
  margin-right: 20px;
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 40px;
  /* overflow-y: scroll;
} */

.css-recurring {
  /* background: #FAFAFA; */
  /* border: 1px solid #212121; */
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  margin-bottom: 28px;
  margin-right: 20px;
  height: 85px;
  width: 100%;
  position: relative;
  overflow: hidden;
  left: 0.5rem;
}

.general-row {
  display: flex;
  justify-content: space-around;
  padding: 1rem 2rem;
}

.align-actions {
  display: flex;
  /* flex-direction: column; */
  grid-gap: 1.4rem;
  gap: 1.4rem;
  padding-left: 2rem;
  padding-right: 4rem;
  justify-content: center;
  width: 5rem;
}
.align-actions button {
  color: #018e82;
  color: var(--button);
}

.align-actions-servicer {
  display: flex;
  flex-direction: column;
  position: relative;
  right: 4.4rem;
}

.align-actions-investor {
  display: flex;
  /* flex-direction: column; */
  grid-gap: 0.5rem;
  gap: 0.5rem;
  /* padding-left: 2rem; */
  /* padding-right: 5rem; */
  text-align: left;
  position: relative;
  left: 0;
  justify-content: center;
  width: 7rem;
}

.align-viewDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  width: 20rem;
}

.popupdashboard {
  color: #018e82;
  color: var(--button);
  background: none;
  border: none;
  width: 10rem;
  font-size: 14px;
  font-weight: 800;
}

.popupdashboard-trustee {
  color: #018e82;
  color: var(--button);
  background: none;
  border: none;
  width: 5rem;
  font-size: 14px;
  font-weight: 800;
}

.popupdashboard-servicer {
  color: #018e82;
  background: none;
  border: none;
  width: 15rem;
  font-size: 16px;
  font-weight: 800;
}

.input-inline {
  width: 450px !important;
  display: flex;
  align-items: center;
}

.css-general::-webkit-scrollbar {
  width: 12px;
}

/* Handle */
.css-general::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 11px 1px 1px #989898;
}

.details {
  display: flex;
  align-items: center;
}

.paymentdate {
  margin-top: 0.5rem;
  margin-right: 4rem;
  font-size: 1.4rem;
}

.popupbuttonViewdetails {
  color: #8c8c8c;
  background: none;
  border: none;
  font-size: 18px;
}

.calendar {
  width: 357px;
  border: 1px solid #212121;
  height: 38px;
}

.form-check-label {
  margin-top: 6px;
}

.radiorole {
  margin-left: 18px !important;
  padding: 5px 15px 5px !important;
}

.content1 {
  padding-left: 70px;
}

.maximized {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.closePopup1 {
  display: flex;
  justify-content: flex-end;
  color: #018e82;
  background: white;
  border-radius: 20px;
  padding: 6px;
}

[type="checkbox"] {
  width: 20px;
  height: 20px;
  background-color: #fff000;
  border: 2rem solid black;
}

[type="checkbox"]:checked {
  accent-color: #ffcc00;
}

/* Scoped style for checkboxes inside your custom multi-select component */
.custom-multiselect input[type="checkbox"] {
  accent-color: white;
}

/* Style for checked state with a custom tick color */
.custom-multiselect input[type="checkbox"]:checked::before {
  width: 20px;
  height: 20px;
  background-color: black;
  border: 2rem solid black;
}

.dealInfo {
  font-weight: bold;
  position: relative;
  top: 0.3rem;
}

.input-Datepickercss {
  -webkit-transform: scale(0.9, 1.1);
          transform: scale(0.9, 1.1);
}

.input-Datepickercss-tranches {
  -webkit-transform: scale(0.7899, 1.1);
          transform: scale(0.7899, 1.1);
  position: relative;
  left: -2.7rem;
}

.borderclass {
  border: 1.5px solid rgba(33, 33, 33, 1);
  border: 1.5px solid var(--tableBorder);
  border-radius: 10px;
  width: 450px;
  padding-top: 23px;
}

.headingpayment1-css {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  padding: 0 28px !important;
  font-family: "Catamaran", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
}

.borderclassh5 {
  font-family: "Catamaran", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  margin-bottom: 20px;
}

.e1class {
  font-family: "Mulish", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #212121;
}

.align-actions1 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 10.7rem;
}

.dealInfo1 {
  margin: 8px 0px;
  word-spacing: 0.1px;
}

.justify-content-end-loantape {
  justify-content: flex-end !important;
  margin-left: 235px;
  margin-top: 60px;
}

.Spreadsheet__header {
  background: var(--header-background-color);
  color: #212121 !important;
  font-family: "Mulish", sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  text-align: center;
  font: inherit;
}

.recurring_details_new {
  display: flex;
  /* justify-content: space-around; */
  grid-gap: 2rem;
  gap: 2rem;
  align-items: center;
  position: relative;
  /* left: 2.8rem; */
  width: 1230px;
}
.map-fields-details{
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
}
.map-fields-details-item{
  display: flex;
  grid-gap: 0.1rem;
  gap: 0.1rem;
  justify-content: flex-end;
}

.recurring_details_new_Reporting {
  display: flex;
  /* justify-content: space-around; */
  grid-gap: 2rem;
  gap: 2rem;
  align-items: center;
  position: relative;
  /* left: 2.8rem; */
  width: 1230px;
}

/* .recurring_Change label {
  position: relative;
  left: -1rem;
} */
/* .css_file_recurring {
  background: #FAFAFA;
  border: 1px solid #212121;
  border-radius: 10px;
  height: 200px;
  width: 100%;
  background-color: #018E82;
  overflow: hidden;
  display: flex;  
  justify-content: center;  
  align-items: center;  
} */
.consolidated_details_new {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fafafa;
  border: 1px solid #018e82;
  border-radius: 10px;
  height: 140px;
  /* Remove fixed height to allow content to expand */
  width: 100%;
}

.consolidated_row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;
  background: #fafafa;
  background: var(--chatWindow_Color);
  border: none !important;
  border-radius: 9px;
}

.dealConso {
  font-weight: bold;
}

.image_details {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  bottom: 0.5rem;
  /* gap: 0.5rem; */
}

/* .image_details img {
  background-color: #FAFAFA ;
  filter: invert(2%);
  -webkit-filter: invert(2%);
}

*/
.intain_img {
  height: 80px;
  width: 300px;
  position: relative;
}

.consolidated_details_new_details {
  display: flex;
  justify-content: space-between;
  width: 100%;
  grid-gap: 5rem;
  gap: 5rem;
  color: #212121;
  color: var(--tableText);
  /* flex-wrap: wrap; */
  /* background-color: #007bff; */
}

.consolidated_details_new_left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: #fff;
  background: var(--white);
  border: 1px solid rgba(33, 33, 33, 1);
  border: 1px solid var(--tableBorder);
  border-radius: 10px;
  height: 650px;
  /* Remove fixed height to allow content to expand */
  /* width: 461px; */
  position: relative;
  width: 40%;
  flex: 1 1;
}

.consolidated_details_new_right {
  display: flex;
  /* width: 750px; */
  width: 60%;
  flex-direction: column;
  /* align-items: flex-start;
  justify-content: flex-start; */
  /* padding-left: 10rem; */
  grid-gap: 1rem;
  gap: 1rem;
  flex: 2 1;
  /* background-color: #007bff; */
}

.heading_tableIndex {
  font-weight: bold;
  color: #212121;
  color: var(--headerText);
  cursor: default;
  padding-bottom: 1rem;
  /* position: absolute; */
  /* left: 5rem;
  top: 2.5rem; */
  /* left: -5rem;
  bottom: 1.5rem; */
}

.linkToTable {
  list-style: none;
  position: absolute;
  left: 3rem;
  color: #018e82;
  /* left: -2rem; */
  display: flex;
  flex-direction: column;
  cursor: pointer;
  grid-gap: 2rem;
  gap: 2rem;
}

.linkToTable a {
  text-decoration: none;
  color: inherit;
}

.linkToTable a:hover {
  color: inherit;
}

/* Add this CSS to your stylesheet */
.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 43px;
  background-color: white;
  color: white;
  color: var(--customColor, white);
  /* Use the customColor variable if defined, otherwise use white */
  border: none;
  border-radius: 60%;
  padding: 8px;
  cursor: pointer;
  font-size: 20px;
  z-index: 9999;
}

.scroll-to-top-button:hover .arrow {
  background-color: #018e82;
  background-color: var(--customColor, #018e82);
  /* Use the customColor variable if defined, otherwise use the fallback color */
  color: white;
}

.arrow {
  color: #018e82;
  color: var(--customColor, #018e82);
  /* Use the customColor variable if defined, otherwise use the fallback color */
  display: inline-block;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.scroll-to-top-button:hover {
  background-color: #018e82;
  background-color: var(--customColor, #018e82);
  /* Use the customColor variable if defined, otherwise use the fallback color */
}

.file_details_new {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  background: var(--white);
  border: 1px solid rgba(33, 33, 33, 1);
  border: 1px solid var(--tableBorder);
  border-radius: 10px;
  height: auto;
  /* Remove fixed height to allow content to expand */
  width: 100%;
}

.file_row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  left: 1rem;
}

.file_item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  width: 40%;
  /* Adjust as needed */
  margin: 0.5rem;
  /* Add spacing between items */
}

.right_service {
  display: flex;
  /* gap: 2rem; */
  align-items: center;
}

.service_common {
  display: flex;
  flex-direction: column;
}

.seperator {
  width: 1px;
  height: 38px;
  background-color: #212121;
  background-color: var(--tableText);
  margin: 0 0.5rem 0.6rem 0.5rem;
  display: inline-block;
}

.reduce_gap {
  width: 13rem;
  /* margin-bottom: 0.2rem;  */
}

.reduce_gap_Issuer {
  position: relative;
  width: 100vw;
  /* margin-bottom: 0.2rem;  */
}

.reduce_gap_contact {
  width: 100%;
}

.contact_gap {
  display: flex;
  width: 50rem;
  grid-gap: 2rem;
  gap: 2rem;
}

.reduce_gap_servicer {
  width: 100%;
}

.general_align {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.general_consolidated {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%;
}

.recurring_details {
  display: flex;
  flex-direction: column;
  flex: 1.5 1;
}

.recurring_details > div {
  display: flex;
  grid-gap: 0.1rem;
  gap: 0.1rem;
  justify-content: flex-end;
}

.Investor-head {
  display: flex;
}

.Investor-content {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
  width: 60%;
  height: 100%;
  margin-left: -1.85rem;
  margin-bottom: -0.67rem;
}

.Investor-content > h4 {
  position: absolute;
  margin-top: 4rem;
  color: black;
  margin-left: 2rem;
  font-weight: bold;
  font-size: 2rem;
}

.Investor-content1 {
  position: absolute;
  width: 40%;
  height: 100%;
  top: 77%;
  /* Adjust the vertical position */
  right: 120px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  /* Center vertically relative to the parent */
  display: flex;
  align-items: center;
  /* Center the content vertically */
}

/* Add this rule to center the image horizontally */
.Investor-content1 img {
  margin: 0 auto;
}

@media (max-width: 768px) {
  .Investor-content1 {
    position: static;
    width: 100%;
    max-width: none;
    margin-top: 2rem;
  }
}

.Inv-child {
  position: absolute;
  display: flex;
  margin-top: 8rem;
  height: 100%;
  grid-gap: 5rem;
  gap: 5rem;
  justify-content: space-evenly;
  margin-left: 2rem;
}

.Inv-child > div > h6 {
  display: flex;
  align-items: flex-start;
  color: black;
}

.Inv-child > div > h5 {
  display: flex;
  align-items: flex-start;
  color: black;
}

.Inv-child1 {
  position: absolute;
  margin-top: 15rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 1rem;
  gap: 1rem;
  height: 100%;
  margin-left: 2rem;
}

.Inv-child1 > h3 {
  color: black;
  font-weight: bold;
}

.Inv-child1 > div > h5 {
  display: flex;
  align-items: flex-start;
  color: black;
}

.Inv-child1 > div > h6 {
  display: flex;
  align-items: flex-start;
  color: black;
}

.inv-table-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 2rem;
  gap: 2rem;
}

.thinking {
  display: flex;
  height: 50vh;
}

.think-left {
  display: flex;
  flex: 0.3 1;
}

.think-right {
  display: flex;
  flex: 0.2 1;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  align-items: center;
  flex-direction: column;
}

.think-dots {
  margin-top: 4rem;
  display: flex;
  flex: 0.3 1;
  grid-gap: 2.3rem;
  gap: 2.3rem;
  flex-direction: column;
}

.think-pages {
  display: flex;
  flex: 0.1 1;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  align-items: center;
  flex-direction: column;
}

.servicer-first-table1 {
  height: auto;
}

.servicer-second-table1 {
  height: auto;
}

.servicer_items {
  list-style: none;
}

@-webkit-keyframes dots {
  0% {
    content: "";
  }

  25% {
    content: ".";
  }

  50% {
    content: "..";
  }

  75% {
    content: "...";
  }

  100% {
    content: ".";
  }
}

@keyframes dots {
  0% {
    content: "";
  }

  25% {
    content: ".";
  }

  50% {
    content: "..";
  }

  75% {
    content: "...";
  }

  100% {
    content: ".";
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loadingMapField {
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}

.loadingMapField::after {
  content: "...";
  display: inline-block;
  -webkit-animation: dots 1.5s infinite linear;
          animation: dots 1.5s infinite linear;
  position: absolute;
}

.commonLoading {
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  font-weight: 500;
}

.commonLoading::after {
  content: "...";
  display: inline-block;
  -webkit-animation: dots 1.5s infinite linear;
          animation: dots 1.5s infinite linear;
  position: absolute;
}

.loadingTextLinear {
  position: fixed;
  /* Position it relative to the viewport */
  top: 0;
  /* Position from the top of the viewport */
  left: 0;
  /* Position from the left of the viewport */
  width: 100%;
  /* Take up the full width */
  height: 100%;
  /* Take up the full height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@-webkit-keyframes dot-animation {
  0%,
  20% {
    content: "";
  }

  40%,
  60% {
    content: ".";
  }

  80%,
  100% {
    content: "..";
  }
}

@keyframes dot-animation {
  0%,
  20% {
    content: "";
  }

  40%,
  60% {
    content: ".";
  }

  80%,
  100% {
    content: "..";
  }
}

.dot-loader {
  color: white;
}

.dot-loader::after {
  color: white;
  content: "...";
  display: inline-block;
  -webkit-animation: dots 1.5s infinite linear;
          animation: dots 1.5s infinite linear;
  position: absolute;
}

.search-container {
  position: relative;
  margin-right: 27px;
  margin-top: 10px;
  color: rgb(1, 142, 130);
}

.search-input {
  padding: 10px;
  padding-left: 30px;
  border: none;
  border-radius: 5px;
  color: #212121;
  color: var(--text_Color);
  background-color: #fff;
  background-color: var(--white);
  transition: width 0.3s ease-in-out;
  margin-left: 5.5px;
}

.search-input .active {
  width: 200px;
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
}

.page-first {
  display: flex;
  position: relative;
  /* left: 4rem; */
  justify-content: space-around;
  overflow: hidden;
}

.css-collateral {
  background: #fff;
  background: var(--white);
  color: #212121;
  color: var(--tableText);
  border: 1px solid rgba(33, 33, 33, 1);
  border: 1px solid var(--tableBorder);
  border-radius: 10px;
  margin-bottom: 28px;
  margin-right: 20px;
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 40px;
  /* overflow-y: scroll; */
}

.css-consolidated {
  background: #fff;
  background: var(--white);
  border: 1px solid rgba(33, 33, 33, 1);
  border: 1px solid var(--tableBorder);
  border-radius: 10px;
  margin-bottom: 28px;
  /* margin-right: 20px; */
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 50px;
  /* overflow-y: scroll; */
}

.collateral_main {
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 1rem;
  gap: 1rem;
  height: 100%;
  /* margin-left: 2rem; */
}

.collateral_child1 {
  display: flex;
  grid-gap: 5rem;
  gap: 5rem;
  align-items: flex-start;
}

.collateral_child > h3 {
  color: black;
  font-weight: bold;
}

.collateral_child > div > h5 {
  display: flex;
  align-items: flex-start;
  color: black;
}

.collateral_child > div > h6 {
  display: flex;
  align-items: flex-start;
  color: black;
}

.collateral_heading {
  border-bottom: 2px solid #048c88;
  width: 100px;
  content: "";
  bottom: 0px;
  left: 0px;
  height: 2px;
  /* position: absolute; */
}

.collateral_heading .page-first h1.pagetitle {
  color: red;
}

.collateral_heading h1.pagetitle {
  font-size: 35px;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 50px;
  color: red !important;
}

.collateral_heading h1.pagetitle::before {
  border-bottom: 2px solid #048c88;
  width: 100px;
  content: "";
  bottom: 0px;
  left: 0px;
  height: 2px;
  position: absolute;
}

#pageHeader-first1 {
  margin: -17px -15px;
  padding: 25px;
}

.collateral-content1 {
  position: absolute;
  width: 35%;
  height: 100%;
  top: 40%;
  right: 5vw;
  /* Adjust the value as needed */
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: flex;
  align-items: center;
  /* Center the content vertically */
}

.collateral_dealinfo {
  /* border-bottom: 1px solid #ccc; */
  padding-bottom: 15px;
  margin-bottom: 15px;
}

#value1 {
  color: #048c88 !important;
  font-weight: bolder !important;
  display: block;
  width: 100%;
  line-height: 34px;
  font-size: 35px;
  font-family: Segoe UI;
  margin-top: 3rem;
}

.collateral-border {
  border-bottom: 2px solid #048c88;
  padding-bottom: 10px;
  margin-bottom: 15px;
  width: 7rem;
}

.Collateral_title {
  font-size: 25px;
  font-family: Segoe UI-MONOSPACE;
  font-weight: 600;
  background: #27a567;
  padding: 7px;
  color: white;
  margin-top: 5rem;
}

.Collateral_loan {
  margin-top: 5rem;
  margin-bottom: 2rem;
  color: #0a4f2b;
  font-size: 16px;
  font-family: "Segoe UI";
}

/* Original class */
.input-select-general-new2 {
  background-image: linear-gradient(45deg, transparent 50%, #018e82 50%),
    linear-gradient(135deg, #018e82, transparent 50%);
  background-image: linear-gradient(45deg, transparent 50%, #018e82 50%),
    linear-gradient(135deg, var(--button), transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  border: 0px;
  border-radius: 2px;
  background-color: transparent;
  color: #018e82;
  color: var(--button);
  width: auto;
  height: 38px;
  outline: none;
  box-shadow: none;
  position: relative;
  right: 1rem;
  padding-right: 30px;
}

/* New class for popup with white background */
.input-select-general-new2.popup-background {
  background-color: #fff;
  background-color: var(--white);
}

/* ------------------------*******************-------------------------------------- */

.btn_prevs {
  width: 110px;
  height: 38px;
  font-weight: 600;
  font-size: 15px;
  border-radius: 20px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  color: #aaa69d;
}

.input-select-general-new {
  background-image: linear-gradient(45deg, transparent 50%, #018e82 50%),
    linear-gradient(135deg, #018e82 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  border: 1.5px solid #212121;
  border-radius: 8px;
  color: #212121;
  padding: 1px 1rem;
  background-color: #fff;
  display: block;
  width: 300px;
  height: 38px;
}

.input-select-general-new4 {
  background-image: linear-gradient(45deg, transparent 50%, #018e82 50%),
    linear-gradient(135deg, #018e82 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: auto, auto, auto;
  background-repeat: no-repeat;
  border: 0px;
  border-radius: 2px;
  color: #018e82;
  padding: 1px 1rem;
  background-color: transparent;
  display: flex;
  width: 130px;
  height: 38px;
}

.standard-box {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-left: -5px;
  margin-top: 10px;
  margin-bottom: -40px;
  grid-gap: 20px;
  gap: 20px;
}

.page-contentstandard {
  background: #fff;
  background: var(--white);
  width: 1450px;
  height: 405px;
  border-radius: 10px;
  border: 1px solid #212121;
  margin-bottom: 30px;
}

.input-select-general {
  background-image: linear-gradient(45deg, transparent 50%, #018e82 50%),
    linear-gradient(135deg, #018e82 50%, transparent 50%);
  background-image: linear-gradient(45deg, transparent 50%, var(--button) 50%),
    linear-gradient(135deg, var(--button) 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  border: 1.5px solid rgba(33, 33, 33, 1);
  border: 1.5px solid var(--tableBorder);
  border-radius: 8px;
  color: #212121;
  color: var(--tableText);
  padding: 1px 1rem;
  background-color: #fff;
  background-color: var(--white);
  display: block;
  width: 450px;
  height: 38px;
}

.input-select-general-new {
  background-image: linear-gradient(45deg, transparent 50%, #018e82 50%),
    linear-gradient(135deg, #018e82 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  border: 1.5px solid #212121;
  border-radius: 8px;
  color: #212121;
  padding: 1px 1rem;
  background-color: #fff;
  display: block;
  width: 300px;
  height: 38px;
}

.input-select-general-new1 {
  background-image: linear-gradient(45deg, transparent 50%, #018e82 50%),
    linear-gradient(135deg, #018e82 50%, transparent 50%);
  background-image: linear-gradient(45deg, transparent 50%, var(--button) 50%),
    linear-gradient(135deg, var(--button) 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  border: 1.5px solid rgba(33, 33, 33, 1);
  border: 1.5px solid var(--tableBorder);
  border-radius: 8px;
  color: #212121;
  color: var(--tableText);
  padding: 1px 1rem;
  background-color: #fff;
  background-color: var(--white);
  display: block;
  width: 200px;
  height: 38px;
}

.input-select-general-new1-deal {
  background-image: linear-gradient(45deg, transparent 50%, #018e82 50%),
    linear-gradient(135deg, #018e82 50%, transparent 50%);
  background-image: linear-gradient(45deg, transparent 50%, var(--button) 50%),
    linear-gradient(135deg, var(--button) 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  border: 1.5px solid rgba(33, 33, 33, 1);
  border: 1.5px solid var(--tableBorder);
  border-radius: 8px;
  color: #212121;
  color: var(--tableText);
  padding: 1px 1rem;
  background-color: #fff;
  background-color: var(--white);
  display: block;
  width: 370px;
  height: 38px;
}

/* .input-select-general-new2 {
  background-image: linear-gradient(45deg, transparent 50%, var(--button)),
    linear-gradient(135deg, var(--button), transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  border: 0px;
  border-radius: 2px;
  color: var(--button);
  padding: 2px 2rem;
  background-color: transparent;
  /* Set the background color to transparent */
/* width: auto; */
/* height: 38px; */
/* }  */

.input-select-general-standard {
  background-image: linear-gradient(45deg, transparent 50%, #018e82 50%),
    linear-gradient(135deg, #018e82 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  border: 1.5px solid #000;
  border-radius: 8px;
  color: #212121;
  padding: 1px 1rem;
  background-color: #fff;
  display: block;
  width: 200px;
  height: 38px;
  margin-top: -40px;
  margin-left: 90px;
}

.input-select-general-standardandsummary {
  background-image: linear-gradient(45deg, transparent 50%, #018e82 50%),
    linear-gradient(135deg, #018e82 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  border: 1.5px solid #000;
  border-radius: 8px;
  color: #212121;
  padding: 1px 1rem;
  background-color: #fff;
  display: block;
  width: 200px;
  height: 38px;
  margin-top: -40px;
  margin-left: 90px;
}

.input-select-general-standard1 {
  background-image: linear-gradient(45deg, transparent 50%, #018e82 50%),
    linear-gradient(135deg, #018e82 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  border: 1.5px solid #000;
  border-radius: px;
  color: #212121;
  padding: 1px 1rem;
  background-color: #fff;
  display: block;
  width: 200px;
  height: 38px;
  margin-top: -40px;
  margin-left: 50px;
}

.row-inv {
  margin-top: 30px;
}

.row-new {
  margin-bottom: 10px;
  margin-left: 620%;
}

.row11 {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.row13 {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-left: -5px;
  margin-top: 10px;
  margin-bottom: 20px;
  grid-gap: 20px;
  gap: 20px;
}

.row14 {
  margin-bottom: 20px;
  grid-gap: 20px;
  gap: 20px;
  margin-left: 20px;
}

.row14_Copy {
  position: relative;
  left: 5px;
  /* bottom: 1rem; */
}

.wanted {
  background: #fafafa;
  border: 1px solid #212121;
  border-radius: 10px;
  margin-bottom: 90px;
  margin-top: -60px;
  margin-right: 20px;
  height: 85px;
  width: 100%;
  overflow: hidden;
}

.stock-marketup {
  display: flex;
  align-items: center;
  color: green;
  font-size: 16px;
  margin-left: 40px;
  margin-top: -30px;
}

.stock-marketdown {
  display: flex;
  align-items: center;
  color: red;
  font-size: 16px;
  margin-left: 40px;
  margin-top: -30px;
  margin-right: -60px;
}

.stock-arrow {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.stock-up {
  color: green;
}

.stock-down {
  color: red;
  -webkit-transform: rotate(180);
          transform: rotate(180);
}

.stock-marketup1 {
  display: flex;
  align-items: center;
  color: green;
  font-size: 16px;
  margin-left: 130px;
  margin-top: -30px;
}

.stock-marketdown1 {
  display: flex;
  align-items: center;
  color: red;
  font-size: 16px;
  margin-left: 110px;
  margin-top: -30px;
}

.wantedsummary {
  background: #fafafa;
  border: 1px solid #212121;
  border-radius: 10px;
  margin-bottom: 30px;
  margin-top: 10px;
  margin-right: 20px;
  height: 85px;
  width: 100%;
  overflow: hidden;
}

.rowsummary {
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
  margin-top: 60px;
  margin-bottom: 10px;
  grid-gap: 20px;
  gap: 20px;
}

.page-contentstandardhorizontal {
  display: flex;
  background: #fff;
  background: var(--white);
  width: 710px;
  height: 405px;
  border-radius: 10px;
  border: 1px solid #212121;
  margin-bottom: 15px;
}

.page-contentstandardhorizontalside {
  background: #fff;
  background: var(--white);
  width: 710px;
  height: 405px;
  border-radius: 10px;
  border: 1px solid #212121;
  margin-bottom: 30px;
  margin-left: 740px;
  margin-top: -420px;
  display: flex;
}

.headersummary {
  font-family: "Catamaran", sans-serif !important;
  font-size: 22px !important;
  color: #212121;
  font-weight: 600 !important;
  margin-top: 5px;
}

.headercharts {
  font-family: "Catamaran", sans-serif !important;
  font-size: 22px !important;
  color: #212121;
  font-weight: 600 !important;
  margin-top: 30px;
  margin-left: 30px;
}

.recurring_details_standard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1230px;
  margin-left: 70px;
  margin-top: 10px;
}

/* .input-select-general-new1-stdFields {
  background-image: linear-gradient(45deg, transparent 50%, #018e82 50%),
    linear-gradient(135deg, #018e82 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  border: 1.5px solid #000;
  border-radius: 8px;
  color: #212121;
  padding: 1px 1rem;
  background-color: #fff;
  display: block;
  width: 300px;
  height: 38px;
}

.stdFieldsLeft {
  display: flex;
  padding: 5rem 5rem 0rem 5rem;
  gap: 3rem;
  justify-content: flex-start;
}

.stdFieldsSub1 {
  display: flex;
  flex-direction: column;
}

.input-stdFields {
  position: relative;
  border: 1.5px solid #212121;
  border-radius: 8px;
  padding: 10px 10px;
  color: #212121;
  display: block;
  width: 300px !important;
  height: 38px;
}

.stdFieldsDown {
  padding: 3rem;
} */

#table-container {
  margin: 0;
  padding: 0;
  box-sizing: 0;
}

@media print {
  .page-break {
    page-break-before: always;
  }
}

.addfields {
  width: 100px;
  height: 38px;
  font-weight: 600;
  font-size: 16px;
  background-color: #018e82;
  border-radius: 10px;
  text-align: center;
  line-height: 38px;
  color: white;
  cursor: pointer;
}

.addfields:hover {
  background-color: #d5f2f0;
  color: #018e82;
}

/* Custom CSS to hide the arrow icon in the Select component */
.hide-arrow-icon .MuiSelect-icon {
  display: none;
}

.parent-std-save {
  position: relative;
}

.enable-std-save {
  display: flex;
  width: 1rem;
  position: absolute;
  right: 6.5rem;
  top: -1rem;
}

.btn_Std {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  right: 2rem;
  padding-bottom: 2rem;
}

.BdbChart {
  position: relative;
  width: 100%;
  height: 78vh;
  display: flex;
  justify-content: center;
  margin-bottom: 10rem;
  /* overflow-y: hidden; */
}

.iframe-no-horizontal-scroll {
  /* overflow-y: hidden; */
}

.AI_Icon {
  cursor: pointer;
  position: relative;
  left: 1.2rem;
}

.Beta {
  position: relative;
  font-size: 14px;
  padding-top: 4px;
  right: 7px;
  color: white;
  letter-spacing: 1.5px;
}

.transition-chat {
  position: relative;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
}

.content-chat {
  background-color: #048c84;
  padding: 10px 10px 10px 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 10%;
  color: #fff;
  flex: 1 1;
}

.Asst-Comps {
  flex: 0.4 1;
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: flex-start;
}

.content-chat .IDA_Icons {
  position: relative;
  top: 4px;
}
.human {
  font-size: 18px;
  font-weight: 600;
  position: relative;
  top: 4px;
  flex: 0.6 1;
}

.content-chat span {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
}

.chatBox {
  height: 79.3%;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  background-color: #fafafa;
  overflow-y: "scroll";
  /* Use "scroll" to enable scrolling */
  scrollbar-width: none;
  -ms-overflow-style: none;
  /* padding-bottom: 20px; */
}

.chatBox::-webkit-scrollbar {
  width: 0.1em;
  /* Adjust to hide the scrollbar */
  display: none;
  /* Hide the scrollbar */
}

.dummySize {
  height: 0.7%;
  position: relative;
  background-color: #fafafa;
}

.inputBox {
  min-height: 10%;
  box-sizing: border-box;
  display: flex;
  /* top:3.5rem; */
  position: relative;
  border-top: 2px solid #000;
}

#chatInput {
  padding-left: 1rem;
  border: none;
  outline: none;
  /* font-size: 1vmax; */
}

.sendBtn {
  border: none;
  width: 10%;
  /* background-color: #048c84; */
  transition: all 0.3s;
  cursor: pointer;
  background-color: #018e82;
}

.sendBtn > img {
  /* width: 1.6vmax; */
  transition: all 0.3s;
  position: relative;
  left: 1.5rem;
}

.sendBtn:hover {
  /* background-color: #1dbab5; */
}

.sendBtn:hover img {
  -webkit-transform: translateX(8px);
          transform: translateX(8px);
}
.sendBtn {
  border-color: #3498db;
  color: #fff;
  transition: all 0.3s ease-in-out;
  /* Increase duration for smoother transition */
}

.sendBtn:hover {
  box-shadow: 0 0 10px 0 #3498db inset, 0 0 10px 4px #3498db;
  font-size: 18px;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  /* Smooth transition on hover */
}

.ask_Btn {
  color: white;
}
.moveToBottomChat {
  height: 30px;
  width: 30px;
  background-color: #e5f3f2;
  position: fixed;
  -webkit-transform: translate(3000%, 1280%) translateY(0);
          transform: translate(3000%, 1280%) translateY(0);
  /* Center horizontally */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-animation: moveUpDown 0.66s infinite alternate;
          animation: moveUpDown 0.66s infinite alternate;
}

@-webkit-keyframes moveUpDown {
  0% {
    -webkit-transform: translate(3000%, 1280%) translateY(0);
            transform: translate(3000%, 1280%) translateY(0);
    /* Start position */
  }

  100% {
    -webkit-transform: translate(3000%, 1274%) translateY(-6px);
            transform: translate(3000%, 1274%) translateY(-6px);
    /* End position */
  }
}

@keyframes moveUpDown {
  0% {
    -webkit-transform: translate(3000%, 1280%) translateY(0);
            transform: translate(3000%, 1280%) translateY(0);
    /* Start position */
  }

  100% {
    -webkit-transform: translate(3000%, 1274%) translateY(-6px);
            transform: translate(3000%, 1274%) translateY(-6px);
    /* End position */
  }
}

.arrowDownChat {
  color: #048c84;
  height: 20px;
}

.typing_channel {
  background-color: #fafafa;
  width: 100%;
  height: 10%;
  /* position: absolute; */
  /* top: 4rem; */
  /* bottom: 4px;
                                          top: 26rem;
                                            left: 0;
                                              */
  /* padding-bottom: 1rem; */
}

.typing-indicator {
  background: #007bff;
  /* height: 5%; */
  /* height: 20px; */
  margin-bottom: 2%;
  margin-top: 2%;
  max-width: 65%;
  padding: 6px;
  overflow: hidden;
  display: inline-block;
  border: 2px solid #e0e0e0;
  /* display: flex;
  justify-content: space-around; */
  /* position: fixed; */
  position: relative;
  width: 5rem;
  left: 1.7%;
  bottom: 0.6rem;
  text-align: center;
  background-color: #fff;
  /* transform: translate(384%,910%); */
  border-radius: 2px 10px 10px 10px;
}

.typing-indicator span {
  display: inline-block;
  opacity: 1;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  -webkit-animation: typingAnimation 1s infinite linear;
          animation: typingAnimation 1s infinite linear;
}

.typing-indicator span:nth-child(1) {
  background-color: rgba(4, 140, 132, 0.8);
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  /* No delay for the first span */
}

.typing-indicator span:nth-child(2) {
  background-color: rgba(4, 140, 132, 0.66);
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
  /* Delay for the second span */
}

.typing-indicator span:nth-child(3) {
  background-color: rgba(4, 140, 132, 0.33);
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  /* Delay for the third span */
}

.suggestedMessages {
  display: flex;
  border-radius: 10px 2px 10px 10px;
  color: #018e82;
  background-color: #e1efee;
  border: 2px solid #018e824d;
  text-align: center;
  padding: 10px;
  margin: 12px;
  justify-content: space-around;
  align-items: center;
  position: relative;
  /* Needed for absolute positioning of the arrow */
}
.suggestedMessage {
  background-color: #fafafa;
}
.suggMsg {
  flex: 0.8 1;
}

.suggArrow {
  height: 28px;
  width: 28px;
  color: #018e82;
  border-radius: 50%;
  padding: 3px;
  position: absolute;
  top: 50%;
  right: 0;
  /* background-color: #e1efee; */
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  opacity: 0;
  /* Initially hidden */
  transition: opacity 0.3s ease-in-out;
  /* Add a smooth transition */
}

.suggestedMessages:hover {
  background-color: #f0f8f7;
  transition: 0.3s ease-in-out;
}

.suggestedMessages:hover .suggArrow {
  opacity: 1;
  /* Make the arrow visible on hover */
}

.suggArrow:hover {
  color: #fff;
  background-color: #42c0b0;
  transition: 0.123s ease-in-out;
}

@-webkit-keyframes typingAnimation {
  0%,
  20% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  80%,
  100% {
    opacity: 1;
  }
}

@keyframes typingAnimation {
  0%,
  20% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  80%,
  100% {
    opacity: 1;
  }
}

.CreateActions {
  display: flex;
  justify-content: flex-start;
  width: 7rem;
}

.checkbox-container {
  display: flex;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.checkbox-container .checkbox-input {
  cursor: pointer;
}

.Accordian {
  width: 100%;
  height: 2.5px;
  background: #bcbcbc;
  display: flex;
  justify-content: flex-end;
  margin: 2rem 0rem;
  align-items: center;
}

.AccordImg {
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: 1.2rem;
  cursor: pointer;
}

.AccordImgReverse {
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: 1.2rem;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  cursor: pointer;
}

.form-container-tranches .Accordian {
  grid-column: 1/-1;
}

.TrancheHeadBtns {
  height: 80px;
  display: flex;
}

.investor-heading-container-tranches {
  flex: 0.3 1;
}

.TranBtns {
  height: 5rem;
  width: 8rem;
}

.TranBtns img {
  height: 1.4rem;
  width: 1.4rem;
  position: relative;
  right: 0.4rem;
  top: 0.1rem;
}

.Tranches_right_btns {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: flex-end;
  flex: 0.7 1;
}

.UploadedFileTranche {
  position: absolute;
  top: 0.1rem;
  right: -0.3rem;
  /* left: 1rem;
  bottom: 0.3rem; */
}

.UploadedFileTranche .UploadedFileImgTranche {
  height: 16px;
  width: 17px;
  right: 0.6rem;
}

.greyline {
  width: 1320px;
  height: 1px;
  margin-top: 10px;
  background-color: #8c8c8c;
  border-radius: 1px;
  margin-left: 30px;
  display: flex;
  align-items: center;
}

.accordion {
  margin-left: 1330px;
  margin-top: 10px;
  margin-left: 30px;
  display: flex;
  align-items: left;
}

/* .accordianToggle{
  
  } */

.addButton {
  left: 95%;
  -webkit-transform: translateX(-80%);
          transform: translateX(-80%);
  position: absolute;
  margin-top: -30px;
}

.removeButton {
  left: 92.5%;
  -webkit-transform: translateX(-80%);
          transform: translateX(-80%);
  margin-top: -30px;
  position: absolute;
  height: 20px;
}

.removeButtonmove {
  margin-left: 1392px;
  margin-bottom: 30px;
  height: 30px;
}

.general-row1 {
  /* display: flex;
    justify-content: space-between;
      */
  /* align-items:flex-end; */
  width: auto;
  /* Adjust the width as needed */
  height: 300px;
  /* Adjust the height as needed */
  border-radius: 10px;
  margin-bottom: 50px;
  /* margin-left: 10px; */
  margin-right: 10px;
  position: relative;
  /* overflow: hidden; */
  /* justify-content: space-around; */
}

.general-row1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 19px;
  right: 1px;
  bottom: 0;
  border: 3px dotted #8c8c8c;
  /* Adjust the width and color */
  box-shadow: 0 0 0 2px transparent;
  /* Adjust the spread value to control the dot spacing */
}

.AccordianGeneral {
  width: 90%;
  height: 2.5px;
  background: #bcbcbc;
  display: flex;
  justify-content: flex-end;
  margin: 2rem 0rem;
  align-items: center;
  left: 2rem;
  position: relative;
}

.AccordImgGen {
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: -4.5rem;
  cursor: pointer;
}

.AccordImgGenReverse {
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: -4.5rem;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  cursor: pointer;
}

.AccordianGeneral {
  width: 90%;
  height: 2.5px;
  background: #bcbcbc;
  display: flex;
  justify-content: flex-end;
  margin: 2rem 0rem;
  align-items: center;
  left: 2rem;
  position: relative;
}

.AccordImgGen {
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: -4.5rem;
  cursor: pointer;
}

.AccordImgGenReverse {
  width: 2rem;
  height: 2rem;
  position: relative;
  right: -4.5rem;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  cursor: pointer;
}

.AccordImgAnnex {
  width: 2rem;
  height: 2rem;
  position: relative;
  /* right: 1rem; */
  top: 0.75rem;
  left: 0.5rem;
  cursor: pointer;
}

.AccordImgAnnexReverse {
  width: 2rem;
  height: 2rem;
  position: relative;
  /* right: 1rem; */
  left: 0.5rem;
  top: 0.75rem;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  cursor: pointer;
}

.disabledESMA {
  opacity: 0.6;
  /* Reduce opacity to indicate disabled state */
  pointer-events: none;
  /* Disable pointer events to prevent interactions */
  /* Add any additional styling to indicate disabled state */
  position: relative;
}

.custom-disabled-button {
  background-color: white !important;
}

.popupbuttonViewdetails.custom-disabled-button.Mui-disabled {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.26);
  /* Default text color for disabled state */
  border: 1px solid rgba(0, 0, 0, 0.23);
  /* Default border color for disabled state */
}

.MuiInputBase-input.Mui-disabled {
  background: #eeeeee !important;
  border-radius: 10px;
}

/* ---------Accounts Recurring Table css------------------ */

/* Add this to your CSS file */
.custom-table .ant-table-thead > tr > th {
  background-color: rgba(229, 243, 242, 1);
  background-color: var(--table-header-bg);
  color: #212121;
  color: var(--headerText);
  /* border-bottom-style: 0px; */
  /* Change text color if needed */
}

/* .custom-table .ant-table-thead>tr>th {
  border-bottom: 0.5px solid #000;
} */

.custom-table .ant-table-thead .account-column {
  border-right: 0.5px solid rgba(33, 33, 33, 1) !important;
  border-right: 0.5px solid var(--tableBorder) !important;
}

.custom-table .ant-table-thead .parent-column {
  border-right: 0.5px solid rgba(33, 33, 33, 1) !important;
  border-right: 0.5px solid var(--tableBorder) !important;
  border-bottom: 0.5px solid rgba(33, 33, 33, 1) !important;
  border-bottom: 0.5px solid var(--tableBorder) !important;
}

.custom-table .ant-table-thead .child-column {
  border-right: 0.5px solid rgba(33, 33, 33, 1) !important;
  border-right: 0.5px solid var(--tableBorder) !important;
}

.custom-table:where(.css-dev-only-do-not-override-11lehqq).ant-table-wrapper
  .ant-table-cell-scrollbar:not([rowspan]) {
  box-shadow: none !important;
}

.custom-table:where(.css-11lehqq).ant-table-wrapper
  .ant-table-cell-scrollbar:not([rowspan]) {
  box-shadow: none !important;
}

.custom-table {
  /* border-bottom-width: 0px; */
  border: 0.5px solid rgba(33, 33, 33, 1);
  border: 0.5px solid var(--tableBorder);
  border-radius: 10px !important;
  position: relative;
  bottom: 5rem;
}

.custom-table .ant-table-tbody > tr > td {
  border-top: none;
  border-right: none !important;
  border-bottom: none;
  border-left: none;
}

.custom-table .ant-table-tbody > tr > td:nth-child(1),
.custom-table .ant-table-tbody > tr > td:nth-child(5),
.custom-table .ant-table-tbody > tr > td:nth-child(8),
.custom-table .ant-table-tbody > tr > td:nth-child(11) {
  border-top: none;
  border-right: 0.5px solid rgba(33, 33, 33, 1) !important;
  border-right: 0.5px solid var(--tableBorder) !important;
  border-bottom: none;
  border-left: none;
}

.custom-table .ant-table-tbody > tr:nth-child(odd) > td {
  background-color: #f7f7f7;
  background-color: var(--tdEven); /* Applies to odd rows */
  color: #212121 !important;
  color: var(--headerText) !important;
}

.custom-table .ant-table-tbody > tr:nth-child(even) > td {
  background-color: rgba(255, 255, 255, 1);
  background-color: var(--tdOdd); /* Example: Light grey for even rows */
  color: #212121 !important;
  color: var(--headerText) !important;
}

.custom-table .ant-table-tbody > tr:nth-child(odd) .account-column {
  background-color: rgba(255, 255, 255, 1) !important;
  background-color: var(--tdOdd) !important;
  position: relative;
}

.custom-table .ant-table-tbody > tr:nth-child(odd) .account-column::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #f7f7f7;
  background-color: var(--tdEven);
  z-index: -1;
}

.custom-table .ant-table-tbody > tr:nth-child(odd) .account-column::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 1;
}

.custom-table .ant-table-tbody > tr:nth-child(odd) .account-column:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 1);
  background-color: var(--tdOdd);
}

.custom-table .ant-table-tbody > tr:nth-child(odd) .account-column > * {
  color: #212121 !important;
  color: var(--tableText) !important;
}
.custom-table:hover {
  background: none !important;
}
.custom-table .ant-table-row:hover {
  background-color: #fafafa !important;
  background-color: var(
    --hover-on-row
  ) !important; /* Your custom hover background */
  box-shadow: #fafafa !important;
  box-shadow: var(--hover-on-row) !important; /* Remove default hover shadow */
}

.custom-table .ant-table-row:hover td.ant-table-cell {
  background: #fafafa !important;
  background: var(--hover-on-row) !important;
}

.custom-table .ant-table-body::-webkit-scrollbar {
  width: 6px; /* Width of the vertical scrollbar */
  height: 6px; /* Height of the horizontal scrollbar */
}

.custom-table .ant-table-body {
  scrollbar-width: thin !important; /* For Firefox: Use thin scrollbars */
  scrollbar-color: #888 #f1f1f1; /* For Firefox: Thumb and track colors */
}

/* ---------------------- End of Accounts Recurring ------------------ */
/* ---------Performance Table css------------------ */

/* Add this to your CSS file */
.performance-table .ant-table-thead > tr > th {
  background-color: rgba(229, 243, 242, 1);
  background-color: var(--table-header-bg);
  color: #212121 !important;
  color: var(--headerText) !important;
  border-right: none !important;
  border-bottom: none !important;
  /* Change text color if needed */
}
.performance-table .ant-table-tbody > tr:nth-child(odd) > td {
  background-color: #f7f7f7;
  background-color: var(--tdEven); /* Applies to odd rows */
  color: #212121 !important;
  color: var(--headerText) !important;
  border-right: none !important;
}

.performance-table .ant-table-tbody > tr:nth-child(even) > td {
  /* If you want a different style for even rows, add here */
  background-color: rgba(255, 255, 255, 1);
  background-color: var(--tdOdd); /* Example: Light grey for even rows */
  color: #212121 !important;
  color: var(--headerText) !important;
  border-right: none !important;
}

.performance-table .ant-table-tbody > tr:nth-child(even) > td {
  background-color: rgba(255, 255, 255, 1);
  background-color: var(--tdOdd); /* Applies to odd rows */
}
.performance-table .ant-table-tbody > tr:nth-child(odd) > td {
  background-color: #f7f7f7;
  background-color: var(--tdEven); /* Example: Light grey for even rows */
}

/* .performance-table .ant-table-thead>tr>th {
  border-bottom: 0.5px solid #000;
} */

.performance-table .ant-table-thead .account-column {
  border-right: 0.5px solid #000 !important;
}

.performance-table .ant-table-thead .parent-column {
  border-right: 0.5px solid #000 !important;
  border-bottom: 0.5px solid #000 !important;
}

.performance-table .ant-table-thead .child-column {
  border-right: 0.5px solid rgba(33, 33, 33, 1) !important;
  border-right: 0.5px solid var(--tableBorder) !important;
}

.performance-table {
  /* border-bottom-width: 0px; */
  border: 1px solid #000;
  border-radius: 10px;
  overflow: hidden !important;
  /* New addition */
  flex-grow: 1;
  border: 1px solid rgba(33, 33, 33, 1);
  border: 1px solid var(--tableBorder);
  /* Allows the table to take the remaining height of the container */
}
.performance-table:where(
    .css-dev-only-do-not-override-11lehqq
  ).ant-table-wrapper
  .ant-table-cell-scrollbar:not([rowspan]) {
  box-shadow: none !important;
}

.performance-table:where(.css-11lehqq).ant-table-wrapper
  .ant-table-cell-scrollbar:not([rowspan]) {
  box-shadow: none !important;
}

.performance-table .ant-table-tbody > tr > td {
  border-top: none;
  border-right: none !important;
  border-bottom: none !important;
  border-left: none;
}

.performance-table .ant-table-cell {
  height: 49px;
  font-family: "Mulish", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 18.83px;
  text-align: left;
  background: #fff;
  background: var(--white);
  color: #212121;
  color: var(--tableText);
}

/* .performance-table .ant-table-tbody>tr>td:nth-child(1),
.performance-table .ant-table-tbody>tr>td:nth-child(5),
.performance-table .ant-table-tbody>tr>td:nth-child(8),
.performance-table .ant-table-tbody>tr>td:nth-child(11) {
  border-top: none;
  border-right: 0.5px solid #000 !important;
  border-bottom: none;
  border-left: none;
} */

/* .performance-table .ant-table-tbody>tr:nth-child(odd) {
  background-color: rgb(229, 229, 229, 0.3) !important;
} */

.performance-table .ant-table-tbody > tr:nth-child(even) {
  background-color: white !important;
}

.performance-table .ant-table-tbody > tr:nth-child(odd) .account-column {
  background-color: white !important;
  position: relative;
}

.performance-table
  .ant-table-tbody
  > tr:nth-child(odd)
  .account-column::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background-color: rgba(229, 229, 229, 0.3) !important; */
}

.performance-table .ant-table-tbody > tr:nth-child(odd) .account-column::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: -99;
}

.performance-table
  .ant-table-tbody
  > tr:nth-child(odd)
  .account-column:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 1;
  /* background-color: rgba(255, 255, 255, 1); */
}

.performance-table .ant-table-tbody > tr:nth-child(odd) .account-column > * {
  color: #000 !important;
}

/* Define the CSS to remove hover effect */
.performance-table:hover {
  background: none !important;
}
.performance-table .ant-table-thead > tr > th:not(:last-child)::before,
.performance-table .ant-table-thead > tr > td:not(:last-child)::before {
  content: none !important; /* Disable the pseudo-element */
  background-color: transparent !important; /* Remove any background color */
  width: 0 !important; /* Remove the visual divider entirely */
}

.performance-table .ant-table-row {
  transition: none !important;
}
.performance-table .ant-table-row:hover {
  background-color: #fafafa !important;
  background-color: var(
    --hover-on-row
  ) !important; /* Your custom hover background */
  box-shadow: #fafafa !important;
  box-shadow: var(--hover-on-row) !important; /* Remove default hover shadow */
}

.performance-table .ant-table-row:hover td.ant-table-cell {
  background: #fafafa !important;
  background: var(--hover-on-row) !important;
}

/* ---------------------- End of Performance ------------------ */

.issuerDashboard-table-top-button-insights-analytics {
  margin-left: 10px !important;
  color: #212121 !important;
  color: var(--text_Color) !important;
  padding: 5px;
  font-weight: 900;
}

.issuerDashboard-table-top-button-insights-active-analytics {
  color: #018e82 !important;
  color: var(--button) !important;
  margin-left: 10px !important;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 900;
  position: relative; /* Needed for positioning the pseudo-element */
}

.issuerDashboard-table-top-button-insights-active-analytics::after {
  content: "";
  position: absolute;
  bottom: -1px; /* Position the line at the bottom of the text */
  left: 6px; /* Adjust the starting position */
  width: calc(100% - 25px); /* Set width to text width minus 20px */
  height: 2px; /* Line height */
  background-color: #018e82 !important; /* Line color */
}

.scrollable-content {
  /* max-height: 100vh; */
  /* overflow-y: auto; */
  /* padding-bottom: 24rem; */
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  width: 100% !important;
  box-sizing: border-box;
  /* overflow-x: hidden; */
  /* position: relative;
                                                                      top: -2rem; */
}

.scrollable-content-dash {
  /* max-height: 100vh; */
  /* overflow-y: auto; */
  /* padding-bottom: 24rem; */
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  width: 100% !important;
  box-sizing: border-box;
  /* overflow-x: hidden; */
  position: relative;
  /* top: -3rem; */
}

.chart-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  gap: 10px;
  width: 100% !important;
  grid-auto-rows: minmax(100px, auto);
  box-sizing: border-box;
  position: relative;
  top: 2rem;
}

.plot-borders-bar-scatter {
  grid-column: 1/3;
  grid-row: 7/8;
  border: 1px solid rgba(33, 33, 33, 1);
  border: 1px solid var(--tableBorder);
  border-radius: 6px;
  padding: 10px;
  /* margin: 10px; */
}

.plot-borders-bar-performance {
  grid-column: 1/3;
  grid-row: 3/4;
  border: 1px solid rgba(33, 33, 33, 1);
  border: 1px solid var(--tableBorder);
  border-radius: 6px;
  padding: 10px;
  /* margin: 10px; */
}
.plot-borders-bar-cpr_cdr {
  grid-column: 1/3;
  grid-row: 2/3;
  border: 1px solid #000;
  border-radius: 6px;
  padding: 10px;
  /* margin: 10px; */
}
.plot-borders-bar-collateral {
  grid-column: 1/3;
  grid-row: 6/7;
  border: 1px solid #000;
  border-radius: 6px;
  padding: 10px;
  border: 1px solid rgba(33, 33, 33, 1);
  border: 1px solid var(--tableBorder);
  /* margin: 10px; */
}

.bdb-Ondemand {
  padding: 2rem 0;
}

.plot-borders {
  border: 1px solid rgba(33, 33, 33, 1) !important;
  border: 1px solid var(--tableBorder) !important;
  border-radius: 10px;
  padding: 6px;
  /* z-index: 1; */
  /* background-color: aqua; */
  width: 100%;
  height: auto;
  /* Adjust height as needed */
  /* margin: 10px; */
}
.plot-borders-cpr_cdr {
  width: 100%;
  height: auto;
  /* Use auto if you want it to be flexible */
  position: relative;
  /* bottom: 2.6rem; */

  /* New additions */
  display: flex;
  flex-direction: column;
}
.plot-borders-delinquency {
  width: 100%;
  height: auto;
  /* Use auto if you want it to be flexible */
  position: relative;
  /* bottom: 2.6rem; */

  /* New additions */
  display: flex;
  flex-direction: column;
}

.static-movement {
  overflow-y: auto !important;
  height: 400px !important;
  position: relative; /* Position relative to contain the fixed header */
}

.static-movement thead {
  position: -webkit-sticky;
  position: sticky; /* Allows the header to stick to the top */
  top: 0; /* Position from the top */
  background: white; /* Background color for the header */
  z-index: 1; /* Ensure the header is above other content */
}

.static-movement th {
  padding: 8px; /* Padding for table headers */
  border-bottom: 1px solid #ddd; /* Border for the header */
}

.plot-inline {
  display: inline !important;
  position: relative;
  width: auto !important;
  height: 450px;
}

.plot-inline .plot-container .svg-container {
  width: auto !important;
}

.plot-inline .plot-container .svg-container .main-svg {
  width: 100% !important;
}

/* @media only screen and (min-width:1200px) {
  .plot-borders {
    width: 560px !important;
  }
} */

.button-group-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  /* background-color: #007bff; */
  text-align: center;
  position: relative;
  top: 0.6rem;
}

.chart-container-portfoilo {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  gap: 10px;
  width: 100% !important;
  grid-auto-rows: minmax(100px, auto);
  box-sizing: border-box;
  position: relative;
  /* top: 2rem; */
}

.portfolioDash {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.dash-table-alignments {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

@media only screen and (min-width: 1050px) and (max-width: 1488px) {
  .chart-container-portfoilo {
    width: 1100px !important;
  }

  /* .plot-inside {
    width: 530px !important;
  } */
}

@media only screen and (min-width: 1150px) and (max-width: 1500px) {
  .plot-borders {
    width: 635px !important;
  }

  .plot-borders-cpr_cdr {
    width: 635px !important;
  }
  .plot-borders-delinquency {
    width: 635px !important;
  }
  /* .plot-inside {
    width: 530px !important;
  } */
}
@media only screen and (max-width: 1294px) {
  .plot-borders {
    width: 564px !important;
  }

  .plot-borders-cpr_cdr {
    width: 564px !important;
  }
  .plot-borders-delinquency {
    width: 564px !important;
  }
  /* .plot-inside {
    width: 530px !important;
  } */
}
.react-datepicker__input-container input[type="text"] {
  width: 250px;
  height: 45px;
  margin: 0 0 0 10px;
  border: 0px solid transparent;
  display: block;
  outline: none;
  padding: 10px 14px;
  cursor: pointer;
  font: 600 14px Mulish;
  text-align: left;
  color: rgb(33, 33, 33);
  text-decoration: none;
  background: rgb(221, 221, 221);
}

.date-range-picker {
  /* position: relative;
                                                                                margin-top: -85px; */
}

.align-per-tabs {
  position: relative;
}

.date-input-container {
  position: relative;
  width: 20vw;
  display: flex;
  align-items: center;
}

.date-input {
  width: 100%;
  cursor: pointer;
  border: 1px solid rgba(33, 33, 33, 1);
  border: 1px solid var(--tableBorder);
  border-radius: 8px;
  background-color: #fafafa;
  background-color: var(--lightGray); /* Example: Light grey for even rows */
  color: #212121 !important;
  color: var(--headerText) !important;
}

.calendar-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.datepicker {
  z-index: 99999;
  position: relative;
  background: #fff !important;
  background: var(--white) !important;
  padding: 10px;
  display: block;
  height: 247px;
  width: 400px;
  border-radius: 0;
  border: 1px solid rgb(230, 230, 230);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.datepicker span {
  font-size: 14px;
  font-family: Mulish, sans-serif;
  color: #212121;
  color: var(--tableText);
  text-align: left;
}

.datepicker-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.datepicker-label {
  width: 45px;
  text-align: right;
  margin-right: 10px;
}

.date-picker {
  width: 250px;
  height: 40px;
}
.react-datepicker__month-container {
  background-color: #fafafa !important;
  background-color: var(--chatWindow_Color) !important;
  color: #212121 !important;
  color: var(--text_Color) !important;
}
.react-datepicker__month-container .react-datepicker__header {
  background-color: #fafafa !important;
  background-color: var(--chatWindow_Color) !important;
  color: #212121 !important;
  color: var(--text_Color) !important;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #212121 !important;
  color: var(--text_Color) !important;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #212121 !important;
  color: var(--text_Color) !important;
}
.react-datepicker__year-select {
  background-color: #fafafa !important;
  background-color: var(--chatWindow_Color) !important;
  color: #212121 !important;
  color: var(--text_Color) !important;
  border: 2px solid #212121 !important;
  border: 2px solid var(--border-Chat) !important;
}

.button-row {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  margin-left: 75px;
  padding: 0 20px;
}

.daterange-btn,
.daterange-cancelbtn {
  width: 110px;
  height: 40px;
  border-radius: 5px;
  font-size: 15px;
  font-family: Mulish, sans-serif;
  padding: 8.75px 2px;
  cursor: pointer;
}

.daterange-btn {
  border: 1px solid rgb(1, 142, 130);
  background-color: rgb(1, 142, 130);
  color: rgb(255, 255, 255);
}

.daterange-cancelbtn {
  margin-left: 10px;
  border: 1px solid rgb(1, 142, 130);
  background-color: rgb(255, 255, 255);
  color: rgb(1, 142, 130);
}

.date-input {
  width: 100%;
  height: 38px;
  padding: 5px 30px 5px 5px;
  /* Add right padding for the icon */
  box-sizing: border-box;
  cursor: pointer;
}

.calendar-icon {
  position: absolute;
  right: 10px;
  /* Position the icon inside the input */
  top: 50%;
  height: 22px;
  width: 19px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #018e82;
  /* Set the icon color to green */
  font-size: 1.2em;
  /* Adjust size as needed */
  cursor: pointer;
  /* Ensure icon is clickable */
}

.table-container {
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
}

.strats-table-container {
  grid-gap: 0rem !important;
  gap: 0rem !important;
}
/* Servicer_css_new_changes */

.pagination-container {
  justify-content: center;
  color: #ffffff;
  margin-top: 1rem;
  display: flex;
  position: relative;
  font-size: 13px;
}
.pagination-container .MuiPagination-ul .MuiButtonBase-root {
  color: #000 !important;
  border-radius: 16px;
}

.date-viewDetails .MuiFilledInput-root {
  width: 12rem !important;
}

/* .Mappedtape-err-validations-msg {
position: absolute;
left: 100%;
top:100%;
margin-left: -170px;
border: 4px solid #AE2E2E;
border-radius: 10px;
padding: 5px;
background-color: #fff;
font-size: 13px;
width:170px;
height:62px;
white-space: normal;
overflow: break-word;
word-break: break-word;
transform: translateY(10px);
} */

/* .Mappedtape-err-validations-msg {
position: absolute;
left: 0;
bottom: 100%; 
background: #fff;
border: 1px solid #AE2E2E;
color: #AE2E2E;
padding: 5px;
display: none; 
white-space: nowrap;
z-index: 10; 
} */

/* .Mappedtape-err-validations-msg-pointer {
position: absolute;
bottom: 100%;
left: 20px;
border-left:10px solid transparent ;
border-right: 10px solid transparent;
border-bottom: 10px solid #AE2E2E;
} */

/* .Mappedtape-err-validations-msg-pointer {
position: absolute;
left: 50%;
bottom: -5px; 
width: 0;
height: 0;
border-left: 5px solid transparent;
border-right: 5px solid transparent;
border-top: 5px solid #AE2E2E;
} */

/* .TableCell:hover .Mappedtape-err-validations-msg .Mappedtape-err-validations-msg-pointer {
display: block;
} */
/* 
.previewLoanid .MuiTableCell-root {
width:150px !important ;

} */

.TableCell {
  position: relative;
}

.Mappedtape-err-validations-msg {
  position: absolute;
  left: 100%;
  top: 100%;
  margin-left: -129px;
  border: 4px solid #ae2e2e;
  border-radius: 10px;
  padding: 5px;
  background-color: #fff;
  font-size: 13px;
  width: 170px;
  height: 62px;
  white-space: normal;
  overflow: break-word;
  word-break: break-word;
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
  display: none;
  z-index: 10;
}

.TableCell:hover .Mappedtape-err-validations-msg {
  display: block;
  /* Show on hover */
}

.Mappedtape-err-validations-msg-pointer {
  position: absolute;
  bottom: 100%;
  left: 43px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ae2e2e;
}
.SummarizeFirstTable {
  font-family: Mulish, sans-serif !important;
  padding: 13px !important;
  font-size: 0.98rem !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sticky-column {
  background: #fff;
  background: var(--white);
  width: 100px;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
  height: 1em;
}

.sticky-column.no-error {
  border-left: 1px solid #e0e0e0;
}

.sticky-column.error {
  border-left: 8px solid #ae2e2e;
}

.popover-triangle {
  width: 8px;
  /* Adjust size as needed */
  height: 8px;
  /* Adjust size as needed */
  margin-left: 144px;
  /* Adjust spacing as needed */
  margin-top: -2rem;
}

.blur-background {
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-backdrop-filter: blur(5px);
  -webkit-filter: blur(5px);
          filter: blur(5px);
}

.non-blur-background {
  -webkit-backdrop-filter: blur(0px);
  -webkit-filter: blur(0px);
          filter: blur(0px);
}

.align-right {
  text-align: center;
}
.label-datatapes {
  color: #212121;
  color: var(--headerText);
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 8px !important;
  margin-left: 2rem;
}

/* Servicer_css */

.servicer-popover-container-recurring {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 18px !important;
  padding-left: 15px !important;
  padding-bottom: 18px !important;
  padding-right: 46px !important;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  width: 170;
  height: 114.55px;
  border: 1px solid #212121;
}

.servicer-popover-container-recurring button {
  border: none;
  outline: none;
  background: none;
  width: 100%;
  text-align: start;
  color: #018e82;
}

.input-general_datatape_pk {
  position: relative;
  border: 1.5px solid rgba(33, 33, 33, 1);
  border: 1.5px solid var(--tableBorder);
  border-radius: 8px;
  padding: 10px 10px;
  color: #212121;
  color: var(--tableText);
  display: block;
  width: 250px !important;
  height: 38px;
  left: 36rem;
}

.input-general_datatape_servicer {
  position: relative;
  border: 1.5px solid rgba(33, 33, 33, 1);
  border: 1.5px solid var(--tableBorder);
  border-radius: 8px;
  padding: 10px 10px;
  color: #212121;
  color: var(--tableText);
  display: block;
  width: 250px !important;
  height: 38px;
  left: 1.5rem;
}

.input-general_datatape_name {
  position: relative;
  border: 1.5px solid rgba(33, 33, 33, 1);
  border: 1.5px solid var(--tableBorder);
  border-radius: 8px;
  padding: 10px 10px;
  color: #212121;
  color: var(--tableText);
  display: block;
  width: 250px !important;
  height: 38px;
  left: 2.5rem;
}

.input-general_datatape_description {
  position: absolute;
  border: 1.5px solid rgba(33, 33, 33, 1);
  border: 1.5px solid var(--tableBorder);
  border-radius: 8px;
  padding: 10px 10px;
  color: #212121;
  color: var(--tableText);
  display: flex;
  width: 500px !important;
  height: 38px;
  left: 32.3rem;
}

.buttonissue-Datatapes-add {
  left: 39rem !important;
  font-size: 38px !important;
  position: relative;
  color: #018e82;
  cursor: pointer;
}

.buttonissue-Datatapes-add-after {
  margin-left: 660px !important;
  font-size: 38px !important;
  margin-top: 5px !important;
  color: #018e82;
  cursor: pointer;
}

.buttonissue-Datatapes-remove {
  left: 36.5rem !important;
  font-size: 38px !important;
  position: relative;
  color: #018e82;
  cursor: pointer;
}

.buttonissue-Datatapes-remove-after {
  left: 34.2rem !important;
  font-size: 38px !important;
  position: relative;
  color: #018e82;
  color: var(--button);
  cursor: pointer;
}

.borderclass {
  border: 1.5px solid rgba(33, 33, 33, 1);
  border: 1.5px solid var(--tableBorder);
  border-radius: 10px;
  width: 483px;
  padding-top: 23px;
  position: absolute;
}

.borderclass_apidoc {
  border: 1.5px solid rgba(33, 33, 33, 1);
  border: 1.5px solid var(--tableBorder);
  border-radius: 10px;
  width: 483px;
  padding-top: 23px;
  /* margin-top: -21rem;
margin-bottom: 10rem; */
  margin-left: 42rem;
}

.servicer-popup-inner-container-heading {
  font-family: Mulish, sans-serif !important;

  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 19px;

  margin-left: 1rem;
  width: 26rem;
}
.uploadfieldscontainer {
  border: 1px solid rgba(33, 33, 33, 1);
  border: 1px solid var(--tableBorder);
  width: 402px;
  height: 78px;
  border-radius: 10px;
  margin-bottom: 15px;
  background-color: #fafafa;
  background-color: var(--lightGray);
  display: flex;
  align-items: center;
}
.uploadfieldscontainer-Selectfile {
  border: 1px solid rgba(33, 33, 33, 1);
  border: 1px solid var(--tableBorder);
  width: 402px;
  height: 78px;
  border-radius: 10px;
  margin-bottom: 15px;
  background-color: #fafafa;
  background-color: var(--lightGray);
  margin-left: -33px;
  display: flex;
  align-items: center;
}

.servicer-popover-container-sort {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 18px !important;
  padding-left: 15px !important;
  /* padding-bottom: 18px !important; */
  padding-right: 46px !important;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  width: 180.55px;
  height: 150px;
  border: 1px solid #212121;
  margin-bottom: 10rem;
  border-radius: 8px;
}

.servicer-popover-container-sort-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 79px !important;
  padding-left: 15px !important;
  /* padding-bottom: 18px !important; */
  padding-right: 46px !important;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  width: 180.55px;
  height: 150px;
  border: 1px solid #212121;
  margin-bottom: 10rem;
  border-radius: 8px;
}

.servicer-popover-container-filter {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 18px !important;
  padding-left: 15px !important;
  padding-bottom: 18px !important;
  padding-right: 46px !important;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  width: 170px;
  height: 105px;
  border: 1px solid #212121;
  margin-left: 24.5rem;
  border-radius: 8px;
  margin-top: -3rem;
}

.servicer-popover-container-sort button {
  border: none;
  outline: none;
  background: none;
  width: 100%;
  text-align: start;
  color: #018e82;
}

.servicer-popover-container-sort-2 button {
  border: none;
  outline: none;
  background: none;
  width: 100%;
  text-align: start;
  color: #018e82;
}

.servicer-popover-container-filter button {
  border: none;
  outline: none;
  background: none;
  width: 100%;
  text-align: start;
  color: #018e82;
}

.servicer-popover-sort {
  height: 1px;
  background: #e0e0e0;
  width: 150%;
  margin-left: 30px;
}

.popupdashboard-servicer-button-disabled {
  opacity: 0.5;
  width: 6rem;
  font-size: 15px;
  margin-left: 8.5rem;
}

.align-actions-servicer {
  display: flex;
  flex-direction: column;
  position: relative;
  /* right: 4.4rem; */

  margin-right: 3rem;
  margin-left: -6rem;
}

.popupdashboard-servicer {
  color: #018e82;
  background: none;
  border: none;
  width: 15rem;
  font-size: 15px;
  font-weight: 800;
  margin-left: 4rem;
}

.popupdashboard-servicer.error {
  text-decoration: underline;
  color: 4px solid #ae2e2e;
  /* Optional: To make it more noticeable */
}
.popupdashboard-servicer-process {
  color: #018e82;
  background: none;
  border: none;
  width: 4rem;
  font-size: 15px;
  font-weight: 800;
  margin-left: 14rem;
  margin-top: -1.25rem;
}

.paymentdate-viewdetails {
  margin-top: 1.5rem;
  margin-left: 1.5rem;
  font-size: 15px;
  font-weight: 400;
}

.popupbuttonViewdetails {
  color: #8c8c8c;
  background: none;
  border: none;
  font-size: 15px;
  width: 187px;
  height: 20px;
  position: absolute;
  margin-right: 6rem;
}
.popupbuttonViewdetails-top-button {
  color: #8c8c8c;
  background: none;
  border: none;
  font-size: 15px;
}

.headingpayment1-css-uploadloantape {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  padding: 0 28px !important;
  font-family: Mulish, sans-serif !important;

  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32.8px;
}

.image_details {
  align-self: center;
  filter: invert(2%);
  -webkit-filter: invert(2%);
  /* gap: 0.5rem; */
}

.popupbuttonViewdetails-top-button.custom-disabled-button.Mui-disabled {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.26);
  /* Default text color for disabled state */
  border: 1px solid rgba(0, 0, 0, 0.23);
  /* Default border color for disabled state */
}

.MuiInputBase-input.Mui-disabled {
  background: #999 !important;
  border-radius: 10px;
}

.popupbutton1-viewaggsum {
  color: #018e82;
  color: var(--button);
  background: none;
  border: none;
  font-size: 15px;
  width: 187px;
  height: 20px;
  position: absolute;
  margin-right: 6rem;
}

.popupbutton1-viewaggsum:hover {
  color: #018e82;
  color: var(--button);
}
.popupbutton1-viewdetails {
  color: #018e82;
  color: var(--button);
  background: none;
  border: none;
  font-size: 15px;
  width: 132px;
  margin-left: 16rem;
}

.popupbutton1-viewdetails:hover {
  color: #018e82;
  color: var(--button);
  /* margin-left: -8rem; */
}

.kyc-card__button-container_upload {
  color: #212121;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  right: 5px;
}

.servicer-popup-upload-btn-contianer-2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: -3rem;
}

.servicer-popup-upload-btn-contianer-3 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: -2rem;
}
/*.......................... Input General Css................... */

@media only screen and (min-width: 1100px) and (max-width: 1600px) {
  .input-general {
    width: 350px !important;
  }

  .input-inline {
    width: 350px !important;
  }

  .MuiInputBase-root {
    width: 350px !important;
  }

  .input-select-general {
    width: 350px !important;
  }

  .css-9i23jl-control {
    width: 350px !important;
  }

  .flex1 {
    width: 350px !important;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #8a8585;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

.scrollable {
  scrollbar-width: thin !important;
  scrollbar-color: #212121;
}

/* --------- Antd Table Design ---------- */

.custom-antds-table .ant-table-thead > tr > th {
  background-color: rgba(229, 243, 242, 1);
  background-color: var(--table-header-bg);
  color: #212121 !important;
  color: var(--headerText) !important;
  /* border-bottom-style: 0px; */
  /* Change text color if needed */
}

.custom-antds-table .ant-table-tbody > tr:nth-child(even) > td {
  background-color: rgba(255, 255, 255, 1);
  background-color: var(--tdOdd); /* Applies to odd rows */
}
.custom-antds-table .ant-table-tbody > tr:nth-child(odd) > td {
  background-color: #f7f7f7;
  background-color: var(--tdEven); /* Example: Light grey for even rows */
}
.custom-antds-table .ant-table-thead .account-column {
  border-right: 0.5px solid #000 !important;
}

.custom-antds-table:where(
    .css-dev-only-do-not-override-11lehqq
  ).ant-table-wrapper
  .ant-table-cell-scrollbar:not([rowspan]) {
  box-shadow: none !important;
}

.custom-antds-table:where(.css-11lehqq).ant-table-wrapper
  .ant-table-cell-scrollbar:not([rowspan]) {
  box-shadow: none !important;
}

.custom-antds-table .ant-table-thead .child-column {
  border-right: 0.5px solid #000 !important;
}
.custom-antds-table {
  /* border-bottom-width: 0px; */
  border: 1px solid rgba(33, 33, 33, 1);
  border: 1px solid var(--tableBorder);
  border-radius: 10px;
  overflow: hidden !important;
  /* New addition */
  flex-grow: 1;
  position: relative;
  bottom: 4rem;

  /* Allows the table to take the remaining height of the container */
}

.custom-antds-table .ant-table-tbody > tr > td {
  border-top: none;
  border-right: none !important;
  border-bottom: none !important;
  border-left: none;
}

.custom-antds-table .ant-table-cell {
  height: 49px;
  font-family: "Mulish", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 18.83px;
  text-align: left;
  background-color: #fff;
  background-color: var(--white);
  color: #212121;
  color: var(--tableText);
}
.custom-antds-table .ant-table-tbody > tr:nth-child(odd) {
  background-color: rgba(255, 255, 255, 1) !important;
  background-color: var(--tdOdd) !important;
}

.custom-antds-table .ant-table-tbody > tr:nth-child(even) {
  background-color: #f7f7f7 !important;
  background-color: var(--tdEven) !important;
}

.custom-antds-table .ant-table-tbody > tr:nth-child(odd) .account-column {
  background-color: #fff !important;
  background-color: var(--white) !important;
  /* position: relative; */
}

.custom-antds-table
  .ant-table-tbody
  > tr:nth-child(odd)
  .account-column::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background-color: rgba(229, 229, 229, 0.3) !important; */
}

.custom-antds-table
  .ant-table-tbody
  > tr:nth-child(odd)
  .account-column::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: -99;
}
.custom-antds-table
  .ant-table-tbody
  > tr:nth-child(odd)
  .account-column:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 1;
  /* background-color: rgba(255, 255, 255, 1); */
}

.custom-antds-table .ant-table-tbody > tr:nth-child(odd) .account-column > * {
  color: #212121 !important;
  color: var(--text_Color) !important;
}

/* Define the CSS to remove hover effect */
.custom-antds-table:hover {
  background: #fff !important;
  background: var(--white) !important;
}

/* Common styles for table columns */
.custom-antds-table .ant-table-thead > tr > th {
  background-color: rgba(229, 243, 242, 1);
  background-color: var(--table-header-bg);
  color: #212121 !important;
  color: var(--headerText) !important;
  border-bottom: none; /* Remove the border-bottom */
  text-align: center; /* Optional: Center-align text */
  white-space: normal; /* Optional: Allow text wrapping */
  word-wrap: break-word;
  line-height: 1.5; /* Optional: Better spacing for multi-line headers */
  padding: 8px;
}

.custom-antds-table .ant-table-tbody > tr > td {
  width: 150px; /* Match the width of the headers */
  /* text-align: center; Center-align content text */
  white-space: normal;
  word-wrap: break-word;
  line-height: 1.5;
}

.custom-antds-table .ant-table-thead > tr > th:not(:last-child)::before,
.custom-antds-table .ant-table-thead > tr > td:not(:last-child)::before {
  content: none !important; /* Disable the pseudo-element */
  background-color: transparent !important; /* Remove any background color */
  width: 0 !important; /* Remove the visual divider entirely */
}

.custom-antds-table .ant-table-row {
  transition: none !important;
}
.custom-antds-table .ant-table-row:hover {
  background-color: #fafafa !important;
  background-color: var(
    --hover-on-row
  ) !important; /* Your custom hover background */
  box-shadow: #fafafa !important;
  box-shadow: var(--hover-on-row) !important; /* Remove default hover shadow */
}

.custom-antds-table .ant-table-row:hover td.ant-table-cell {
  background: #fafafa !important;
  background: var(--hover-on-row) !important;
}

.custom-antds-table .ant-table-body::-webkit-scrollbar {
  width: 6px; /* Width of the vertical scrollbar */
  height: 6px; /* Height of the horizontal scrollbar */
}

.custom-antds-table .ant-table-body {
  scrollbar-width: thin !important; /* For Firefox: Use thin scrollbars */
  scrollbar-color: #888 #f1f1f1; /* For Firefox: Thumb and track colors */
}

/* ---------- Dashboard custom table -------------- */

.custom-antds-dash-table .ant-table-thead > tr > th {
  background-color: rgba(229, 243, 242, 1);
  background-color: var(--table-header-bg);
  color: #212121 !important;
  color: var(--headerText) !important;
  /* border-bottom-style: 0px; */
  /* Change text color if needed */
}
.custom-antds-dash-table .ant-table-thead .account-column {
  border-right: 0.5px solid #000 !important;
}

.custom-antds-dash-table:where(
    .css-dev-only-do-not-override-11lehqq
  ).ant-table-wrapper
  .ant-table-cell-scrollbar:not([rowspan]) {
  box-shadow: none !important;
}

.custom-antds-dash-table:where(.css-11lehqq).ant-table-wrapper
  .ant-table-cell-scrollbar:not([rowspan]) {
  box-shadow: none !important;
}

.custom-antds-dash-table .ant-table-tbody > tr:nth-child(even) > td {
  background-color: rgba(255, 255, 255, 1);
  background-color: var(--tdOdd); /* Applies to odd rows */
}
.custom-antds-dash-table .ant-table-tbody > tr:nth-child(odd) > td {
  background-color: #f7f7f7;
  background-color: var(--tdEven); /* Example: Light grey for even rows */
}

.custom-antds-dash-table .ant-table-thead .child-column {
  border-right: 0.5px solid #000 !important;
}
.custom-antds-dash-table {
  /* border-bottom-width: 0px; */
  border: 1px solid rgba(33, 33, 33, 1);
  border: 1px solid var(--tableBorder);
  border-radius: 10px;
  overflow: hidden !important;
  /* New addition */
  flex-grow: 1;
  position: relative;
  /* Allows the table to take the remaining height of the container */
}

.custom-antds-dash-table .ant-table-tbody > tr > td {
  border-top: none;
  border-right: none !important;
  border-bottom: none !important;
  border-left: none;
  background-color: #fff;
  background-color: var(--white);
  color: #212121;
  color: var(--tableText);
}

.custom-antds-dash-table .ant-table-cell {
  height: 49px;
  font-family: "Mulish", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 18.83px;
  text-align: left;
}
.custom-antds-dash-table .ant-table-tbody > tr:nth-child(odd) {
  background-color: rgba(255, 255, 255, 1) !important;
  background-color: var(--tdOdd) !important;
}

.custom-antds-dash-table .ant-table-tbody > tr:nth-child(even) {
  background-color: #f7f7f7 !important;
  background-color: var(--tdEven) !important;
}

.custom-antds-dash-table .ant-table-tbody > tr:nth-child(odd) .account-column {
  background-color: rgba(255, 255, 255, 1) !important;
  background-color: var(--tdOdd) !important;
}

.custom-antds-dash-table
  .ant-table-tbody
  > tr:nth-child(odd)
  .account-column::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 1) !important;
  background-color: var(--tdOdd) !important;
  /* background-color: rgba(229, 229, 229, 0.3) !important; */
}

.custom-antds-dash-table
  .ant-table-tbody
  > tr:nth-child(odd)
  .account-column::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: -99;
}
.custom-antds-dash-table
  .ant-table-tbody
  > tr:nth-child(odd)
  .account-column:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 1;
  /* background-color: rgba(255, 255, 255, 1); */
}

.custom-antds-dash-table
  .ant-table-tbody
  > tr:nth-child(odd)
  .account-column
  > * {
  color: #212121 !important;
  color: var(--text_Color) !important;
}

/* Define the CSS to remove hover effect */
/* .custom-antds-dash-table:hover {
  background: var(--white) !important;
  color:#000  !important;
} */

/* Common styles for table columns */
.custom-antds-dash-table .ant-table-thead > tr > th {
  background-color: rgba(229, 243, 242, 1);
  background-color: var(--table-header-bg);
  color: #212121 !important;
  color: var(--headerText) !important;
  border-bottom: none; /* Remove the border-bottom */
  text-align: center; /* Optional: Center-align text */
  white-space: normal; /* Optional: Allow text wrapping */
  word-wrap: break-word;
  line-height: 1.5; /* Optional: Better spacing for multi-line headers */
  padding: 8px;
}

.custom-antds-dash-table .ant-table-tbody > tr > td {
  width: 150px; /* Match the width of the headers */
  text-align: center; /* Center-align content text */
  white-space: normal;
  word-wrap: break-word;
  line-height: 1.5;
}

.custom-antds-dash-table .ant-table-thead > tr > th:not(:last-child)::before,
.custom-antds-dash-table .ant-table-thead > tr > td:not(:last-child)::before {
  content: none !important; /* Disable the pseudo-element */
  background-color: transparent !important; /* Remove any background color */
  width: 0 !important; /* Remove the visual divider entirely */
}

.custom-antds-dash-table .ant-table-row {
  transition: none !important;
}
.custom-antds-dash-table .ant-table-row:hover {
  background-color: #fafafa !important;
  background-color: var(
    --hover-on-row
  ) !important; /* Your custom hover background */
  box-shadow: #fafafa !important;
  box-shadow: var(--hover-on-row) !important; /* Remove default hover shadow */
}

.custom-antds-dash-table .ant-table-row:hover td.ant-table-cell {
  background: #fafafa !important;
  background: var(--hover-on-row) !important;
}

.custom-antds-dash-table .ant-table-body::-webkit-scrollbar {
  width: 6px; /* Width of the vertical scrollbar */
  height: 6px; /* Height of the horizontal scrollbar */
}

.custom-antds-dash-table .ant-table-body {
  scrollbar-width: thin !important; /* For Firefox: Use thin scrollbars */
  scrollbar-color: #888 #f1f1f1; /* For Firefox: Thumb and track colors */
}

/* ------------------------- custom - filters ---------------------------- */

/* Left placement */
.popover-container-sort-left {
  inset: 120px 0px auto auto !important;
  height: 334px;
  margin-bottom: 10rem;
  border-radius: 8px;
  width: 387.55px;
  min-width: 387.55px;
  border: 1px solid rgba(33, 33, 33, 1);
  border: 1px solid var(--tableBorder);
  background: #fff;
  background: var(--white);
  color: #212121;
  color: var(--tableText);
}

.popover-container-sort-left .arrow {
  -webkit-transform: translate(0px, 38.25px) !important;
          transform: translate(0px, 38.25px) !important;
}

input[type="radio"] {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
  -webkit-appearance: none;
          appearance: none;
  border: 1px solid rgba(33, 33, 33, 1);
  border: 1px solid var(--tableBorder);
  background: #fff;
  background: var(--white);
  color: #212121;
  color: var(--tableText);
  cursor: pointer;
}

input[type="radio"]:checked {
  background-color: #ffc000;
}
/* input[type="checkbox"]{
  width: 20px;
  height: 20px;
  color: #ffffff;
  border: 1px solid #212121;
  border-radius : 3px;
  
  }
  input[type="checkbox"]:checked{
  width: 14px;
  height : 14px;
  accent-color: #ffc000;
  } */

/* Right placement */
.popover-container-sort-right {
  inset: 120px auto auto 0px !important;
  /* inset: 120px 0px auto auto !important; */
  height: 334px;
  border: 1px solid rgba(33, 33, 33, 1);
  border: 1px solid var(--tableBorder);
  background: #fff;
  background: var(--white);
  color: #212121;
  color: var(--tableText);
  margin-bottom: 10rem;
  border-radius: 8px;
  width: 387.55px;
  min-width: 387.55px;
}

.popover-content {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  margin-left: 28px;
  margin-top: 15px;
}

.popover-row {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.popover-input {
  width: 130px;
  height: 38px;
  border-radius: 8px;
  padding: 5px !important;
  border: 1px solid rgba(33, 33, 33, 1);
  border: 1px solid var(--tableBorder);
  background: #fff;
  background: var(--white);
  color: #212121;
  color: var(--tableText);
}

.dropdownicon .popover-input {
  background-image: linear-gradient(45deg, transparent 50%, #018e82 50%),
    linear-gradient(135deg, #018e82 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 6px 6px, 6px 6px, 9.38px 9.38px;
  background-repeat: no-repeat;
}

.dropdownicon .popover-input-wide {
  background-image: linear-gradient(45deg, transparent 50%, #018e82 50%),
    linear-gradient(135deg, #018e82 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 6px 6px, 6px 6px, 9.38px 9.38px;
  background-repeat: no-repeat;
}

.popover-input-wide {
  width: 190px;
  height: 38px;
  border-radius: 8px;
  padding: 5px;
  border: 1px solid rgba(33, 33, 33, 1);
  border: 1px solid var(--tableBorder);
  background: #fff;
  background: var(--white);
  color: #212121;
  color: var(--tableText);
}

.popover-radio-group {
  display: flex;
  margin-top: 15px;
  grid-gap: 15px;
  gap: 15px;
}

.popover-radio-item {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.popover-bottom-buttons {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  margin-left: 153px;
  margin-top: 10px;
}

.filteredColumn {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  border: 1px solid rgba(33, 33, 33, 1);
  border: 1px solid var(--tableBorder);
  background: #fff;
  background: var(--white);
  color: #212121;
  color: var(--tableText);
}

.custom-pagination-item {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #000000 !important;
  color: var(--dropdownTextColor) !important;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none !important; /* Removes the square outline */
  border: none !important; /* Optional: Ensures no borders */
}

.custom-pagination-item:hover {
  color: white !important;
  background-color: #018e82 !important;
}

.custom-pagination-item.active {
  background-color: #018e82 !important;
  color: white !important;
}

.custom-pagination-item:focus {
  outline: none !important; /* Removes the focus outline */
}

.custom-search .ant-input-search-button {
  display: none; /* Hide the search button */
}

.custom-search {
  position: relative;
  right: 1rem;
}

.custom-search .ant-input-wrapper .ant-input-affix-wrapper {
  border-radius: 8px;
  background-color: #fff;
  background-color: var(--white);
}

.custom-search .ant-input-wrapper .ant-input {
  border-radius: 8px; /* Apply consistent border radius */
}

.custom-search .ant-input-wrapper .ant-input::-webkit-input-placeholder {
  color: #018e82;
  color: var(--placeHolder);
  opacity: 1;
}

.custom-search .ant-input-wrapper .ant-input::placeholder {
  color: #018e82;
  color: var(--placeHolder);
  opacity: 1;
}

.anticon {
  color: #018e82;
  color: var(--placeHolder);
}
.dropdown-heading-dropdown-arrow {
  color: #018e82 !important;
}

.rmsc .clear-selected-button {
  display: none !important;
}

.align-fixed-content {
  position: -webkit-sticky;
  position: sticky; /* Makes it stick to the top while scrolling */
  top: 0; /* Ensures it stays at the top */
  z-index: 100; /* Adjust z-index to keep it above other elements */
  background-color: #fff;
  background-color: var(--white);
  height: 270px;
  margin-bottom: 1rem;
}
.align-fixed-content-loans {
  position: -webkit-sticky;
  position: sticky; /* Makes it stick to the top while scrolling */
  top: 0; /* Ensures it stays at the top */
  z-index: 100; /* Adjust z-index to keep it above other elements */
  background-color: #fff;
  background-color: var(--white);
  height: 300px;
  margin-bottom: 1rem;
}

.deal-name-display {
  /* margin-left: 15px;
  display: flex; */
  align-items: center;
  position: relative;
  right: 16rem;
  /* max-width: 500px; */
  overflow: hidden; /* Ensures content doesn't overflow */
  white-space: nowrap; /* Prevents text from wrapping */
  text-overflow: ellipsis;
}

.deal-name-box {
  padding: 5px 15px;
  background-color: rgba(1, 142, 130, 0.25);
  border: 1px solid rgba(1, 142, 130, 0.25);
  border-radius: 4px;
  font-size: 14px;
  font-family: Mulish;
  font-weight: 600;
  color: #212121;
  color: var(--tableText);
}


.sidebar {
  width: 60px;
  height: 100vh;
  /* background-color: #0e1111; */
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: width 0.3s ease;
}

.sidebar:hover {
  width: 220px;
}
.sidebar:hover .issure-icon-active {
  width: 185px !important;
}

.sidebar:hover .sidebar-item-content {
  width: 185px !important;
}

.sidebar-icons {
  padding: 15px 0 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-logo {
  position: relative;
  margin-bottom: 50px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* .sidebar:hover ul:active {
  background-color: aqua;
  width: 200px !important;
} */
.sidebar-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 7px;
  color: white;
  text-decoration: none;
  /* width: 185px; */
  /* transition: background-color 0.3s ease; */
}

.sidebar-item img {
  width: 24px;
  height: 24px;
}

.sidebar-item-label {
  display: none;
  margin-left: 15px;
  color: white;
  font-size: 14px;
}

.sidebar:hover .sidebar-item-label {
  display: inline-block;
}

.sidebar-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}

.sidebar-item-profile {
  background-color: #e2ebea;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50px;
  color: #004d40;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}

/* Add hover effects for smooth transitions */
.sidebar-item:hover {
  background-color: #1a534e;
}

.notificationcolor {
  font-size: 24px;
  color: #ccc;
}

.sidebar-icons ul {
  list-style-type: none !important;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style: none !important;
}

.sidebar-icons li {
  display: flex;
  align-items: center;
  justify-content: center;
  /* Ensures text is aligned with icons */
  /* margin-bottom: 20px; */
  /* Adds space between each icon-item */
  list-style-type: none !important;
}

.sidebar-icons img {
  width: 40px;
  /* height: 30px; */
  /* Adds spacing between the icon and text */
}

.sidebar-icons span {
  font-size: 14px;
  color: #fff;
  /* Adjust this based on the sidebar color scheme */
}

.logo-intain-hidden {
  display: none;
  /* Hide IntainLogo by default */
}

/* Show IntainLogo on sidebar hover */
.sidebar:hover .logo-intain-hidden {
  display: block;
  width: 88px;
  height: 44px;
  /* Show IntainLogo when sidebar is hovered */
}

.sidebar:hover .logo-intan {
  display: none;
  /* Hide Intan_AI when sidebar is hovered */
}

.sidebar-item-content {
  opacity: 0.5;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}
.sidebar-item .sidebar-item-content {
  -webkit-filter: var(--sideBarIconFilter);
          filter: var(--sideBarIconFilter);
}

.sidebar-item:hover {
  opacity: 0.5;
  cursor: pointer;
  border-radius: 10px;
  padding: 8px 7px;
  /* width: 185px !important; */
}

/* Show Content on sidebar hover */
.content-show {
  display: none;
  /* Hide IntainLogo by default */
}

.sidebar:hover .content-show {
  display: block;
  -webkit-filter: var(--sideBarIconFilter);
          filter: var(--sideBarIconFilter);
  /* Show Content when sidebar is hovered */
}

.darkmode-label {
  width: 40px; /* Reduced from 50px */
  height: 20px; /* Reduced from 25px */
  position: relative;
  display: block;
  background-color: #03dac6;
  border-radius: 12px; /* Adjusted for smaller size */
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.4),
    inset 0px -1px 2px rgba(255, 255, 255, 0.4);
  cursor: pointer;
  transition: 0.3s;
  right: 0.4rem;
}

.darkmode-label::after {
  content: "";
  width: 16px; /* Reduced from 21px */
  height: 16px; /* Reduced from 21px */
  position: absolute;
  top: 2px; /* Adjusted for centering */
  left: 2px;
  background: linear-gradient(180deg, #ffffff, #ffffff);
  border-radius: 50%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

#darkmode-toggle {
  width: 0;
  height: 0;
  visibility: hidden;
}

#darkmode-toggle:checked + .darkmode-label {
  background-color: #017268;
}

#darkmode-toggle:checked + .darkmode-label::after {
  left: 100%; /* Adjusted for smaller size */
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  background: linear-gradient(180deg, #777, #3a3a3a);
}

/* Adjusted size for sun and moon icons */
.sun,
.moon {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 14px; /* Reduced from 19px */
  height: 14px; /* Reduced from 19px */
  transition: 0.3s;
  z-index: 100;
}

.moon {
  left: 3px; /* Adjusted position */
  opacity: 1; /* Moon is visible when unchecked */
}

.sun {
  right: 3px; /* Adjusted position */
  opacity: 0; /* Sun is hidden when unchecked */
}

#darkmode-toggle:checked + .darkmode-label .moon {
  opacity: 0; /* Moon hides when checked */
}

#darkmode-toggle:checked + .darkmode-label .sun {
  opacity: 1; /* Sun shows when checked */
}

.darkmode-label:active::after {
  width: 10px; /* Reduced from 13px */
  height: 10px; /* Reduced from 12px */
}

/* Add this to sidebar.css */
.blur-background {
  -webkit-filter: blur(5px);
          filter: blur(5px);
  pointer-events: none;
}

/* Modal overlay to blur the background */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Updated Modal styles */
.modal-container {
  position: fixed;
  bottom: 80px;
  left: 70px;
  width: 240px;
  background: var(--modalBackground);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  padding: 20px;
  display: flex;
  flex-direction: column;
  grid-gap: 0px;
  gap: 0px;
}

/* Profile section */
.profile-section {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--button);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
  color: var(--background-DD_Seperator);
  position: static;
}

/* Button styles */
.modal-button,
.modal-button-logout {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: left;
  background-color: transparent !important;
  color: var(--placeHolder);
  position: static;
}

.modal-button:hover,
.modal-button-logout:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.modal-button-logout {
  color: var(--placeHolder);
}

/* Theme toggle section */
.theme-toggle-section {
  padding: 10px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.darkmode-label {
  position: relative;
  left: 0;
  top: 0;
  margin: 0 auto;
}

/* Tooltip container styles */
.tooltip-container {
  position: relative;
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.3s ease;
}

.tooltip-container.collapsed .darkmode-text {
  opacity: 0;
  width: 0;
  margin: 0;
  overflow: hidden;
  transition: opacity 0.3s ease, width 0.3s ease;
}

.tooltip-container.expanded .darkmode-text {
  opacity: 1;
  width: auto;
  margin: 0 0.5rem;
  transition: opacity 0.3s ease, width 0.3s ease;
}

/* Adjust sun and moon icon alignment for collapsed state */
.tooltip-container.collapsed .darkmode-label {
  justify-content: center;
}

.tooltip-container.expanded .darkmode-label {
  justify-content: space-between;
}

/* Ant Design Popover customization */
.profile-popover {
  .ant-popover-inner {
    background: var(--modalBackground);
    border-radius: 8px;
    padding: 0;
  }

  .ant-popover-inner-content {
    padding: 0;
  }

  .ant-popover-arrow {
    left: 9px;
  }
  .ant-popover-arrow::after {
    background: var(--modalBackground);
    border: 1px solid var(--popover-border);
    width:  11.970563px !important;
  }
  .ant-popover-content {
    left: 8px;
    border: 1px solid var(--popover-border);
    border-radius: 10px;
  }
}

.modal-button,
.modal-button-logout {
  width: 100%;
  padding: 10px;
  border: none;
  background: transparent;
  text-align: left;
  cursor: pointer;
  color: var(--placeHolder);
  font-size: 14px;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.modal-button-logout {
  color:  var(--placeHolder);
}

.profile-section {
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
}

.theme-toggle-section {
  padding: 10px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.06); */
}

/* CustomFilterComponent.css */

/* Border color of the radio button when unchecked */
.ant-radio-inner {
  border-color: #000000 !important;
}

/* Color of the radio button when checked */
.ant-radio-checked .ant-radio-inner {
  background-color: #ffc000 !important;
  border-radius: 100%;
  border-color: #000000 !important;
}

/* Color of the checkmark inside the radio button when checked */
.ant-radio-checked .ant-radio-inner::after {
  background-color: #ffc000 !important;
}

.custom-filter-popover .ant-popover-inner {
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.filter-popover-content {
  width: 280px;
}

/* .sort-buttons {
  margin-bottom: 16px;
}

.sort-button {
  display: block;
  width: 100%;
  text-align: left;
  margin-bottom: 4px;
  color: #4bafa6;
  font-weight: 500;
}

.filter-label {
  font-weight: 500;
  margin-bottom: 12px;
}

.filter-row {
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
}

.condition-select,
.value-select {
  width: 50%;
}

.radio-group {
  margin-bottom: 12px;
  display: block;
}

.button-row {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.submit-button {
  background-color: #ffc000;
  border-color: #000000;
  color: #000000;
  border-radius: 20px;
}

.submit-button:hover {
  background-color: #e6ac00;
  border-color: #000000;
  color: #000000;
} */

/* Customize Select components */
.ant-select-selector {
  border-radius: 4px !important;
  border-color: #d9d9d9 !important;
}

/* Optional: Add hover effects */
.sort-button:hover {
  background-color: rgba(75, 175, 166, 0.1);
}

/* Customize the Select arrow color */
.custom-select-arrow .ant-select-arrow {
  color: #4bafa6 !important;
}

/* Optional: Change arrow color on hover */
.custom-select-arrow:hover .ant-select-arrow {
  color: #3a8c85 !important;
}

.custom-filter-menu {
  width: 265px;
  background: var(--white) !important;
  padding: 0;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
              0 3px 6px -4px rgba(0, 0, 0, 0.12),
              0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.06);
}

/* Add this new style for the placeholder color */
.custom-select-placeholder .ant-select-selection-placeholder {
  color: var(--filter-placeholder-color) !important;
}

/* Style for the search input inside Select */

.mapping-switch.ant-switch {
    background-color: #d9d9d9;
  }
  
  .mapping-switch.ant-switch:hover {
    background-color: #d9d9d9;
  }
  
  .mapping-switch.ant-switch.ant-switch-checked {
    background-color: rgb(25, 118, 210) !important;
  }
  
  .mapping-switch.ant-switch.ant-switch-checked:hover {
    background-color: rgb(25, 118, 210) !important;
  }
  
  .mapping-switch.ant-switch.ant-switch-checked .ant-switch-inner {
    background-color: rgb(25, 118, 210) !important;
  }
  
  .mapping-switch.ant-switch-checked .ant-switch-inner {
    background-color: rgb(25, 118, 210) !important;
  }
::-webkit-scrollbar {
    width: 8px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color:#8a8585;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .scrollable {
    scrollbar-width: thin;
    scrollbar-color:  #212121;
  }
  
  .content-admin {
        width: 512px;
        height: 58px;
        overflow-y: auto;
        overflow-x: hidden;
        white-space: normal;
        display: block;
        border: 1px solid var(--tableBorder);
        border-radius: 8px !important;
        color: var(--tableText) !important;
        font-size: 15px;
    }
  
  .paymentdatestdfields {
      font-size: 15px;
        width: 85px;
        /* padding-top: 3.5rem; */
        position: relative;
        display: flex;
        left: 29.5rem;
        position: relative;
        top: 3.3rem;
    }
    .paymentdatelastupdate {
      font-size: 15px;
      width:85px;
  
      width: 15rem;
      position: relative;
        display: flex;
        left: 36.3rem;
        top: 3.3rem;
      }
     
    .input-select-general-stdfields-assetclass {
      background-image: linear-gradient(45deg, transparent 50%, var(--button) 50%), linear-gradient(135deg, #018e82 50%, transparent 50%);
      background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), 100% 0;
      background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
      background-repeat: no-repeat;
      border: 1.5px solid var(--tableBorder);
      border-radius: 8px;
      color: var(--tableText);
      padding: 1px 1rem;
      background-color: var(--white);
      display: flex;
      width: 300px;
      height: 38px;
      /* margin-left: 25.5rem; */
      /* margin-top: -2.2rem; */
      padding-bottom: 0rem;
      /* margin-bottom: 0rem; */
      position: relative;
        left: 35rem;
        top: 1rem;
    }
    
    .input-select-general-stdfields-lastupdate {
      background-repeat: no-repeat;
      border: 1.5px solid var(--tableBorder);
      border-radius: 8px;
      color: gray;
      padding: 1px 1rem;
      background-color: var(--white);
      display: flex;
      width: 300px;
      height: 38px;
      position: relative;
        left: 42rem;
        top: 1rem;
    }
  
    .input-text{
      width: 15rem;
      font-size: 15px;
    }
  
    .custom-header{
      width:6rem;
    }
    .custom-header-new{
      width:10rem;
    }
    
    .uw-search-button-container-stdfields {
      width: 12rem;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      position: relative;
      left: 45rem;
      top: 1rem;
    }
    
    .headerdashboard-mastertape {
      font-family: "Catamaran", sans-serif !important;
      font-size: 24px;
      color: var(--tableText);
      font-weight: 600 !important;
      min-width: 20rem;
      position: absolute;
        display: flex;
        top: 3rem;
        left: 1rem;
    }
    
    .stdfields-antd .ant-table-thead > tr > th {
      background-color: var(--table-header-bg);
      color: var(--headerText) !important;
      /* border-bottom-style: 0px; */
      /* Change text color if needed */
    }
    .stdfields-antd .ant-table-thead .account-column {
      border-right: 0.5px solid #000 !important;
    }
    
    .stdfields-antd .ant-table-thead .parent-column {
      border-right: 0.5px solid #000 !important;
      border-bottom: 0.5px solid #000 !important;
    }
    
    .stdfields-antd .ant-table-thead .child-column {
      border-right: 0.5px solid #000 !important;
    }
    .stdfields-antd {
      /* border-bottom-width: 0px; */
      border: 1px solid var(--tableBorder);
      border-radius: 10px;
      overflow: hidden !important;
      /* New addition */
      flex-grow: 1;
      position: relative;
      bottom: -1rem;
      /* Allows the table to take the remaining height of the container */
    }
    
    .stdfields-antd .ant-table-tbody > tr > td {
      border-top: none;
      border-right: none !important;
      /* border-bottom: none; */
      border-left: none;
    }
    
    .stdfields-antd .ant-table-cell {
      height: 49px;
      font-family: "Mulish", sans-serif;
      font-size: 15px;
      font-weight: 400;
      line-height: 18.83px;
      text-align: left;
      background-color: var(--white);
      color: var(--tableText);
    }
    .stdfields-antd .ant-table-tbody>tr:nth-child(odd) > td {
      background-color: var(--tdOdd) !important;
    }
    
    .stdfields-antd .ant-table-tbody > tr:nth-child(even) > td {
      background-color: var(--tdEven) !important;
    }
    
    .stdfields-antd .ant-table-tbody > tr:nth-child(odd) .account-column {
      background-color: var(--white) !important;
      /* position: relative; */
    }
    
    .stdfields-antd
      .ant-table-tbody
      > tr:nth-child(odd)
      .account-column::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      /* background-color: rgba(229, 229, 229, 0.3) !important; */
    }
    
    .stdfields-antd .ant-table-tbody > tr:nth-child(odd) .account-column::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: white;
      z-index: -99;
    }
    .stdfields-antd
      .ant-table-tbody
      > tr:nth-child(odd)
      .account-column:first-child {
      position: -webkit-sticky;
      position: sticky;
      left: 0;
      top: 0;
      z-index: 1;
      /* background-color: rgba(255, 255, 255, 1); */
    }
    
    .stdfields-antd .ant-table-tbody > tr:nth-child(odd) .account-column > * {
      color: var(--text_Color) !important;
    }
    
    /* Define the CSS to remove hover effect */
    .stdfields-antd:hover {
      background: var(--white) !important;
    }
    
    /* Common styles for table columns */
    .stdfields-antd .ant-table-thead > tr > th {
      width: 150px; /* Set a default width for all columns */
      text-align: center; /* Center-align header text */
      white-space: normal; /* Allow text wrapping */
      word-wrap: break-word;
      line-height: 1.5; /* Better spacing for multi-line headers */
      padding: 8px;
    }
    
    .stdfields-antd .ant-table-tbody > tr > td {
      width: 150px; /* Match the width of the headers */
      text-align: center; /* Center-align content text */
      white-space: normal;
      word-wrap: break-word;
      line-height: 1.5;
    }
    
    .stdfields-antd .ant-table-thead > tr > th:not(:last-child)::before,
    .stdfields-antd .ant-table-thead > tr > td:not(:last-child)::before {
      content: none !important; /* Disable the pseudo-element */
      background-color: transparent !important; /* Remove any background color */
      width: 0 !important; /* Remove the visual divider entirely */
    }
    
    .stdfields-antd .ant-table-row {
      transition: none !important;
    }
    .stdfields-antd .ant-table-row:hover {
      background-color: var(
        --hover-on-row
      ) !important; /* Your custom hover background */
      box-shadow: var(--hover-on-row) !important; /* Remove default hover shadow */
    }
    
    .stdfields-antd .ant-table-row:hover td.ant-table-cell {
      background: var(--hover-on-row) !important;
    }
.data-display {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid #838383;
  border-radius: 5px;
  padding: 10px;
  /* background-color: #fafafa; */
  background-color: var(--chatWindow_Color) !important;
  color: var(--headerText);
}

.as_of_Labels {
  color: var(--headerText);
}

.data-item {
  text-align: center;
}

.data-value {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  font-weight: bold;
}

.data-label {
  color: var(--bannerTextColor) !important;
}

.percentage {
  display: flex;
  align-items: center;
}

.positive-percentage {
  color: #169414;
  display: flex;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
}

.negative-percentage {
  color: #ae2e2e;
  display: flex;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
}

.positive-arrow {
  color: green;
  margin-left: 5px;
}

.negative-arrow {
  color: red;
  margin-left: 5px;
}

.IDA_Virtual {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  position: relative;
  right: 4px;
}

.IDA_Icons {
  position: relative;
  display: flex;
  flex-direction: column;
}

.IDA_Icons .beta {
  position: relative;
  bottom: 0.6rem;
}
.align-perf-dropdowns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  top: 2rem;
  padding-bottom: 3rem;
  /* background-color: aqua; */
}

.align-per-tabs {
  width: 20vw;
}

.align-strats-dropdowns {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  position: relative;
  top: 1rem;
  padding-bottom: 2rem;
  /* background-color: aqua; */
}

.align-strat-side-dropdowns {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  position: relative;
  /* top: 1rem; */
  /* padding-bottom: 2rem; */
  /* background-color: aqua; */
}

.align-strat-tabs {
  width: 16vw;
}

.align-strat-tabs-primary {
  width: 26vw;
}

.align-all-dropdowns {
  width: 26vw;
  position: relative;
  top: 0.4rem;
}

.custom-multiselect {
  border: 1px solid black;
  border-radius: 8px;
  position: relative;
  /* This is important for positioning the dropdown */
}

.custom-multiselect .dropdown-container {
  border: none !important;
  position: relative !important;
  border-radius: 8px !important;
}

.custom-multiselect .dropdown-content {
  z-index: 99999;
  border: none;
  position: absolute;
  /* Position the dropdown absolutely */
  top: 100%;
  /* Position it below the input */
  left: 0;
  width: 100%;
  /* Make it the same width as the input */
  background: var(--dropdown-bg) !important;
  /* Ensure the background is white */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  border: 1px solid black;
  border-radius: 4px;
}

.custom-select .css-1nmdiq5-menu {
  z-index: 999;
  border: none;
  position: absolute;
  /* Position the dropdown absolutely */
  top: 100%;
  /* Position it below the input */
  left: 0;
  width: 100%;
  /* Make it the same width as the input */
  background: white;
  /* Ensure the background is white */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  border: 1px solid black;
  border-radius: 4px;
}

@media only screen and (min-width: 1160px) and (max-width: 1307px) {
  .align-per-tabs {
    width: 16vw;
  }
  .IDA_Virtual {
    position: relative;
    left: 4px;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 5600px) {
  .IDA_Virtual {
    position: relative;
    left: 4px;
  }
}

.accordion-container {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    flex-direction: column;
}

.accordion-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    cursor: pointer;
    grid-gap: 1rem;
    gap: 1rem;
}

.acc-line-though {
    height: 1px;
    flex: 1 1;
    background-color: #BCBCBC;
}

.acc-arrow {
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.acc-arrow.open {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}
.FixTails {
  position: relative;
  bottom: 2rem;
}

.scrollSummary {
  position: relative;
  /* bottom: 2rem; */
}

.SummaryStds {
  position: relative;
  /* background-color: aqua; */
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  left: -5px;
  width: 100%;
  padding-bottom: 10px;
}

.dropdown-row {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  /* Adjust the gap between dropdowns as needed */
  padding: 23px 0 15px 0;
}

.dropdown-container {
  display: flex;
  align-items: center;
}

.dropdown-label {
  margin-right: 10px;
  font-weight: bold;
  position: relative;
  top: 2px;
}

.dropdown-content-topBar {
  width: 200px;
  /* Adjust width as per requirement */
}

.custom-multiselect .dropdown-content-topBar {
  width: 200px;
  /* Adjust width for multi-selects */
}

/* .general-InvContainer,
.align-all-dropdowns {
    flex: 1;
} */
.total-row {
  font-weight: bold;
  color: #000;
}

.custom-vintage-button {
  background-color: #f2f9f9; /* Light greenish background */
  color: #009688; /* Teal text color */
  border: 1px solid #009688; /* Teal border color */
  border-radius: 30px; /* Rounded edges */
  font-size: 16px; /* Adjust font size */
  padding: 6px 20px; /* Adjust padding */
  height: auto; /* Ensure height is flexible */
  line-height: 1; /* Line height adjustment */
  transition: all 0.3s ease;
  position: relative;
  top: 3rem;
}

/* On hover, override AntD styles */
.custom-vintage-button:hover,
.custom-vintage-button:focus {
  background-color: #e0f7fa; /* Slightly darker green background */
  color: #00796b !important; /* Darker teal text color */
  border-color: #00796b !important; /* Darker teal border */
  outline: none; /* Remove default focus outline */
  box-shadow: none; /* Remove default AntD shadow */
}

/* On active/click state */
.custom-vintage-button:active {
  background-color: #b2dfdb !important; /* Even darker green background */
  color: #004d40 !important; /* Dark teal text color */
  border-color: #004d40 !important; /* Dark teal border */
  outline: none; /* Remove outline */
  box-shadow: none; /* Remove AntD box shadow */
}

.vintage-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.vintage-dropdown {
  position: relative;
  top: 2.2rem;
  left: 2rem;
}

.workbench-table-container {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
}

.table-row {
  display: flex;
  justify-content: space-between;
  grid-gap: 16px;
  gap: 16px;
  width: 100%;
  overflow: hidden; /* Prevent overflow issues */
}

.table-column {
  flex: 1 1;
  min-width: 0; /* Prevent the flex item from overflowing */
  max-width: 50%; /* Ensure both columns share the space equally */
}

.performance-table {
  width: 100% !important; /* Ensure the table takes the full width of its container */
  max-width: 100% !important; /* Prevent the table from exceeding its container */
  overflow: auto !important; /* Add scrolling if the table's content exceeds container width */
}

.selected-loan-table {
  width: 150px !important;
}

.table-row {
  display: flex;
  grid-gap: 16px;
  gap: 16px; /* Add space between columns */
}

.table-column {
  flex: 1 1; /* Make all columns equal width */
  display: flex;
  flex-direction: column;
}

.table-column .ant-table-wrapper {
  flex: 1 1; /* Make the Ant Design tables stretch to fill height */
  display: flex;
  flex-direction: column;
}

.table-column .ant-table-body {
  flex: 1 1; /* Stretch the table body to fill available space */
  overflow-y: auto !important; /* Allow scrolling for large data */
  max-height: 370px !important;
}
.table-section .ant-table-body {
  overflow-y: auto !important; /* Allow scrolling for large data */
}
.alignment-for-Charts {
  height: 465px !important;
}

.alignment-for-Charts .ant-table-wrapper {
  overflow-y: hidden !important;
}
/* .align-border-plot {
  padding: 0 !important;
  border-radius: 4px !important;
} */

.custom-inv-ddDownload {
  background-color: var(--white) !important;
  color: #018e82 !important;
}

.custom-inv-ddDownload .ant-dropdown-menu-item {
  background-color: var(--white) !important;
  color: #018e82 !important;
}

.custom-inv-ddHr {
  background-color: #e0e0e0 !important;
  border-top: 2px solid #e0e0e0 !important;
}

.modalStyle .ant-modal-content {
  font-family: "Mulish", sans-serif !important;
  height: 90vh !important;
  background-color: var(--chatWindow_Color);
}

/* .ant-modal {
    width: 100%;
    transform-origin: 85px 43px;
    overflow: hidden;
    height: -webkit-fill-available;
} */

.suggested-questions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5rem;
  padding: 1rem;
  /* background-color: blueviolet; */
}

.suggested-question-needed {
  border: 1px solid var(--tableBorder);
  border-radius: 20px;
  background-color: var(--tdOdd); /* Example: Light grey for even rows */
  cursor: "pointer";
  padding: 1.5rem;
  text-align: center;
  color: var(--headerText) !important;
}

.suggested-question-needed:hover {
  background-color: var(--tdEven); /* Example: Light grey for even rows */
  cursor: pointer;
}

.specific-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: "Mulish", sans-serif;
  min-width: 60vw;
  box-shadow: 0 0 10px rgba(22, 17, 17, 0.15);
  border: 1px solid #000;
  border-radius: 10px;
  color: var(--text_Color);
}

.specific-table th {
  text-align: center;
  border: 1px solid #dddddd;
  padding: 8px;
  color: var(--chatWindow_TableHeader);
}
.specific-table td {
  background: var(--chatWindow_Color) !important;
  text-align: center;
  border: 1px solid #dddddd;
  padding: 8px;
}
.specific-table thead tr {
  background-color: #f2f2f2;
  font-weight: bold;
}

.specific-table td {
  background-color: #fafafa;
}

.specific-table tr:nth-child(even) td {
  background-color: #ffffff;
}

.specific-table th {
  background-color: #e5f3f2;
}

.specific-table th,
.specific-table td {
  border: 1px solid #ddd;
  padding: 8px;
}
.scrollable-content-chat {
  /* flex: 1; */
  /* max-height: 100%; */
  /* Constrain content height */
  overflow-y: auto;
  /* overflow-x: hidden; */
  height: 100%;
  /* Enable vertical scrolling */
  padding-bottom: 120px;
  /* Optional padding for spacing */
}

.scrollable-content-chat .scroll-content-downloads {
  background-color: blueviolet;
}
/* Custom scrollbar styles for WebKit browsers */
.scrollable-content-chat::-webkit-scrollbar {
  width: 8px;
  /* Adjust the width of the scrollbar */
}

.scrollable-content-chat::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Color of the scrollbar thumb */
  border-radius: 10px;
  /* Rounded corners for a smoother look */
}

.scrollable-content-chat::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Darker on hover */
}

.scrollable-content-chat::-webkit-scrollbar-track {
  background-color: transparent;
  /* Transparent background for the scrollbar track */
}

/* Remove scrollbar arrows */
.scrollable-content-chat::-webkit-scrollbar-button {
  display: none;
  /* Hide the up/down arrow buttons */
}

.chat-msgs {
  height: 76vh;
  overflow-y: auto;
  color: var(--tableText);
}

.chat-msgs::-webkit-scrollbar-track {
  background-color: transparent;
  /* max-height: 85vh; */
  /* Transparent background for the scrollbar track */
}

/* Remove scrollbar arrows */
.chat-msgs::-webkit-scrollbar-button {
  display: none;
  /* Hide the up/down arrow buttons */
}

.chat-msgs .recent-entry {
  /* padding: 10px;
    border-radius: 50px;
    cursor: pointer; */
  cursor: pointer;
  font-family: "Mulish", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20.25px;
  text-align: left;

  color: var(--tableText);
}

.chat-msgs .recent-entry:hover {
  /* background-color: #e2e6eb; */
}

/* For standard browsers */
.chatInput-text::-webkit-input-placeholder {
  color: var(--tableText);
  background-color: var(--chatWindow_Color);
}
.chatInput-text::placeholder {
  color: var(--tableText);
  background-color: var(--chatWindow_Color);
}

.active-suggestion {
  background-color: #f0f0f0;
}

/* For WebKit browsers (e.g., Chrome, Safari) */
.chatInput-text::-webkit-input-placeholder {
  color: var(--tableText);
  background-color: var(--chatWindow_Color);
}

.horizontal_seperator {
  margin: 15px 0px 15px 0px;
  width: 214px;
  /* height: 1px; */
  top: 261px;
  left: 134px;
  background-color: gray !important;
}

.history {
  font-family: Mulish;
  font-size: 17px;
  font-weight: 700;
  line-height: 22.95px;
  text-align: left;
  color: var(--headerText);
}

.top_left_Icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-spacing {
  margin-right: 16px;
  cursor: pointer;
  /* Adjust this value for desired spacing */
}
.result {
  padding: 0px 5%;
}

.result-title {
  margin: 0px 0px 10px 0px;
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.result-data {
  display: flex;
  align-items: flex-start;
}

.loader-chat {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.loader-chat hr {
  border-radius: 4px;
  border: none;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #e1efee, #62b1a4, #e1efee);
  background-size: 800px 50px;
  height: 20px;
  -webkit-animation: loading 3s linear infinite;
          animation: loading 3s linear infinite;
  /* Add animation */
}

@-webkit-keyframes loading {
  0% {
    background-position: -800px 0px;
    /* Start position */
  }

  100% {
    background-position: 800px 0;
    /* End position */
  }
}

@keyframes loading {
  0% {
    background-position: -800px 0px;
    /* Start position */
  }

  100% {
    background-position: 800px 0;
    /* End position */
  }
}

/* Override Ant Design switch styles with maximum specificity */
.custom-switch .ant-switch.ant-switch-checked,
.custom-switch .ant-switch-checked,
.ant-switch.ant-switch-checked,
.ant-switch-checked {
  background-color: #018E82 !important;
}

.custom-switch .ant-switch.ant-switch-checked:hover,
.custom-switch .ant-switch-checked:hover,
.ant-switch.ant-switch-checked:hover,
.ant-switch-checked:hover {
  background-color: #018E82 !important;
}

.custom-switch .ant-switch.ant-switch-checked:focus,
.custom-switch .ant-switch-checked:focus,
.ant-switch.ant-switch-checked:focus,
.ant-switch-checked:focus {
  background-color: #018E82 !important;
}

/* Also target the inner node of the switch */
.custom-switch .ant-switch.ant-switch-checked .ant-switch-inner,
.ant-switch.ant-switch-checked .ant-switch-inner {
  background-color: #018E82 !important;
}

.custom-switch {
  transition: all 0.3s ease;
}

.selected-prompt {
  color: #018E82 !important;
}
.chat-chart-container {
  display: flex;
  flex-direction: column;
  /* Stack charts vertically */
}

.padding-graph {
  padding-bottom: 10px;
}

.plot-borders-bar-chat-charts {
  flex: 1 1;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0;
  position: relative;
  width: 100% !important;
  /* width: 100vw !important; */
  /* Add vertical spacing between charts */
}

.plot-inline-chat-charts {
  display: inline !important;
  position: relative;
  width: auto !important;
  height: 450px;

  color: var(--tableText) !important;
  background-color: var(--chatWindow_Color) !important;
}

.plot-inline-chat-charts .plot-container .svg-container {
  width: auto !important;
}

.plot-inline-chat-charts .plot-container .svg-container .main-svg {
  width: 100% !important;
}

.loan-table-alignments {
  display: flex;
  width: 100%;
  height: auto;
  /* Align cards to the top */
}

.loans-cards {
  /* border-bottom-width: 0px; */
  border: 1px solid var(--tableBorder);
  border-left: none;
  border-radius: 0 10px 10px 0 !important;
  overflow-y: auto; /* Enables vertical scrolling */
  overflow-x: auto; /* Enables horizontal scrolling */ /* New addition */
  flex-grow: 1;
  padding: 3rem;
  display: grid;
  height: 630px !important;
}

/* .scrollable-content-loans {
  height: 100vh; 
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100% !important;
  box-sizing: border-box;
  overflow-x: hidden;
  position: relative;
} */

.scrollable-content-loans {
  /* max-height: 100vh; */
  /* overflow-y: auto; */
  /* padding-bottom: 25rem; */
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  width: 100% !important;
  box-sizing: border-box;
  /* overflow-x: hidden; */
  position: relative;
}

.loan-details-graphs {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.loan-details-container {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

.loan-contents-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
  gap: 15px;
}

.loan-contents-container .loan-section,
.borrower-attributes,
.performance-section,
.collateral-section {
  border: 1px solid #8c8c8c;
  border-radius: 10px;
  padding: 10px;
  background-color: var(--lightGray);
  color: var(--headerText);
}

.loan-header {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.loan-info-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.info-item {
  display: flex;
  flex-direction: column; /* Stacks label and value vertically */
  min-width: 120px; /* Ensures consistent width */
  margin-right: 10px;
}

.progress-bar.yellow {
  background-color: #f7c948; /* Yellow for current balance */
}

.progress-bar.red {
  background-color: #f44336; /* Red for original balance */
}

.loan-header-info {
  font-size: 20px;
  font-weight: 600;
  line-height: 32.8px;
  text-align: left;
}

.info-header {
  font-size: 16px;
  font-weight: 600;
  line-height: 32.8px;
  color: var(--headerText);
}

.loan-financial-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--headerText);
}

.bar-section {
  display: flex;
  border: 1px solid #000;
  width: 200px;
  height: 40px;
}

.current-balance {
  background-color: #87ceeb; /* Light blue */
  text-align: center;
  line-height: 40px;
  color: #000;
  font-weight: bold;
}

.original-balance {
  background-color: #ffdab9; /* Peach */
  text-align: center;
  line-height: 40px;
  color: #000;
  font-weight: bold;
}

.table-container-loans {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
}

.table-loans-wrapper {
  max-height: 500px; /* Adjust as needed */
  overflow-y: auto;
  overflow-x: auto;
  width: 100%;
  background-color: var(--lightGray);
  color: var(--headerText);
  border: none !important;
}

.table-loans-wrapper table {
  width: 100%;
  border-collapse: collapse;
}

.table-loans-wrapper thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #e8f5f9; /* Light blue header background */
  color: #333;
  font-weight: bold;
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  z-index: 1;
}

.table-loans-wrapper tbody td {
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  white-space: nowrap; /* Prevents text wrapping */
}

.table-loans-wrapper tbody tr:hover {
  background-color: var(--hover-on-row);
}

.custom-input-loans::-webkit-input-placeholder {
  color: #000; /* Change placeholder color to black */
  opacity: 1; /* Ensure opacity is set to 1 for better visibility */
}

.custom-input-loans::placeholder {
  color: #000; /* Change placeholder color to black */
  opacity: 1; /* Ensure opacity is set to 1 for better visibility */
}

.custom-input-loans {
  outline: none;
}

/* For LoanDetails Table */

.table-loans-details-wrapper {
  overflow-y: auto;
  max-height: 500px; /* Adjust height as needed */
  width: 100%;
  border: 1px solid #fff;
}
/* can you make the scroll available after the column headers  */
.table-loans-details-wrapper table {
  width: 100%;
  border-collapse: collapse;
}

.table-loans-details-wrapper thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #e8f5f9; /* Light blue header background */
  color: #333;
  font-weight: bold;
  text-align: left;
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  z-index: 1;
}

.table-loans-details-wrapper td {
  padding: 16px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
  white-space: nowrap; /* Prevent text wrapping */
}

.table-loans-details-wrapper tbody tr:hover {
  background-color: #fafafa;
}

.data-display {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #838383;
  border-radius: 5px;
  padding: 10px;
  background-color: var(--chatWindow_Color) !important;
}

.SpaceDown {
  padding-bottom: 10px;
}
.data-item {
  text-align: center;
}

.data-value {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  font-weight: bold;
}

.data-label {
  color: var(--bannerTextColor) !important;
}

.percentage {
  display: flex;
  align-items: center;
}

.positive-percentage {
  color: #169414;
  display: flex;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
}

.negative-percentage {
  color: #ae2e2e;
  display: flex;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
}

.positive-arrow {
  color: green;
  margin-left: 5px;
}

.negative-arrow {
  color: red;
  margin-left: 5px;
}

/* .align-perf-dropdowns {
      display: flex;
      align-items: center;
      justify-content: space-between;
          gap: 5rem;
      width: 100%;
      position: relative;
      top: 2rem;
      padding-bottom: 3rem;
  }
  
  */
.datepicker {
  position: absolute;
  z-index: 1000;
  /* Ensure it stays above other elements */
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.date-range-picker {
  position: relative;
  top: -3rem;
}

.align-perf-side-dropdowns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  bottom: 13px;
  position: relative;
}

.align-per-tabs {
  width: 20vw;
}

.align-strats-dropdowns {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  position: relative;
  top: 1rem;
  padding-bottom: 2rem;
  /* background-color: aqua; */
}

.align-strat-side-dropdowns {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  position: relative;
  /* top: 1rem; */
  /* padding-bottom: 2rem; */
  /* background-color: aqua; */
}
.side-three-starts {
  display: flex;
  justify-content: space-between;
  flex: 1 1;
}

.side-prim-strat {
  position: relative;
  left: 12rem;
}
.side-sec-strat {
  position: relative;
  left: 6rem;
}

.align-strat-tabs {
  width: 16vw;
}

.align-strat-tabs-primary {
  width: 26vw;
}

.align-all-dropdowns {
  width: 19vw !important;
  position: relative;
  top: 0.4rem;
  height: 3rem;
}

.date-viewDetailsFunding {
  position: relative;
  /* top: -0.6rem; */
  left: 3rem;
  bottom: 2.59342rem;

  /* background-color: #007bff; */
}

.date-viewDetailsFunding .MuiFilledInput-root {
  width: 12rem !important;
}

.rmsc * {
  /* background-color: var(--dropdown-bg) !important; */
  /* background-color: var(--dropdown-bg) !important; */
  /* color: var(--text_Color) !important; */
}
.rmsc .select-item.selected {
  background-color: var(--background-DD_Content) !important;
  color: var(--text_Color) !important;
}
.rmsc .select-item {
  background-color: var(--background-DD_Content) !important;
  color: var(--text_Color) !important;
  padding: 2px 2px 2px 5px !important;
}
.rmsc .item-renderer input {
  position: relative;
  top: 5px;
}
.rmsc .options li {
  margin: -3px -3px -3px 0 !important;
}
.rmsc .options {
  background-color: var(--background-DD_Seperator) !important;
}
.rmsc .options::-webkit-scrollbar {
  height: 5px; /* Adjust scrollbar height */
  width: 5px; /* Adjust scrollbar width */
}

.rmsc .options::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 10px;
}

.rmsc .options::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color on hover */
}

.rmsc .options::-webkit-scrollbar-track {
  background: var(
    --background-DD_Seperator
  ) !important; /* Background of scrollbar track */
}
.custom-multiselect {
  border: 1px solid var(--tableBorder) !important;
  /* width: 200px; */
  border-radius: 8px;
  position: relative;
  padding: 0;
  color: var(--text_Color) !important;
  /* This is important for positioning the dropdown */
}
.multiselect-width {
  width: 200px !important;
}

.multiselect-width .dropdown-content {
  min-width: 200px !important;
  width: auto !important;
  white-space: nowrap !important;
  z-index: 9999 !important;
}

.rmsc .dropdown-heading {
  border-radius: 8px !important;
  background-color: var(--dropdown-bg) !important;
}

.rmsc.custom-multiselect:hover {
  border: 1px solid var(--dropdown-hover-bg) !important;
}
.custom-multiselect .dropdown-container {
  border: none !important;
  position: relative !important;
  border-radius: 8px !important;
}

/* Apply custom styles for checkboxes within the multi-select component */
.custom-multiselect .dropdown-content .option > input[type="checkbox"] {
  width: 20px;
  /* Width of the checkbox */
  height: 20px;
  /* Height of the checkbox */
  accent-color: black !important;
  /* Sets the checkbox color when checked */
  background-color: black !important;
  /* Background color when unchecked */
  border: 1px solid black;
  /* Border color of the checkbox */
  /* Space between the checkbox and text */
  cursor: pointer;
  /* Change cursor to pointer */
  z-index: 999;
}

/* Set the tick mark color */
.custom-multiselect .dropdown-content .option > input[type="checkbox"]:checked {
  accent-color: white !important;
  /* White tick mark color */
}
.custom-multiselect .dropdown-content {
  z-index: 999999;
  border: none;
  position: absolute;
  /* Position the dropdown absolutely */
  top: 100%;
  /* Position it below the input */
  left: 0;
  width: 100%;
  /* Make it the same width as the input */
  background-color: var(--dropdown-bg) !important;

  color: black !important;
  /* Ensure the background is white */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  border: 1px solid black;
  border-radius: 4px;
}

.custom-select .css-1nmdiq5-menu {
  z-index: 999;
  border: none;
  position: absolute;
  /* Position the dropdown absolutely */
  top: 100%;
  /* Position it below the input */
  left: 0;
  width: 100%;
  /* Make it the same width as the input */
  background-color: var(--dropdown-bg) !important;
  color: black !important;

  /* Ensure the background is white */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  border: 1px solid black;
  border-radius: 4px;
}

/* Targeting the dropdown content specifically for react-multi-select */
.custom-multiselect .dropdown-container .dropdown-content {
  z-index: 999999;
  border: 1px solid black;
  position: absolute;
  top: 100%; /* Position it below the input */
  left: 0;
  width: 100%;
  /* background-color: black !important; Force the background color to black */
  color: white !important; /* Change text color to white for contrast */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

/* Specific targeting for items/options inside the dropdown */
.custom-multiselect .dropdown-container .dropdown-content .item {
  color: var(--text_Color) !important; /* Set color for dropdown items */
  /* background-color: var(--lightGray) !important; Set background for items */
}

/* Modify the search box */
.custom-multiselect .dropdown-container .search input {
  background-color: var(--lightGray) !important; /* Set search box background */
  color: var(--text_Color) !important; /* Set text color */
}

.custom-multiselect .dropdown-container .dropdown-content {
  /* background-color: black !important; */
  color: black !important;
}

.performance-table table .table-body-wrapper > tr:nth-child(odd) > td {
  background-color: var(--tdOdd); /* Applies to odd rows */
  color: var(--headerText) !important;
  border-right: none !important;
  border-bottom: none !important;
}

.performance-table table .table-body-wrapper > tr:nth-child(even) > td {
  /* If you want a different style for even rows, add here */
  background-color: var(--tdEven); /* Example: Light grey for even rows */
  color: var(--headerText) !important;
  border-right: none !important;
  border-bottom: none !important;
}

.timePeriod {
  font-weight: bold;
}
.normal_table_headers th {
  background-color: var(--table-header-bg) !important;
  color: var(--headerText) !important;
  border-bottom: none !important;
}

@media only screen and (min-width: 1160px) and (max-width: 1307px) {
  .align-per-tabs {
    width: 16vw;
  }
}

@media only screen and (min-width: 1250px) and (max-width: 1600px) {
  .multiselect-width .dropdown-content {
    width: 200px !important;
    white-space: wrap !important;
    z-index: 9999 !important;
  }
}

.tableHeaders {
  font-family: "Catamaran", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 32.8px;
  text-align: left;
  color: var(--headerText);
}
.tableHeaders-collateral {
  font-family: "Catamaran", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 32.8px;
  text-align: left;
  position: relative;
  top: 2rem;
}
.PortfolioStds {
  position: relative;
  /* background-color: aqua; */
  left: -5px;
  width: 100%;
  color: var(--button) !important;
}

.dropdown-row {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  /* Adjust the gap between dropdowns as needed */
  padding: 23px 0 15px 0;
}

.dropdown-container {
  display: flex;
  align-items: center;
}

.dropdown-label {
  margin-right: 10px;
  position: relative;
  font-family: "Mulish", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 18.83px;
  text-align: right;
  top: 2px;
  color: var(--headerText);
}

.dropdown-content-topBar {
  width: 200px;
  /* Adjust width as per requirement */
}

.custom-multiselect .dropdown-content-topBar {
  width: 200px;
  /* Adjust width for multi-selects */
}

/* .general-InvContainer,
.align-all-dropdowns {
    flex: 1;
} */

.rmsc.custom-multiselect:hover {
  border: 1px solid var(--tableBorder) !important;
}

